import {
    Button,
    ButtonProps, Stack,
    Typography
} from "@mui/material";
import {
    PropsWithChildren
} from "react";
import Iconify from "../iconify";

//-------------------------------
interface CardContentButtonT {
    icon: string;
    target?: string;
    disableDivider?: boolean;
}

export function CardContentButton({
    icon, children, variant = 'text', disableDivider, ...props
}: CardContentButtonT & PropsWithChildren<ButtonProps>) {
    return (
        <Button
            {...props}
            size="large"
            variant={variant}
            fullWidth
            sx={theme => ({
                p: 3,
                borderRadius: 0,
                ...(!disableDivider && { borderTop: `1px solid ${theme.palette.divider}` })
            })}
        >
            <Stack
                width='100%'
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Stack direction='row'
                    alignItems='center'>
                    <Iconify icon={icon} mr={2} />
                    <Typography lineHeight={1.5} textTransform='none'>
                        {children}
                    </Typography>
                </Stack>
                <Iconify width={24} icon='mdi:chevron-right' />
            </Stack>
        </Button>
    );
}
