import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useReservationInfo } from 'src/ReservationInfoContext';
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
import { NAV } from '../../../config-global';
import { hideScrollbarX } from '../../../utils/cssStyles';
import NavToggleButton from './NavToggleButton';
import createNavConfig from './config-navigation';
import { selectReservationMenuLabelToRemove, filterNavItems, selectListingMenuLabelToRemove } from './navHelpers';
import { useListingInfo } from '../../../ListingInfoContext';

export default function NavMini() {
  const {
    totalReservations,
    isLoading: isReservationInfoLoading,
    primaryReservation } = useReservationInfo()

  const {
    isLoading: isListingInfoLoading,
    singleListingId,
    totalListings
  } = useListingInfo()

  const navListKeysToRemove = useMemo(() => {
    let itemsToRemove: string[] = [];
    itemsToRemove = itemsToRemove.concat(selectListingMenuLabelToRemove(isListingInfoLoading, totalListings));
    itemsToRemove = itemsToRemove.concat(selectReservationMenuLabelToRemove(isReservationInfoLoading, totalReservations));
    return itemsToRemove;
  }, [isListingInfoLoading, isReservationInfoLoading, totalListings, totalReservations])

  const filteredNavConfig = useMemo(() => {
    return filterNavItems(  navListKeysToRemove,
                            createNavConfig(primaryReservation ? primaryReservation.name : '', singleListingId !== null ? singleListingId  : '' ))
  }, [navListKeysToRemove, primaryReservation, singleListingId])

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini data={filteredNavConfig} />
      </Stack>
    </Box>
  );
}
