import { brandConfig } from "../../config"

export default function fTitle(title: string) {
    const trimmedTitle = title.trim()
    return `${trimmedTitle} | ${brandConfig.name}`
}

function createTitleWithReservationName(title: string, reservationName: string) {
    return `${title} ${reservationName}`
}

function createTitleWithListingId(title: string, listingId: string) {
    return `${title} ${listingId}`
}

export const PageTitles = {
    'GeneralAppPage': 'Dashboard',
    'ListingPage': (listingId: string) => createTitleWithListingId('Property', listingId),
    'ListingsPage': 'Properties',
    'PaymentConfirmationPage': 'Payment Confirmation',
    'ReservationPage': (reservationName: string) => createTitleWithReservationName('Stay', reservationName),
    'ReservationsPage': 'Stays',
    'TravelInsurancePage': (reservationName: string) => createTitleWithReservationName('Trip Protection', reservationName),
    'InvoicePage': (reservationName: string) => createTitleWithReservationName('Invoice', reservationName),
}