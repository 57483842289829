import { Masonry } from "@mui/lab";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import EmptyContent from "../../components/empty-content/EmptyContent";
import { useSettingsContext } from "../../components/settings";
import useResponsive from "../../hooks/useResponsive";
import { useGetListingsQuery } from "../../redux/rtkQuery/apiSlice";
import { PATH_ROOT } from "../../routes/paths";
import { ListingListCard } from "../../sections/listing/ListingListCard";
import { StandardRTKLoader } from "../../sections/loader/StandardRTKLoader";
import { cloud_getListings } from "../../utils/mrr/cloudFunctions";
import fTitle, { PageTitles } from "../../utils/mrr/formatTitle";

export default function ListingsPage() {
    const { themeStretch } = useSettingsContext()
    const horizontal = useResponsive('up', 500)
    const below860 = useResponsive('down', 860)
    const {
        data: listingsData,
        isError: listingsIsError,
        isError: listingsError,
        isSuccess: listingsSuccess,
        isFetching: listingsFetching,
    } = useGetListingsQuery({})

    if (!listingsSuccess) {
        return (
            <StandardRTKLoader
                endpoint={cloud_getListings}
                mutationCall={false}
                isError={listingsIsError}
                isFetching={listingsFetching}
                loadedObjectName="properties"
                error={listingsError}
            />);
    }

    return (
        <>
            <Helmet>
                <title>{fTitle(PageTitles.ListingsPage)}</title>
            </Helmet>
            <Container
                maxWidth={
                    themeStretch
                        ? 'md'
                        : 'xl'
                }
            >
                <CustomBreadcrumbs
                    heading="Properties"
                    links={[
                        { name: 'Home', href: PATH_ROOT },
                        { name: 'Properties' },
                    ]}
                />

                {listingsData.length === 0 &&
                    <EmptyContent
                        title='No listings found.' />
                }
                {listingsData.length > 0 &&
                    <Masonry
                        columns={below860 ? 1 : 2}
                        spacing={2}>
                        {listingsData.map(
                            (listing, index) => (
                                <ListingListCard
                                    key={`${listing.id}_card_${index}`}
                                    horizontal={horizontal}
                                    listing={listing} />
                            ))}
                    </Masonry>
                }

            </Container>
        </>
    )
}
