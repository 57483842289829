import { ConfigType } from "./config-navigation";

export const menuLabelPluralListings = 'properties';
export const menuLabelSingleListing = 'property';

export const menuLabelPluralReservations = 'stays';
export const menuLabelSingleReservation = 'my stay';

export function selectListingMenuLabelToRemove(isLoading: boolean, totalListings: number) {
  if (isLoading || totalListings !== 1) {
    return [menuLabelSingleListing]
  }
  return [menuLabelPluralListings];
};

export function selectReservationMenuLabelToRemove(isLoading: boolean,
  totalReservations: number) {
  if (isLoading) {
    return [menuLabelSingleReservation]
  }
  return totalReservations === 1
    ? [menuLabelPluralReservations]
    : [menuLabelSingleReservation]
};

export function filterNavItems(labelsToRemove: string[], navList: ConfigType) {
  const setOfKeysToRemove = new Set(labelsToRemove.map((label) => label.toLowerCase()))

  if (setOfKeysToRemove.size === 0) {
    return navList
  }

  const clonedNavList: ConfigType = [];

  for (let i = 0; i < navList.length; ++i) {
    // eslint-disable-next-line prefer-object-spread
    clonedNavList.push(Object.assign({}, navList[i]));
  }

  const filteredNavList = clonedNavList.filter((navEntry) => {
    return !setOfKeysToRemove.has(navEntry.subheader.toLowerCase())
  })

  filteredNavList.forEach((category) => {
    category.items = category.items.filter((subEntryItem) => {
      return !setOfKeysToRemove.has(subEntryItem.title.toLowerCase())
    })
  })
  return filteredNavList
};
