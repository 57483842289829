import { ReservationGeneral } from '../../models/ReservationGeneral';
import { OrientationContainer } from '../containers/OrientationContainer';
import { ReservationCardContent } from './ReservationCardContent';
import { ReservationCardImage } from './ReservationCardImage';

// ----------------------------------------------------------------------

type Props = {
  reservation: ReservationGeneral;
  isProperty?: boolean; // if true, will not show the property name, will not show the property image chip, and will not show reservation details,
  horizontal: boolean;
};

export function ReservationListCard({ reservation, isProperty, horizontal }: Props) {


  return (
    <OrientationContainer horizontal={horizontal}>
      <ReservationCardImage horizontal={horizontal} reservation={reservation} isProperty={isProperty} />
      <ReservationCardContent reservation={reservation} isProperty={isProperty} />
    </OrientationContainer>

  );
}
