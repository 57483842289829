import {
    Divider, List,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";
import { SplitRow } from "../containers/SplitRow";
// eslint-disable-next-line import/no-cycle
import { GPISChannel } from "../../models/GPISGeneral";

// ----------------------------------------------------------------------

export function GPISChannelList({ channel_list }: { channel_list: GPISChannel[]; }) {
    return (
        <List disablePadding>
            <Stack divider={<Divider />}>
                {channel_list.map((channel, index) => (
                    <ListItemButton
                        key={`${channel.channel}_${index}_channel`}
                        sx={{ p: 0, height: '2rem' }}>
                        <SplitRow>
                            <Typography
                                variant="body2">
                                {channel.program} ({channel.definition})
                            </Typography>
                            <Typography
                                variant="body2">
                                {channel.channel}
                            </Typography>
                        </SplitRow>
                    </ListItemButton>
                ))}
            </Stack>
        </List>
    );
}
