import { OrientationContainer } from "../../components/containers/OrientationContainer";
import { ListingGeneral } from "../../models/ListingGeneral";
import { ListingCardContent } from "./ListingCardContent";
import { ListingListCardImage } from "./ListingListCardImageProps";

type ListingListCardProps = {
    listing: ListingGeneral;
    horizontal?: boolean;
};
export function ListingListCard({ listing, horizontal }: ListingListCardProps) {
    return (
        <OrientationContainer horizontal={horizontal}>
            <ListingListCardImage horizontal={horizontal} listing={listing} />
            <ListingCardContent listing={listing} />
        </OrientationContainer>
    );
}
