// ----------------------------------------------------------------------
export class GPISDetailSection {
    constructor(
        public readonly title: string,
        public readonly id: string,
        public readonly category: string,
        public readonly body: string | JSX.Element
    ) { }
}
export class GPISItem {
    public readonly sections: GPISDetailSection[];
    constructor(
        public readonly title: string,
        public readonly link: string,
        private readonly _sections: any[]
    ) {
        this.sections = this._sections?.map((section: any) => {
            const { title: sectionTitle, id, category, body } = section;
            return new GPISDetailSection(sectionTitle, id, category, body);
        });
    }
}
