import ListItemText from '@mui/material/ListItemText';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Alert, Box, Collapse } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';
import { CenteredRow } from '../containers/CenteredRow';
import { SplitRow } from '../containers/SplitRow';
import Iconify from '../iconify';
import Label from '../label';

// ----------------------------------------------------------------------
interface PaymentMethodListProps {
    paymentMethods: PaymentMethod[];
    selectedPaymentMethodId: string | null;
    onPaymentMethodSelected: (paymentMethodId: string) => void;
    defaultPamentMethodId: string | null;
}


export default function PaymentMethodList(props: PaymentMethodListProps) {
    const {
        paymentMethods,
        selectedPaymentMethodId,
        onPaymentMethodSelected,
        defaultPamentMethodId
    } = props;

    const filteredPaymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.type === 'card' || paymentMethod.type === 'us_bank_account')
    const sortedAndFilteredPaymentMethods = filteredPaymentMethods.sort((a, b) => {
        return b.created - a.created
    })

    // ? Debugging used to confirm that the payment methods are sorted by created date from newest to oldest
    // console.log(sortedAndFilteredPaymentMethods.map((paymentMethod) => getPaymentMethodDescription(paymentMethod) + ` ${new Date(paymentMethod.created * 1000).toLocaleString()}`))

    return (
        <Stack spacing={1}>
            {sortedAndFilteredPaymentMethods.map((option) => {
                const { id } = option;
                return (
                    <OptionItem
                        defaultPaymentMethodId={defaultPamentMethodId}
                        paymentMethod={option}
                        key={id}
                        selected={selectedPaymentMethodId === id}
                        onPaymentMethodSelected={() => { onPaymentMethodSelected(id) }}
                    />
                )
            })}
        </Stack>
    );
}

// ----------------------------------------------------------------------

type OptionItemProps = PaperProps & {
    paymentMethod: PaymentMethod;
    selected: boolean;
    onPaymentMethodSelected: (paymentMethodId: string) => void;
    defaultPaymentMethodId: string | null;
};

function mapCardIcon(brand?: PaymentMethod.Card['brand']) {
    switch (brand) {
        case 'diners':
            return 'simple-icons:dinersclub';
        case 'unionpay':
            return `logos:${brand}`;
        case undefined:
            return 'mdi:bank';
        default:
            return `formkit:${brand}`;
    }
}

function OptionItem({ selected, onPaymentMethodSelected, paymentMethod, defaultPaymentMethodId }: OptionItemProps) {
    const { card, id } = paymentMethod
    const cardIcon = mapCardIcon(card?.brand)
    return (
        <>
            <Paper
                onClick={() => { onPaymentMethodSelected(id) }}
                variant="outlined"
                key={paymentMethod.id}
                sx={{
                    p: 1,
                    cursor: 'pointer',
                    ...(selected && {
                        boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
                        borderColor: (theme) => theme.palette.primary.main,
                    }),
                }}
            >
                <ListItemText
                    primary={
                        <SplitRow>
                            <CenteredRow>
                                <Box sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, p: .5, display: 'flex' }} mr={2} >
                                    <Iconify width={24} height={16} icon={cardIcon} />
                                </Box>

                                {getPaymentMethodDescription(paymentMethod)}
                                {defaultPaymentMethodId === id && <Label color='primary' ml={2}>Default</Label>}
                            </CenteredRow>
                        </SplitRow>
                    }
                    primaryTypographyProps={{ typography: 'subtitle2', }}
                />
            </Paper>
            <Collapse in={selected && paymentMethod.type === 'us_bank_account'}>
                <USBankAccountDisclaimer />
            </Collapse>
        </>
    );
}

function USBankAccountDisclaimer() {
    return (
        <Alert square severity="info">
            Please note this may take 1-2 business days to process.
        </Alert>
    )
}


// ----------------------------------------------------------------------
const getPaymentMethodDescription = (method: PaymentMethod) => {
    const capitalizedCardBrand = method.card?.brand.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    const capitalizedBankName = method.us_bank_account?.bank_name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    switch (method.type) {
        case 'card':
            return method.card ? `${capitalizedCardBrand} •••• ${method.card.last4}` : 'Unknown Card';
        case 'us_bank_account':
            return method.us_bank_account ? `${capitalizedBankName} •••• ${method.us_bank_account.last4}` : 'Unknown Bank Account';
        default:
            return 'Unknown Payment Method';
    }
};