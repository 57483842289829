import { useCallback, useMemo, useState } from 'react';
import { AUTH } from 'src/auth/FirebaseContext';
import { axiosToGCF } from './AxiosGCF';
import { useSnackbar } from 'notistack';

const useDownloadRA = (raID: string, reservationName: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [downloadingRA, setDownloadingRA] = useState(false);
  const downloadRentalAgreement = useCallback(async () => {
    try {
      setDownloadingRA(true);
      const searchParams = new URLSearchParams();
      searchParams.append('object_id', raID);
      const request = await axiosToGCF(
        AUTH.currentUser,
        'getPDF',
        'application/pdf',
        'POST',
        undefined,
        searchParams
      );
      const result = await request({
        url: 'getPDF',
        responseType: 'blob',
        data: { object_id: raID },
      });

      if (result.status !== 200) {
        setDownloadingRA(false);
        enqueueSnackbar('There was a problem downloading rental agreement.', { variant: 'error' });
        return false;
      }
      // read blob
      const blob: Blob = await result.data;
      if (blob.type !== 'application/pdf') {
        setDownloadingRA(false);
        enqueueSnackbar('There was a problem with this pdf format.', { variant: 'error' });
        return false;
      }
      const pdfUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'Rental Agreement Signed (' + reservationName + ').pdf';

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfUrl);
        link.remove();
      }, 100);
      setDownloadingRA(false);
      return true;
    } catch (error) {
      enqueueSnackbar('There was a problem viewing rental agreement.', { variant: 'error' });
      setDownloadingRA(false);
      return false;
    }
  }, [raID, reservationName, enqueueSnackbar]);

  return useMemo(
    () => ({ downloadingRA, downloadRentalAgreement }),
    [downloadingRA, downloadRentalAgreement]
  );
};

export default useDownloadRA;
