import { LoadingButton } from "@mui/lab";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { ConfirmPaymentData, PaymentIntent, StripeError } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { StripeCustomer } from '../../models/Stripe';
import { useAnalyticsContext } from "../../analytics";
import { ROIGeneral } from "../../models/ROIGeneral";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { PATH_APP } from "../../routes/paths";
import { fCurrency } from "../../utils/formatNumber";
import { sendToSentry, sentryStripeFingerprint } from "../../utils/mrr/sentryReporter";
import { paymentCallbackError, paymentRedirectStatus } from "./PaymentTypes";
import { sendAndSwallowPurchaseEvent } from "../../analytics/sendAndSwallowPurchaseEvent";
import { createPaymentIntent } from "./service/createPaymentIntent";

export const verboseLogging = false

interface PaymentButtonProps {
    returnUrl?: string;
    selectedPaymentMethodId?: string;
    amount: number;
    paymentError: StripeError | null;
    handlePaymentError: (error: StripeError | null) => void;
    onSuccessfulPayment?(): Promise<{ data: any; } | { error: unknown; }>;
    reservation?: ReservationGeneral;
    reservationOrderItem?: ROIGeneral;
    category: string;
    loading?: boolean;
    disabled?: boolean;
    metadata?: Record<string, string | number | boolean>;
    description?: string;
    customer: StripeCustomer
}
export function PaymentButton(props: PaymentButtonProps) {
    const stripe = useStripe();
    const elements = useElements();
    const { tagPurchaseEvent } = useAnalyticsContext();
    const {
        amount,
        category,
        handlePaymentError,
        loading,
        onSuccessfulPayment,
        paymentError,
        reservation,
        reservationOrderItem,
        returnUrl,
        selectedPaymentMethodId,
        disabled,
        description,
        metadata,
        customer
    } = props;
    const [paymentStarted, setPaymentStarted] = useState(false);
    const [confirmedPaymentIntent, setConfirmedPaymentIntent] = useState<PaymentIntent | null>(null);
    const paymentEnded = useRef(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (confirmedPaymentIntent === null || paymentEnded.current) {
            return;
        }
        // prevent this logic from running more than once
        paymentEnded.current = true;
        // ----- Redirect -----
        const redirectURL = new URL(returnUrl || window.location.origin + PATH_APP.payment.confirm);
        addPaymentConfirmStatusSearchParams(redirectURL, confirmedPaymentIntent.status);

        if (confirmedPaymentIntent.status === 'succeeded' || confirmedPaymentIntent.status === 'processing') {
            sendAndSwallowPurchaseEvent(category, amount, tagPurchaseEvent, confirmedPaymentIntent, reservation, reservationOrderItem)
        }

        onConfirmedPaymentSuccess(confirmedPaymentIntent, redirectURL, onSuccessfulPayment).then(() => {
        }).catch((error: any) => {
            // Handle error message in confirm page
        }).finally(() => {
            const navToUrl = redirectURL.toString().replace(redirectURL.origin, '')
            navigate(navToUrl, { replace: true })
        })
    });

    const initiatePayment = async () => {
        // console.log('elements:', stripe?.elements());
        if (!stripe || !elements || !customer) {
            console.warn('Failed to initiate payment.')
            return;
        }
        handlePaymentError(null);
        setPaymentStarted(true);
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // setErrorMessage(submitError.message ?? "An unknown error occurred");
            setPaymentStarted(false);
            return;
        }
        const windowOrigin = window.location.origin;

        try {
            let paymentMetadata = metadata || {};
            if (reservation) {
                // Here we add the reservation id and name to the payment metadata if a reservation is provided.
                paymentMetadata = {
                    ...paymentMetadata,
                    reservation_id: reservation.id,
                    reservation_name: reservation.name
                };
            }
            else if (reservationOrderItem) {
                // Here we add the reservation order item id, type, and sub_type to the payment metadata if a reservation order item is provided.
                paymentMetadata = {
                    ...paymentMetadata,
                    reservation_name: reservationOrderItem.reservation_name,
                    roi_id: reservationOrderItem.id,
                    type: reservationOrderItem.type,
                    sub_type: reservationOrderItem.sub_type
                };
            }
            const paymentIntent = await createPaymentIntent({ amount, currency: 'usd', description, customer: customer?.id, metadata: paymentMetadata });
            if (!paymentIntent) {
                handleError(new Error('Failed to create payment intent.'));
                return;
            }
            const confirmParams: ConfirmPaymentData = {
                return_url: returnUrl || windowOrigin + PATH_APP.payment.confirm,
                ...(selectedPaymentMethodId && { payment_method: selectedPaymentMethodId })
            }

            const confirmPaymentOptions: any = {
                ...(!selectedPaymentMethodId && elements && { elements }),
                clientSecret: paymentIntent?.client_secret as string,
                confirmParams,
                redirect: "if_required",
            }
            const { error, paymentIntent: confirmResponse } = await stripe.confirmPayment(confirmPaymentOptions);
            if (error) {
                handleError(error);
                return;
            }
            setConfirmedPaymentIntent(confirmResponse);
        }
        catch (error) {
            // This is needed to catch non-Stripe errors that happen within Stripe, e.g. 'circular JSON'.
            handleError(error);
        }
    };

    const handleError = (error: any) => {
        // we send certain errors to Sentry, but not expected things like 'insufficient funds'
        if (error.type !== 'card_error' && error.type !== 'validation_error') {
            // For more info, see: https://stripe.com/docs/js/payment_intents/confirm_payment
            sendToSentry(error, null, sentryStripeFingerprint, 'confirmPayment');
        }
        setPaymentStarted(false); // Reset payment state
        handlePaymentError(error);
    };

    const buttonLoading = (paymentStarted && (paymentError === null) || loading)
    const buttonDisabled = paymentStarted || !customer || disabled
    return (
        <LoadingButton size='large' variant="contained" color="primary" onClick={initiatePayment} disabled={buttonDisabled} loading={buttonLoading} >
            Pay {fCurrency(amount, true)}
        </LoadingButton>
    )
}

// -----------------------------------------------------------

// set status to display on payment confirm
function addPaymentConfirmStatusSearchParams(url: URL, status: PaymentIntent.Status | 'unknown') {
    verboseLogging && console.log('adding status to redirect')
    return url.searchParams.set(paymentRedirectStatus, status);
}
// set status to display on payment confirm
function addCallbackErrorMessageSearchParams(url: URL) {
    verboseLogging && console.log('adding error message to redirect')
    return url.searchParams.set(paymentCallbackError, 'We were unable to update your reservation. Please contact guest support.');
}

async function onConfirmedPaymentSuccess(
    confirmedPaymentIntent: PaymentIntent,
    redirectURL: URL,
    onSuccessfulPayment?: () => Promise<{
        data: any;
    } | {
        error: unknown;
    }>,
) {
    if (!onSuccessfulPayment) {
        return
    }
    // In the case of processing we just don't know yet if it's successful or not (Bank Account).
    // According to Stripe, we should proceed as if succedded and handle faild bank payments elsewhere.
    if (confirmedPaymentIntent.status === 'succeeded' || confirmedPaymentIntent.status === 'processing') {
        verboseLogging && console.log('executing payment success callback')
        try {
            const data = await onSuccessfulPayment();
            if ((data as any).error) {
                // there was an error in the post payment callback.
                verboseLogging && console.log('error executing payment success callback. Data:', data)
                addCallbackErrorMessageSearchParams(redirectURL)
            }
        } catch (error) {
            verboseLogging && console.log('error executing payment success callback. Error:', error)
            addCallbackErrorMessageSearchParams(redirectURL)
        }
    } else {
        console.log('Callback ignored on status:', confirmedPaymentIntent.status)
    }

    // if (confirmedPaymentIntent.status === 'requires_payment_method'
    //     || confirmedPaymentIntent.status === 'requires_confirmation'
    //     || confirmedPaymentIntent.status === 'requires_action'
    //     || confirmedPaymentIntent.status === 'requires_capture'
    //     || confirmedPaymentIntent.status === 'canceled') {
    //     // what to do, if anything
    // }
};
