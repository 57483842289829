import { useCallback, useEffect, useState } from "react";
import { usePatchRegistrationStepMutation } from "src/redux/rtkQuery/apiSlice";
import { GuestGeneral } from "../../models/GuestGeneral";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { RegistrationGuestsSection } from "./RegistrationGuestsSection";
import { RegistrationPicklistItem } from "./types";


interface RegistrationAdditionalGuestsProps {
    stepCompleted: boolean,
    mainGuest: GuestGeneral,
    ageRequired: boolean,
    reservation: ReservationGeneral,
    guestModels: GuestGeneral[] | null,
    callbackCancel: VoidFunction,
    callbackAddGuest: VoidFunction,
    callbackEditGuest: (guest: GuestGeneral) => void,
    callbackContinue: VoidFunction
};

export function RegistrationAdditionalGuests({
    stepCompleted,
    mainGuest,
    ageRequired,
    reservation,
    guestModels,
    callbackCancel,
    callbackAddGuest,
    callbackEditGuest,
    callbackContinue
}: RegistrationAdditionalGuestsProps) {
    const [ guestListModified, setGuestListModified ] = useState<boolean>(false);

    useEffect(() => {
        // console.log('RegistrationAdditionalGuests useEffect', guestModels);
    }, [guestModels]);

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    const completeGuestListStep = useCallback(async () => {
        const sfPayload = {
            reservationName: reservation.name,
            registrationStep: RegistrationPicklistItem.GUEST_LIST
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    console.error(data.error.message);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update guest list step', putError);
            });

            //TODO
            // sendEvent(new GAEventGuest(
            //     sfPayload.type,
            //     sfPayload.is_minor,
            //     sfPayload.first_name,
            //     sfPayload.last_name,
            //     true,
            //     sfPayload.relation,
            //     reservation_name,
            // ))
        }, [callbackContinue, reservation.name, sendPatchRegistrationStep]);

    return (
        <RegistrationGuestsSection
            mainGuest={mainGuest}
            reservation={reservation}
            ageRequired
            guestModels={guestModels}
            parentLoading={patchStepIsLoading}
            callbackAddGuest={() => {
                callbackAddGuest();
                setGuestListModified(true);
            }}
            callbackEditGuest={(guest: GuestGeneral) => {
                callbackEditGuest(guest);
                setGuestListModified(true);
            }}
            callbackGuestDeleted={(guest: GuestGeneral) => {
                setGuestListModified(true);
            }}
            callbackCancel={() => {
                callbackCancel();
            }}
            callbackContinue={() => {
                if (guestListModified || !stepCompleted) {
                    completeGuestListStep();
                    return;
                }
                callbackContinue();
            }}
        />
    );
};
