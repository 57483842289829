import { SettingsValueProps } from "../components/settings/types";

declare global {
  interface Window {
    BRAND_TITLE: string;
    locale: string;
  }
}

interface BrandConfig {
  name: string;
  greeting: string;
  thankyou: string;
  brandId: string;
  brandCode: string;
  backgroundUrl?: string;
  logoUrl: string;
  iconUrl: string;
  logoUrlDark: string;
  iconUrlDark: string;
  themeSettings: SettingsValueProps;
  customCssUrl?: string; // Optional, for custom styles if needed
  address: {
    streetAddress: string,
    city: string,
    state: string,
    postalCode: string,
  },
  website: string,
  supportEmail: string,
  supportPhone: string,
  locale: string,
  timezone: string,
  timezoneCode: string,
  stripePublishableKey: string;
}

// Define your branding configurations for different domains here
const domainBrand: Record<string, BrandConfig> = {
  'guest.beachviewrentals.com': {
    name: 'Beachview Rentals',
    greeting: 'Welcome',
    thankyou: 'Thank you',
    brandId: 'beach_view_rentals',
    brandCode: 'BVR',
    backgroundUrl: '/assets/background/bg_img3.webp',
    logoUrl: '/logo/bvr_logo_full.svg',
    iconUrl: '/logo/bvr_logo_small.svg',
    logoUrlDark: '/logo/bvr_logo_full_dark.svg',
    iconUrlDark: '/logo/bvr_logo_small_dark.svg',
    website: 'https://www.beachviewrentals.com',
    supportEmail: 'experience@beachviewrentals.com',
    supportPhone: '(949) 673-2627',
    locale: 'Newport Beach',
    timezone: 'America/Los_Angeles',
    timezoneCode: 'PT',
    address: {
      streetAddress: '314 Old Newport Blvd',
      city: 'Newport Beach',
      state: 'CA',
      postalCode: '92663'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false,
    },
    stripePublishableKey: process.env.REACT_APP_BVR_STRIPE_PUBLISHABLE_KEY as string
  },
  'guest.mauiresortrentals.com': {
    name: 'Maui Resort Rentals',
    greeting: 'Aloha',
    thankyou: 'Mahalo',
    brandId: 'maui_resort_rentals',
    brandCode: 'MRR',
    backgroundUrl: '/assets/background/bg_img1_33pct.jpg',
    logoUrl: '/logo/mrr_logo_full.svg',
    iconUrl: '/logo/mrr_logo_small.svg',
    logoUrlDark: '/logo/mrr_logo_full_dark.svg',
    iconUrlDark: '/logo/mrr_logo_small_dark.svg',
    website: 'https://www.mauiresortrentals.com',
    supportEmail: 'experience@mauiresortrentals.com',
    supportPhone: '(888) 468-0578',
    locale: 'Maui',
    timezone: 'Pacific/Honolulu',
    timezoneCode: 'HT',
    address: {
      streetAddress: '10 Halawai Dr #A-3',
      city: 'Lahaina',
      state: 'HI',
      postalCode: '96761'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false,
    },
    stripePublishableKey: process.env.REACT_APP_MRR_STRIPE_PUBLISHABLE_KEY as string
  },
  'default': {
    name: 'Dream Resorts',
    greeting: 'Aloha',
    thankyou: 'Mahalo',
    brandId: 'maui_resort_rentals',
    brandCode: 'DRR',
    backgroundUrl: '/assets/background/bg_img2.webp',
    logoUrl: '/logo/mrr_logo_full.svg',
    iconUrl: '/logo/mrr_logo_small.svg',
    logoUrlDark: '/logo/mrr_logo_full_dark.svg',
    iconUrlDark: '/logo/mrr_logo_small_dark.svg',
    website: 'https://www.mauiresortrentals.com',
    supportEmail: 'experience@mauiresortrentals.com',
    supportPhone: '(888) 468-0578',
    locale: 'Maui',
    timezone: 'Pacific/Honolulu',
    timezoneCode: 'HT',
    address: {
      streetAddress: '10 Halawai Dr #A-3',
      city: 'Lahaina',
      state: 'HI',
      postalCode: '96761'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false,
    },
    stripePublishableKey: process.env.REACT_APP_DRR_STRIPE_PUBLISHABLE_KEY as string
  }
};

// Determine the current hostname, environment variable will override
const currentHostname = process.env.REACT_APP_HOSTNAME || window.location.hostname;

// Select the brand configuration based on the current hostname
const brandConfig = domainBrand[currentHostname] || domainBrand.default; // Fallback to 'default' if no match

export default brandConfig;
