/**
 * @param numBaths Number of bathrooms
 * @returns formatted display string
 */
export function fBathrooms(numBaths: number) {
    if (numBaths <= 1) {
        return `${numBaths} Bath`;
    }
    return `${numBaths} Baths`;
}
