import {
    Alert, AlertTitle, Stack
} from "@mui/material";
import { convertRTKErrorToFriendly } from "../../utils/mrr/errorHandling";
import { standardFBInternalMessage, standardSFInternalMessage } from "../../redux/middleware/rtkQueryActionHandler";

interface StandardErrorProps {
    error: any,
    endpoint: string,
    mutationCall: boolean, // as opposed to 'query' call
    tableMode?: boolean
}


export function StandardRTKQError({ error, endpoint, mutationCall, tableMode }: StandardErrorProps) {
    const friendlyInfo = convertRTKErrorToFriendly(error, endpoint, mutationCall);

    return (
        <Stack
            marginX={tableMode ? 2 : 0}
            marginTop={tableMode ? 2 : 0}
        >
            <Alert severity="error">
                {/*KEEP: Valid code. This feature has come and gone.
                <AlertTitle>Error</AlertTitle>
                */}
                {friendlyInfo.message} Our team has been notified.
                {/*KEEP: Valid code. This feature has come and gone.
                {(friendlyInfo.detail !== null
                    && friendlyInfo.detail !== standardFBInternalMessage
                    && friendlyInfo.detail !== standardSFInternalMessage) &&
                <>
                    <br />
                    <span style={{fontSize: '0.8em'}}>
                        {friendlyInfo.detail}
                    </span>
                </>
                }
                */}
            </Alert>
        </Stack>
    )
}
