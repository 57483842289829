import { Alert, Button, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { PATH_APP } from '../../routes/paths';
import { CheckinRequestForm } from '../../sections/reservation/CheckInRequest/CheckinRequestForm';
import { AddService } from '../../sections/reservation/ROI/AddService';
import { fBathrooms } from '../../utils/mrr/fBathrooms';
import { fBedrooms } from '../../utils/mrr/fBedrooms';
import { CenteredRow } from '../containers/CenteredRow';
import Iconify from '../iconify';
import MenuPopover from '../menu-popover';
import { fReservationCheckInOut } from './fReservationCheckInOut';
import useReservationUIConfig from '../../models/useReservationUIConfig';

// ----------------------------------------------------------------------
type Props = {
    reservation: ReservationGeneral,
    disableNav?: boolean;
    isProperty?: boolean // if true, will not show the property name, will not show the property image chip, and will not show reservation details
};

export function ReservationCardContent({
    reservation,
    disableNav = false,
    isProperty = false
}: Props) {
    const {
        name,
        name_detailed: property,
        nights,
        bedrooms,
        bathrooms,
        unit_view,
        balance_due,
        is_hybrid_child,
    } = reservation;
    const { Add_Service, Display_Add_Check_In_Request, Disable_Add_Check_In_Request } = useReservationUIConfig(reservation)
    const [open, setOpen] = useState<HTMLElement | null>(null);
    const [openAddService, setOpenAddService] = useState(false);
    const [openCheckInRequest, setOpenCheckInRequest] = useState(false);
    // const [openExtendStay, setOpenExtendStay] = useState(false);

    // const handleOpenExtendStay = () => {
    //     setOpenExtendStay(true);
    // }
    // const handleCloseExtendStay = () => {
    //     setOpenExtendStay(false);
    // }
    const handleOpenAddService = () => {
        setOpenAddService(true);
    }
    const handleCloseAddService = () => {
        setOpenAddService(false);
    }
    const handleOpenCheckInRequest = () => {
        setOpenCheckInRequest(true);
    }
    const handleCloseCheckInRequest = () => {
        setOpenCheckInRequest(false);
    }

    const handleCloseMenu = () => {
        setOpen(null);
    }
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setOpen(event.currentTarget);
    }

    const navigate = useNavigate();
    const navigateToReservation = () => {
        if (disableNav) {
            return;
        }
        navigate(PATH_APP.createReservation(name));
    };

    const showMenuRule = is_hybrid_child
        ? (Add_Service || Display_Add_Check_In_Request)
        : false;

    const showMenu = !reservation.require_guest_registration && showMenuRule;

    return (
        <>
            <Stack
                id={`${name}_reservation_card_content`}
                flexGrow={1}
                onClick={navigateToReservation}
                sx={{
                    ...(!disableNav && {
                        cursor: 'pointer',
                        position: 'relative',
                    })
                }}
            >
                {(reservation.require_guest_registration
                    && !reservation.guest_registration_complete
                    && reservation.guest_registration_requirements.length > 0) &&

                    <Alert severity='warning'>Registration Required</Alert>
                }

                <Stack id={`${name}_reservation_card_content_header`}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='h6'
                    >
                        {!isProperty ? property : name}
                    </Typography>
                    {showMenu && <IconButton
                        onClick={handleOpenMenu}>
                        <Iconify
                            width={18}
                            icon="eva:more-horizontal-fill" />
                    </IconButton>}
                </Stack>
                <Stack id={`${name}_reservation_card_content_details`}
                    spacing={1}
                >
                    {!isProperty &&
                        <Stack
                            direction="row"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <CenteredRow sx={{ mr: 1 }}>
                                <Iconify icon="mdi:bedroom-outline" width={18} sx={{ mr: 0.5 }} />
                                <Typography variant='caption' whiteSpace='nowrap'>{fBedrooms(bedrooms)}</Typography>
                            </CenteredRow>

                            <CenteredRow sx={{ mr: 1 }}>
                                <Iconify icon="mdi:bathroom" width={18} sx={{ mr: 0.5 }} />
                                <Typography variant='caption' whiteSpace='nowrap'>{fBathrooms(bathrooms)}</Typography>
                            </CenteredRow>

                            <CenteredRow sx={{ mr: 1 }}>
                                <Iconify icon="uil:scenery" width={18} sx={{ mr: 0.5 }} />
                                <Typography variant='caption' whiteSpace='nowrap'>{unit_view}</Typography>
                            </CenteredRow>

                        </Stack>}
                    {nights > 0 &&
                        <Stack
                            alignItems='flex-start'
                            direction='row'>
                            <Iconify icon="ion:calendar-outline" width={18} sx={{ mr: 0.5 }} />
                            <Typography sx={{ wordBreak: 'break-word' }} variant='body2'>{fReservationCheckInOut(reservation)} ({nights} {nights > 1 ? 'nights' : 'night'})</Typography>
                        </Stack>}

                </Stack>
            </Stack>
            {showMenu &&
                <MenuPopover
                    open={open}
                    onClose={handleCloseMenu}
                    arrow="top-right"
                    sx={{ width: 'fit-content' }}
                > <Stack
                    spacing={0.5}>
                        {Display_Add_Check_In_Request
                            && <Button
                                disabled={Disable_Add_Check_In_Request}
                                sx={{ justifyContent: 'flex-start' }}
                                onClick={handleOpenCheckInRequest}
                                color={balance_due > 0 ? 'warning' : 'primary'}
                                startIcon={<Iconify icon='mdi:plus-circle-outline' />}
                            >
                                Add Check In Request
                            </Button>}
                        {Add_Service
                            && <Button
                                sx={{ justifyContent: 'flex-start' }}
                                onClick={handleOpenAddService}
                                startIcon={<Iconify icon='mdi:room-service-outline' />}
                            >
                                Add Service
                            </Button>}
                    </Stack>
                </MenuPopover>
            }
            {openCheckInRequest
                && Display_Add_Check_In_Request
                && <CheckinRequestForm
                    onClose={handleCloseCheckInRequest}
                    open={openCheckInRequest}
                    reservation={reservation} />}
            {openAddService
                && Add_Service
                && <AddService
                    open={openAddService}
                    handleClose={handleCloseAddService}
                    reservation={reservation} />
            }
            {/* {openExtendStay
                && Display_Extend_Stay_Button
                && <ExtendStay taxRate={0} reservationGeneral={reservation} showDialog={openExtendStay} titleText='Extend Stay' onClose={handleCloseExtendStay} />} */}
        </>
    );
}
