import { Button, Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { SeverErrorIllustration } from '../assets/illustrations';
import { MotionContainer, varBounce } from '../components/animate';
import fTitle from '../utils/mrr/formatTitle';

// ----------------------------------------------------------------------

export default function ErrorPage() {
    const { state } = useLocation()

    const text = state?.error || 'Error! Please try again.'
    const subHeader = state?.subHeader || null
    return (
        <>
            <Helmet>
                <title>{fTitle('Error')}</title>
            </Helmet>

            <MotionContainer>
                <Stack alignItems='center'>
                    <m.div variants={varBounce().in}>
                        <Typography variant="h3">
                            {text}
                        </Typography>
                    </m.div>

                    {subHeader &&
                        <m.div variants={varBounce().in}>
                            <Typography variant="body1" maxWidth={300}>
                                {subHeader}
                            </Typography>
                        </m.div>
                    }

                    <m.div variants={varBounce().in}>
                        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                    </m.div>

                    <Button component={RouterLink} to='/' variant="text">
                        Back To Home
                    </Button>
                </Stack>
            </MotionContainer>
        </>
    );
}
