// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';
import './utils/mapboxgl';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { MotionLazyContainer } from './components/animate';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import ThemeLocalization from './locales';
import { persistor, store, } from './redux/store';
import Router from './routes';
import ThemeProvider from './theme';

import { AuthProvider } from './auth/FirebaseContext';
import ReduxMessageProvider from './components/error-message/ErrorMessageProvider';
import './firebase_emulator.css';
// ----------------------------------------------------------------------
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import * as Sentry from "@sentry/react";
import { StandardErrorFallback } from './components/error-handling/StandardErrorFallback';
import { EmergencyFallbackErrorText } from './utils/mrr/errorHandling';
import { AnalyticsProvider } from './analytics/AnalyticsProvider';

export default function App() {
  return (
    <BrowserRouter>
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          console.warn('sentry captured error', scope);
          scope.setTag('error scope', 'root sentry');
        }}
        fallback={<StandardErrorFallback reportToSentry={false} errorInfo={null} customErrorMessage={EmergencyFallbackErrorText} />}
      >
        <AuthProvider>
          <AnalyticsProvider>
            <HelmetProvider>
              <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                      <ScrollToTop />
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeSettings>
                            <ThemeLocalization>
                              <SnackbarProvider>
                                <StyledChart />
                                <ReduxMessageProvider>
                                  <Router />
                                </ReduxMessageProvider>
                              </SnackbarProvider>
                            </ThemeLocalization>
                          </ThemeSettings>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </SettingsProvider>
                  </LocalizationProvider>
                </PersistGate>
              </ReduxProvider>
            </HelmetProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  );
}
