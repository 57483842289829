import { LoadingButton } from "@mui/lab";
import {
    Button,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useMemo, useState } from "react";
import { PriceBreakdownListPlusTax } from "src/components/price/PriceBreakdownListPlusTax";
import { SplitRow } from "../../../components/containers/SplitRow";
import {
    ROIGeneral
} from "../../../models/ROIGeneral";
import { FDateFormat, fDate } from "../../../utils/formatTime";

const MAX_LENGTH = 255;

// -----------------------------
export function AddServiceNotes(
    {
        roi,
        selectService,
        isLoading,
        handleConfirm,
        handleNavBack,
        notes,
        setNotes,
    }: {
        notes: string;
        setNotes: (notes: string) => void;
        selectService: (service: ROIGeneral) => void;
        roi: ROIGeneral;
        isLoading: boolean;
        handleConfirm: (roi: ROIGeneral) => Promise<void>;
        handleNavBack: () => void;
    }
) {
    const [inputError, setInputError] = useState<string | null>(null);
    const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= MAX_LENGTH) {
            setInputError(null)
            setNotes(event.target.value);
            return
        }
        setNotes(event.target.value);
        setInputError(`Special instructions cannot exceed ${MAX_LENGTH} characters.`)
    }
    const handleUpdateROI = (description?: string) => {
        if (description) {
            roi.description = description
        }
        selectService(roi);
        return roi
    }

    const handleContinue = async () => {
        let paymentROI = roi
        if (notes && !inputError) {
            paymentROI = handleUpdateROI(notes.trim());
        }
        await handleConfirm(paymentROI);
    }

    const { amount, discount, tax_amount, tax_rate, total_amount, id: roi_ID, subtotal } = roi
    const hasDiscount = discount > 0;
    const isCreate = !roi_ID;

    const priceBreakdown = useMemo(() => {
        // [[ROI TAXES]] We no longer show these details.
        // When in create mode we will use the tax rate as there is no tax amount or total amount on the payload.
        // const tax = typeof tax_amount === 'number' ? tax_amount : Number((amount * (tax_rate / 100)).toFixed(2));

        const data = hasDiscount
            ? [
                [{ label: 'Amount', value: amount, display: true }],
                [{ label: 'Discount', value: discount, display: true }],
                [{ label: 'SubTotal', value: subtotal, display: true }],
                // [{ label: 'Tax', value: tax, display: true }],
            ]
            : [
                [{ label: 'Amount', value: amount, display: true }],
                // [{ label: 'Tax', value: tax, display: true }],
            ]

        if (!isCreate) {
            // When in edit mode we will use the total amount
            data.push([{ label: 'Total', value: total_amount, display: true }],)
        }
        return data
    }, [amount, discount, hasDiscount, isCreate, total_amount, subtotal])

    return (
        <>
            <Stack>
                <Typography
                    variant='caption'
                    color='text.secondary'>
                    Preferred Time
                </Typography>
                <Typography
                    variant='body1'>
                    {roi.start_time_ISO ? fDate(roi.start_time_ISO, FDateFormat.localized_date_time_full) : 'No time selected'}
                </Typography>
            </Stack>
            <Stack>
                <TextField
                    disabled={isLoading}
                    multiline
                    minRows={3}
                    maxRows={6}
                    fullWidth
                    label='Special Instructions'
                    value={notes}
                    onChange={handleChangeNotes} />
                {!inputError && <Typography variant="caption" color="text.secondary">
                    Notes and details for this service
                </Typography>}
                {inputError && <Typography variant="caption" color="error.main">
                    {inputError}
                </Typography>}
            </Stack>
            {/* [[ROI TAXES]] We no longer show these details. */}
            {/* <PriceBreakdownList
                disableCalculatedTotal={!isCreate}
                rows={priceBreakdown} /> */}
            <PriceBreakdownListPlusTax
                disableCalculatedTotal={!isCreate}
                rows={priceBreakdown} />

            <SplitRow>
                <Button
                    variant="text"
                    onClick={handleNavBack}>
                    Back
                </Button>
                <LoadingButton
                    disabled={isLoading || inputError !== null}
                    variant="contained"
                    loading={isLoading}
                    onClick={handleContinue}>
                    Continue to Payment
                </LoadingButton>
            </SplitRow>
        </>
    );
}
