import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";

export function SplitRow({ children, top = false, spacing }: PropsWithChildren<{ top?: boolean, spacing?: number }>) {
    return (
        <Stack
            spacing={spacing}
            direction='row'
            justifyContent='space-between'
            alignItems={top ? 'flex-start' : 'center'}
            flexGrow={1}>
            {children}
        </Stack>
    )
}