import {
    Middleware,
    MiddlewareAPI,
    isRejected
} from '@reduxjs/toolkit';
import { FirebaseError } from 'firebase/app';
import { signOut } from 'firebase/auth';
import { getFriendlyPicklistName } from 'src/sections/registration/types';
import { cloud_patchRegistrationStep } from 'src/utils/mrr/cloudFunctions';
import {
    addError,
    addMessage
} from '../slices/apiMessage';
// eslint-disable-next-line import/no-cycle
import { AUTH } from '../../auth/FirebaseContext';
import { CheckLocalDev } from '../../config-global';
import { EmergencyFallbackErrorText } from '../../utils/mrr/errorHandling';
import { sendToSentry } from '../../utils/mrr/sentryReporter';
import { apiSlice } from '../rtkQuery/apiSlice';
import {
    MutationMessageProviderErrorText,
    MutationMessageProviderSuccessText,
    QueryMessageProviderErrorText,
    QueryMessageProviderSuccessText
} from './apiMessageTexts';

const verboseLogging = false && CheckLocalDev();

export const standardFBExpiredMessage = 'Session expired.';
export const standardFBInternalMessage = 'internal';
export const standardSFInternalMessage = 'There was a problem.';

const checkActionIsFirebaseExpiration = (action: any) => {
    return (action
        && (action.payload
            && action.payload.name === 'FirebaseError'
            && action.payload.message === standardFBExpiredMessage)
        && (action.error && action.error.message === 'Rejected'));
};

const parsedRejectedAction = (action: any, endpointName: string, mutationCall: boolean): string => {
    if (action.payload && action.payload.message) {
        // first, we try to parse this as a SF-originating error object
        let parsedJSON = null;
        if (typeof action.payload.message === 'string') {
            if (action.payload.message !== '') {
                try {
                    parsedJSON = JSON.parse(action.payload.message);
                }
                catch (parseException: any) {
                    //NOTE: Not necessarily a problem! We get varied error formats.
                    //      It could be just another format we haven't seen.
                }
            }
        }

        if (parsedJSON !== null) {
            verboseLogging && console.warn('Reporting as SF-originating error:', parsedJSON.status, parsedJSON.error);
        }
        else {

            verboseLogging && console.warn('Reporting as non-SF error:', '(' + action.payload.code + ')', action.payload.message);
        }

        const standardMappedMessage = mutationCall
            ? MutationMessageProviderErrorText[endpointName]
            : QueryMessageProviderErrorText[endpointName];

        if (standardMappedMessage) {
            return standardMappedMessage;
        }

        verboseLogging && console.warn('unmapped endpoint error: ' + endpointName);
    }

    verboseLogging && console.warn('fallback text for: ' + endpointName)

    return EmergencyFallbackErrorText;
};

export const rtkQueryActionHandler: Middleware =
    (api: MiddlewareAPI<any, any>) => (next) => (action) => {
		// this is spammy, but useful for debugging rtk actions
		// if (CheckEnvironment(SupportedEnvironments.Dev)) {
		//     console.log('rtk action: ' + ((action && action.meta && action.meta.arg) ? action.meta.arg.endpointName : 'n/a'), action);// [[TEMP-BREADCRUMB]]
		// }

        if (checkActionIsFirebaseExpiration(action)) {
            verboseLogging && console.warn('Firebase session expired');
            api.dispatch(addError({ error: 'This session has expired.', id: action, showSnackbar: true }));

            //! wipe the RTK Query cache
            api.dispatch(apiSlice.util.resetApiState());

            signOut(AUTH)
                .then((x: any) => {
                    verboseLogging && console.log('middleware signOut THEN', x);
                })
                .catch((y: any) => {
                    verboseLogging && console.log('middleware signOut CATCH', y);
                });
        }

        const { meta } = action

        if (!meta || !meta.arg || !meta.arg.endpointName || meta.requestStatus === 'pending') {
            return next(action)
        }
        const { arg: { endpointName, type }, requestId } = meta

        // Failure ----------------------
        if (isRejected(action)) {
            // vvvvvvvv
            //NOTE: Tracking down the false-positive reports, e.g. non-200 errors that go to Sentry, but do not exist in the GCF logs.
            //      I suspect it's a call that's pending (or a similar mid-way state) then gets canceled
            //      by a page change or disconnect, then comes through here.
            //
            //SEE: https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
            // If it was cancelled before execution, meta.condition will be true.
            // If it was aborted while running, meta.aborted will be true.
            // If neither of those is true, the thunk was not cancelled, it was simply rejected, either by a Promise rejection or rejectWithValue.
            if (meta.condition === true) {
                console.log('action cancelled before execution');
            }
            if (meta.aborted === true) {
                console.log('action aborted while running');
            }
            // ^^^^^^^^

            const shouldReport = meta.condition === false && meta.aborted === false;

            const errorMsg = parsedRejectedAction(action, endpointName, type === 'mutation');
            if (errorMsg) {
                api.dispatch(addError({ id: requestId, error: errorMsg, showSnackbar: false }))

                if (shouldReport) {
                    verboseLogging && console.log('middleware sentry capture', errorMsg);

                    const nullOrFirebaseError = action.payload instanceof FirebaseError ? action.payload : null

                    sendToSentry(nullOrFirebaseError,
                        errorMsg,
                        'gcf endpoint error',
                        endpointName,
                        {
                            action: action,
                            error: action.error,
                            meta: meta,
                            arg: meta ? meta.arg : 'n/a',
                            payload: action.payload,
                            time: (new Date()).toLocaleString(),
                            timeUTC: (new Date()).toUTCString()
                        })
                }
            }

            return next(action)
        }

        // Success ----------------------
        if (type === 'query') {
            const message = QueryMessageProviderSuccessText[endpointName]
            if (message) {
                api.dispatch(addMessage({ id: requestId, message }))
            }
        }
        else if (type === 'mutation') {
            let message = MutationMessageProviderSuccessText[endpointName]
            // we special-case registration step updates with extra detail
            if (meta && meta.arg && meta.arg.endpointName === cloud_patchRegistrationStep) {
                if (meta.arg.originalArgs.registrationStep) {
                    const stepName = getFriendlyPicklistName(action.meta.arg.originalArgs.registrationStep);
                    message = stepName + ' completed.';
                }
            }
            if (message) {
                api.dispatch(addMessage({ id: requestId, message }))
            }
        }

        return next(action)
    };
