import { Masonry } from "@mui/lab";
import { Card } from "@mui/material";
import { StyledCardContent } from "../../components/containers/StyledCardContent";
import { GPISLinks } from "../../components/gpis/GPISLinks";
import useResponsive from "../../hooks/useResponsive";
import { GPISDetailSection } from "../../models/GPISDetailSection";

export function GPISMultiInfo({
    data,
}: {
    data: {
        id?: string; title: string; items: GPISDetailSection[];
    }[];
}) {
    const downSm = useResponsive('down', 'sm')
    const downMd = useResponsive('down', 'md')
    return (
        <Card raised>
            <StyledCardContent>
                <Masonry columns={downSm ? 1 : downMd ? 3 : undefined}>
                    {data.map(({ id, title, items }) => <GPISLinks key={id} title={title} items={items} />)}
                </Masonry>
            </StyledCardContent>
        </Card>
    );
}
