import Iconify from '../../../components/iconify';
import { PATH_ADMIN, PATH_APP, PATH_PAGE } from '../../../routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  // {
  //   title: 'Components',
  //   icon: <Iconify icon="ic:round-grain" />,
  //   path: PATH_PAGE.components,
  // },
  {
    title: 'Pages',
    path: '/pages',
    icon: <Iconify icon="eva:file-fill" />,
    children: [
      {
        subheader: 'Other',
        items: [
          // { title: 'About us', path: PATH_PAGE.about },
          // { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          // { title: 'Pricing', path: PATH_PAGE.pricing },
          // { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      // {
      //   subheader: 'Authentication',
      //   items: [
      //     { title: 'Login', path: PATH_AUTH.loginUnprotected },
      //     { title: 'Register', path: PATH_AUTH.registerUnprotected },
      //     { title: 'Reset password', path: PATH_AUTH.resetPassword },
      //     { title: 'Verify code', path: PATH_AUTH.verify },
      //   ],
      // },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Dashboard',
        items: [{ title: 'Dashboard', path: PATH_APP.app }],
      },
    ],
  },
  // {
  //   title: 'Documentation',
  //   icon: <Iconify icon="eva:book-open-fill" />,
  //   path: PATH_DOCS.root,
  // },
];

export default navConfig;

export const navConfigAdmin = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      { title: 'users', path: PATH_ADMIN.user.list, icon: <Iconify icon="ion:people-sharp" /> },
      { title: 'create user', path: PATH_ADMIN.user.new, icon: <Iconify icon="eva:plus-fill" /> },
      //TODO { title: 'Notifications', path: PATH_HOME.user.notifications, icon: ICONS.chat },
    ],
  },
];
