import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from '../auth/useAuthContext';
import { PATH_ADMIN } from '../routes/paths';
import signInAsOtherUser, { getSessionAdmin, getSessionGUID, hasSessionAdmin } from '../utils/mrr/emulator/signInAsOtherUser';
import LoadingScreen from '../components/loading-screen/LoadingScreen';
import { dispatch } from '../redux/store';
import { apiSlice } from '../redux/rtkQuery/apiSlice';
import { WritePostLoginPath } from '../config-global';
import { Roles } from '../utils/mrr/userConstants';
import { removeSessionAdmin } from '../auth/FirebaseContext';

export default function LogOutPage() {
    const { user, logout, isInitialized, setAdminSessionChanging } = useAuthContext();
    const navigate = useNavigate();
    useEffect(() => {
        //[[USER LOGOUT]]
        // wipe the RTK Query cache
        dispatch(apiSlice.util.resetApiState());

        //NOTE: This conditional previously checked "isInitialized", which comes from
        //		authContext. We've seen it to be redundant, so far, but it's something
        //		to keep in mind.
        if (!user) {
            navigate('/', { replace: true });
            return;
        }
        logout();
        if (hasSessionAdmin()) {
            setAdminSessionChanging(true)
            signInAsOtherUser(true, user.id, getSessionAdmin()!, getSessionGUID()!).then(() => {
                removeSessionAdmin()
                navigate(PATH_ADMIN.user.list, { replace: true });
            }).catch(() => {
                navigate(WritePostLoginPath(Roles.User), { replace: true });
            });
        }
    }, [logout, navigate, user, isInitialized, setAdminSessionChanging]);
    return <LoadingScreen />;
}
