import {
    Alert, AlertTitle, Stack
} from "@mui/material";
import { safelyReadErrorMessage } from "../../utils/mrr/errorHandling";
import { standardSFInternalMessage } from "../../redux/middleware/rtkQueryActionHandler";
import { CheckEnvironment, SupportedEnvironments } from "../../config-global";

interface StandardErrorProps {
    error: any,
    useGenericText?: boolean,
    tableMode?: boolean,
    suppressOurTeamSuffix?: boolean
}


export function StandardError({ error, useGenericText, tableMode, suppressOurTeamSuffix }: StandardErrorProps) {
    let friendlyInfo = safelyReadErrorMessage(null, error);

    // handle the cryptic Firebase offline error translations
    if (useGenericText || friendlyInfo === 'internal' || friendlyInfo === 'true') {
        friendlyInfo = standardSFInternalMessage

        if (CheckEnvironment(SupportedEnvironments.Dev)) {
            console.log('(dev only log) using generic error text:', error);
        }
    }

    return (
        <Stack
            marginX={tableMode ? 2 : 0}
            marginTop={tableMode ? 2 : 0}
        >
            <Alert severity="error">
                {/*KEEP: Valid code. This feature has come and gone.
                <AlertTitle>Error</AlertTitle>
                */}
                {friendlyInfo} {suppressOurTeamSuffix ? '' : 'Our team has been notified.'}
                {/* NOTE: If a detail is needed, see StandardRTKQError */}
            </Alert>
        </Stack>
    )
}
