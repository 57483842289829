import { CSSProperties } from "react";

export const containerStyle: CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	margin: 0,
};

export const itemRowStyle: CSSProperties = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '4px',
	width: '100%',
	maxWidth: '600px',
};

export const itemStatusStyle: CSSProperties = {
	width: '30px',
	textAlign: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const itemNameStyle: CSSProperties = {
	flexGrow: 1,
	paddingLeft: '10px'
};
