import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { SplitRow } from '../../../components/containers/SplitRow';
import { FullscreenModal, FullscreenModalProps } from '../../../components/fullscreen-modal/FullscreenModal';
import Iconify from '../../../components/iconify';
import MenuPopover, { MenuPopoverProps } from '../../../components/menu-popover';
import { createMailToSupportHref, supportEmail, supportPhone } from '../../../utils/mrr/contactUs';

export function ContactUsPopover(props: Omit<MenuPopoverProps, 'onClose'> & { onClose: () => void; }) {
    const { onClose } = props;
    const handleClose = () => { onClose && onClose(); };
    return (
        <MenuPopover {...props}>
            <MenuItem
                component='a'
                href={createMailToSupportHref('Need Assistance')}
                sx={{ color: 'primary.main' }}
                onClick={handleClose}
            >
                <Iconify icon="eva:email-outline" />
                {supportEmail}
            </MenuItem>
            <MenuItem
                component='a'
                href={`tel:${supportPhone}`}
                sx={{ color: 'primary.main' }}
                onClick={handleClose}
            >
                <Iconify icon="eva:phone-outline" />
                {supportPhone}
            </MenuItem>
        </MenuPopover>
    );
}
export function ContactUsModal(props: Omit<FullscreenModalProps, 'titleText'>) {
    return (
        <FullscreenModal titleText='Contact Support' {...props}>
            <Stack spacing={1}>
                <ContactUsButton label={supportEmail} icon="eva:email-outline" href={createMailToSupportHref('Need Assistance')} />
                <ContactUsButton label={supportPhone} icon="eva:phone-outline" href={`tel:${supportPhone}`} />
            </Stack>
        </FullscreenModal>
    );
}

function ContactUsButton({ href, label, icon, onClick }: { href: string, label: string, icon: string, onClick?: () => void }) {
    return (
        <Button
            size='large'
            color='primary'
            onClick={onClick}
            component='a'
            sx={{ textTransform: 'none' }}
            href={href}
            startIcon={<Iconify icon={icon} />}
        >
            <SplitRow>
                <Typography variant='body1'>
                    {label}
                </Typography>
                <Iconify color='inherit' icon='mdi:chevron-right' />
            </SplitRow>
        </Button>
    )
}
