import {
    Skeleton,
    Stack
} from "@mui/material";

export function SectionLoading({ size, height }: { size?: number, height?: number }) {
    const skeletonHeight = height || 65;
    const loopingArray = Array(size || 1).fill('loading_component');
    return (
        <Stack flexGrow={1}>
            {loopingArray.map((genericName, index) => (
                <Skeleton key={`${genericName}_${index}`} height={skeletonHeight} />
            ))
            }
        </Stack>
    );
}
