import { LoadingButton } from "@mui/lab";
import {
    Button,
    Stack, Typography
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { GAEventAddCheckinRequest, useAnalyticsContext } from "../../../analytics";
import { mapRequestToIcon } from "../../../components/containers/CardPickerInput";
import { SplitRow } from "../../../components/containers/SplitRow";
import { FullscreenModal } from "../../../components/fullscreen-modal/FullscreenModal";
import { RHFTextField } from "../../../components/hook-form";
import FormProvider from "../../../components/hook-form/FormProvider";
import { SectionLoading } from "../../../components/loading-screen/SectionLoading";
import {
    CheckInRequest,
    CheckInRequestOptions,
    ReadOnlyCheckInRequest,
    ReservationGeneral
} from "../../../models/ReservationGeneral";
import { useGetHybridReservationsQuery, usePatchCheckinRequestMutation } from "../../../redux/rtkQuery/apiSlice";
import { PATH_APP } from "../../../routes/paths";
import { cloud_getHybridReservations, cloud_patchCheckinRequest } from "../../../utils/mrr/cloudFunctions";
import { createFormId } from "../../../utils/mrr/createFormId";
import { StandardRTKQError } from "../../error/StandardRTKQError";
import { SelectHybridChild } from "../ROI/AddService/SelectHybridChild";
import CheckinRequestSelect, { CheckInRequestFormState, CheckInRequestInputName } from "./CheckinRequestSelect";

interface CheckinRequestFormProps {
    onClose: VoidFunction,
    open: boolean,
    reservation: ReservationGeneral,
}

enum StepInForm {
    SelectHybrid,
    EditRequest
}

function createDefaultValues(reservation: ReservationGeneral): CheckInRequestFormState {
    return {
        [CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS]: CheckInRequestOptions.filter((option) => {
            if (!reservation.split_bed_allowed && option === CheckInRequest.SPLIT_BED) {
                return false
            }
            return reservation.check_in_requests.includes(option)
        }),
        [CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS]: CheckInRequestOptions.filter((option) => {
            if (!reservation.split_bed_allowed && option === CheckInRequest.SPLIT_BED) {
                return false
            }
            return !reservation.check_in_requests.includes(option)
        }),
        [CheckInRequestInputName.CHECK_IN_REQUESTS_DETAIL]: reservation.check_in_requests_detail || '',
        [CheckInRequestInputName.CHECK_IN_REQUESTS]: reservation.check_in_requests || [],
    }
}

export function CheckinRequestForm({
    onClose,
    open,
    reservation,
}: CheckinRequestFormProps) {
    const [stepInForm, setStepInForm] = useState(reservation.is_hybrid_parent ? StepInForm.SelectHybrid : StepInForm.EditRequest)
    const navigate = useNavigate()
    const { sendEvent } = useAnalyticsContext()

    const [
        sendPatchCheckinRequest,
        {
            isLoading: patchIsLoading,
            isError: patchIsError,
            error: patchError
        }
    ] = usePatchCheckinRequestMutation();

    const {
        data: hybridReservations,
        isFetching: hybridReservationsFetching,
        isError: hybridReservationsError,
        error: hybridReservationsErrorObject
    } = useGetHybridReservationsQuery({ reservationName: reservation.name }, { skip: !reservation.is_hybrid_parent })

    // -------------------

    const [selectedHybridReservation, setSelectedHybridReservation] = useState<ReservationGeneral | null>(null)
    const methods = useForm<CheckInRequestFormState>({
        defaultValues: createDefaultValues(reservation)
    });
    const { handleSubmit, formState: { isDirty, isSubmitting }, setValue, getValues, reset } = methods;

    const onSubmit = handleSubmit(async ({
        activeSelectedOptions: checkInRequest,
        check_in_requests_detail: checkInRequestDetail }) => {
        const readonlyOptions = (Object.values(ReadOnlyCheckInRequest) as any[]).filter((option) => reservation.check_in_requests.includes(option))
        await sendPatchCheckinRequest({
            reservationName: // for cache tagging, only
				selectedHybridReservation
				? selectedHybridReservation.name
				: reservation.name,
            check_in_requests: [...readonlyOptions, ...checkInRequest],
            check_in_requests_detail: checkInRequestDetail
        }).then((data: any) => {
            if (!data.error) {
                if (selectedHybridReservation) {
                    sendEvent(new GAEventAddCheckinRequest(checkInRequest, checkInRequestDetail, selectedHybridReservation.name))
                    navigate(PATH_APP.createReservation(selectedHybridReservation.name))
                    return
                }
                sendEvent(new GAEventAddCheckinRequest(checkInRequest, checkInRequestDetail, reservation.name))
                onClose();
                return
            }
            reset()
        })
    });

    // -----------------------------
    const handleSelectHybridReservation = (selection: ReservationGeneral) => {
        setSelectedHybridReservation(selection)
        reset(createDefaultValues(selection))
        setStepInForm(StepInForm.EditRequest)
    }
    const returnToSelectHybrid = () => {
        reset()
        setStepInForm(StepInForm.SelectHybrid)
    }

    const handleSelectionChange = (name: string, chipValue: string) => {
        const availableValues = getValues(CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS);
        const requestedValues = getValues(CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS);

        const setValueConfig = {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        };
        // const inputIsReqested = name === 'activeSelectedOptions'
        const inputIsAvailable = name === CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS;
        if (inputIsAvailable) {
            // we want to remove it from available and add it to requested
            setValue(CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS, [...requestedValues, chipValue] as CheckInRequest[], setValueConfig);
            setValue(CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS, availableValues.filter((val) => val !== chipValue), setValueConfig);
            return;
        }
        // we want to remove it from requested and add it to available
        setValue(CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS, requestedValues.filter((val) => val !== chipValue), setValueConfig);
        setValue(CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS, [...availableValues, chipValue] as CheckInRequest[], setValueConfig);

    };


    let options = CheckInRequestOptions.map((option) => ({ label: option, value: option }));
    if (selectedHybridReservation && !selectedHybridReservation.split_bed_allowed) {
        options = options.filter((option) => option.value !== CheckInRequest.SPLIT_BED);
    }
    if (!selectedHybridReservation && !reservation.split_bed_allowed) {
        options = options.filter((option) => option.value !== CheckInRequest.SPLIT_BED);
    }

    // ---------- Error handler
    if (hybridReservationsError) {
        return (
            <FullscreenModal showDialog={open} onClose={onClose} titleText='Check In Request'>
                <StandardRTKQError
                    mutationCall
                    endpoint={cloud_getHybridReservations}
                    error={hybridReservationsErrorObject} />
            </FullscreenModal>
        )
    }

    const submitButtonLoading = patchIsLoading || isSubmitting;
    const submitButtonDisabled = !isDirty || submitButtonLoading;
    return (
        <FullscreenModal
            showDialog={open}
            onClose={onClose}
            titleText='Check In Request'>
            {patchIsError &&
                <StandardRTKQError
                    mutationCall
                    endpoint={cloud_patchCheckinRequest}
                    error={patchError} />
            }
            {hybridReservationsFetching && <SectionLoading size={6} />}
            {stepInForm === StepInForm.SelectHybrid
                && hybridReservations
                && !hybridReservationsFetching
                && <SelectHybridChild reservations={hybridReservations} selectReservation={handleSelectHybridReservation} />}
            {stepInForm === StepInForm.EditRequest && !hybridReservationsFetching &&
                <FormProvider
                    name="checkin_request_form"
                    id={createFormId(selectedHybridReservation?.name || reservation.name, 'checkin_request_form')}
                    methods={methods}
                    onSubmit={onSubmit}>
                    <Stack spacing={2}>
                        <Stack
                            spacing={2}
                        >
                            <CheckinRequestSelect
                                onChange={handleSelectionChange}
                                label='Available'
                                name={CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS}
                                options={options}
                                mapIcon={mapRequestToIcon} />
                            <CheckinRequestSelect
                                onChange={handleSelectionChange}
                                label='Requested'
                                name={CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS}
                                options={options}
                                mapIcon={mapRequestToIcon} />
                            <Stack
                            >
                                <Typography
                                    variant="subtitle1">
                                    Notes
                                </Typography>
                                <RHFTextField
                                    // label='Notes'
                                    maxRows={10}
                                    multiline
                                    minRows={2}
                                    name='check_in_requests_detail' />
                            </Stack>
                        </Stack>
                        <Stack
                            width='100%'
                            justifyContent='flex-end'
                            direction='row'>
                            <SplitRow>
                                {selectedHybridReservation &&
                                    <Button
                                        disabled={submitButtonLoading}
                                        onClick={returnToSelectHybrid}>
                                        Back
                                    </Button>}
                                <LoadingButton
                                    loading={submitButtonLoading}
                                    disabled={submitButtonDisabled}
                                    type="submit"
                                    variant="contained">
                                    Submit
                                </LoadingButton>
                            </SplitRow>
                        </Stack>
                    </Stack>
                </FormProvider>}
        </FullscreenModal>
    );
}

