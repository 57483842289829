import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EmergencyFallbackErrorText } from '../../utils/mrr/errorHandling';
import { StandardErrorFallback } from './StandardErrorFallback';

function Fallback(error: any) {
    return (
        <StandardErrorFallback reportToSentry errorInfo={error} customErrorMessage={EmergencyFallbackErrorText} />
    );
}

export function PageErrorGuard({ children }: PropsWithChildren) {
    return (
        <ErrorBoundary fallbackRender={Fallback} >
            {children}
        </ErrorBoundary>
    );
}
