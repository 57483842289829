import { Stack } from "@mui/material";
import { GPISFaq } from "../../components/gpis/GPISFaq";
import { useGetPortalInfoQuery } from "../../redux/rtkQuery/apiSlice";
import { cloud_getResortAndListingGpi } from "../../utils/mrr/cloudFunctions";
import { StandardRTKLoader } from "../loader/StandardRTKLoader";
import { GPISInfo } from "./GPISInfo";
import { GPISMultiInfo } from "./GPISMultiInfo";
import { GPISChannel } from "../../models/GPISGeneral";

// ----------------------------------------------------------------------
export function GPISSection({ reservationName, listingId }: { reservationName?: string; listingId?: string }) {
    const {
        data: portalInfo,
        isError: portalInfoError,
        isSuccess: portalInfoSuccess,
        isFetching: portalInfoFetching,
        error: portalInfoErrorObject
    } = useGetPortalInfoQuery({
        ...(reservationName && { reservationName }),
        ...(listingId && { listingId })
    }, { skip: !reservationName && !listingId })

    if (!portalInfoSuccess) {
        return (
            <StandardRTKLoader
                endpoint={cloud_getResortAndListingGpi}
                mutationCall={false}
                isError={portalInfoError}
                isFetching={portalInfoFetching}
                loadedObjectName="more info"
                error={portalInfoErrorObject}
            />);
    }

    const {
        faq,
        channel_list,
        basic_information,
        contact_information,
        resort_information,
        safety_information,
        suite_information,
    } = portalInfo

    const multiInfoData = [];

    if (basic_information) {
        multiInfoData.push({ id: 'basic_information', title: basic_information.title, items: basic_information.sections });
    }

    if (contact_information) {
        multiInfoData.push({ id: 'contact_information', title: contact_information.title, items: contact_information.sections });
    }

    if (safety_information) {
        multiInfoData.push({ id: 'safety_information', title: safety_information.title, items: safety_information.sections });
    }

    if (channel_list.length > 0) {
        multiInfoData.push({ id: 'channel_list', title: 'Television', items: [GPISChannel.createGPISItem(channel_list)] });
    }

    return (
        <Stack spacing={1}>
            {faq && <GPISFaq faq={faq} />}
            {suite_information && <GPISInfo id='suite_information' showCategory title={suite_information.title} items={suite_information.sections} />}
            {resort_information && <GPISInfo id='resort_information' showCategory title={resort_information.title} items={resort_information.sections} />}
            {basic_information
                && contact_information
                && safety_information
                && <GPISMultiInfo data={multiInfoData} />
            }
        </Stack>
    );
}