import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { PATH_APP } from '../../routes/paths';
import Image from '../image';
import { ImageStyles } from '../image/style';

interface Props {
    reservation: ReservationGeneral;
    isProperty?: boolean; // if true, will not show the property name, will not show the property image chip, and will not show reservation details
    horizontal?: boolean;
}

export function ReservationCardImage({ reservation, isProperty, horizontal }: Props) {
    const { name, listing_photo_url } = reservation;
    const [imageSrc, setImageSrc] = useState(listing_photo_url);
    const [hasError, setHasError] = useState(false);

    const handleImageError = () => {
        // Set a different image source if the first one fails
        setImageSrc(`${process.env.PUBLIC_URL}/assets/background/beachFootprints.jpg`);
        setHasError(true);
    };

    const navigate = useNavigate();
    const navigateToReservation = () => {
        navigate(PATH_APP.createReservation(name));
    };


    return (
        <Box
            onClick={navigateToReservation}
            sx={
                horizontal
                    ? ImageStyles.ImageContainer.horizontal
                    : ImageStyles.ImageContainer.vertical}>
            {!isProperty &&
                <Chip
                    color='primary'
                    label={name}
                    sx={ImageStyles.ImageContainer.chip} />
            }
            <Image
                alt={hasError ? 'stock_reservation_image' : `${name}_list_image`}
                srcSet={imageSrc}
                onError={handleImageError}
                sx={{
                    height: 1,
                    borderRadius: 1.5,
                }} />
        </Box>
    );
}
