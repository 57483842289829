import { Card } from "@mui/material";
import { StyledCardContent } from "../../components/containers/StyledCardContent";
import { GPISLinks, GPISLinksProps } from "../../components/gpis/GPISLinks";

export function GPISInfo({ id, ...props }: GPISLinksProps & { id: string }) {
    return (
        <Card id={id} raised>
            <StyledCardContent>
                <GPISLinks {...props} />
            </StyledCardContent>
        </Card>
    );
}
