import { parseISO } from "date-fns";
import { ROIGeneral, ROIStatus, ROISubType, ROIType } from "./ROIGeneral";

export class InsurancePolicyDetail {
    public readonly effectivedate_ISO: Date
    constructor(
        public readonly coveragedescription: string,
        public readonly coveragetype: string,
        public readonly unlimited: boolean,
        private readonly _effectivedate: string,

        // may not be on payload
        public readonly individuallimit: number | null = null,
        public readonly policylimit: number | null = null,
    ) {
        this.effectivedate_ISO = parseISO(this._effectivedate)
    }
}

export class TravelInsuranceROIGeneral extends ROIGeneral {
    public start_time_ISO: Date | null = null
    public end_time_ISO: Date | null = null
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly status: ROIStatus,
        public description: string,
        public readonly type: ROIType,
        public readonly sub_type: ROISubType,
        public readonly listing_id: string,
        public readonly amount: number,
        public readonly tax_amount: number, // in dollar value $10.00
        public readonly discount: number,
        public readonly subtotal: number,
        public readonly total_amount: number,
        public readonly reservation_id: string,
        public readonly reservation_name: string,
        public readonly tax_rate: number, // 17.14%
        public readonly fee_type: string,
        public readonly insurance_policy_details: InsurancePolicyDetail[],
        public readonly service_window_start: string,
        public readonly service_window_end: string,
        public readonly start_time: string, // YYYY-MM-DDT00:00:00Z
        public readonly end_time: string, // YYYY-MM-DDT00:00:00Z
    ) {
        super(
            id,
            name,
            status,
            description,
            type,
            sub_type,
            listing_id,
            amount,
            tax_amount,
            discount,
            subtotal,
            total_amount,
            reservation_id,
            reservation_name,
            tax_rate,
            fee_type,
            service_window_start,
            service_window_end,
            start_time,
            end_time,
        )

        if (typeof this.start_time === 'string' && this.start_time.endsWith('Z')) {
            this.start_time_ISO = parseISO(this.start_time)
        }
        if (typeof this.end_time === 'string' && this.end_time.endsWith('Z')) {
            this.end_time_ISO = parseISO(this.end_time)
        }
    }
}

export function InstantiateTravelInsuranceROIFromJSON(json: any) {
    let insurancePolicyDetails = []
    if (json.insurance_policy_details) {
        insurancePolicyDetails = json.insurance_policy_details
            .map((detail: any) => new InsurancePolicyDetail(
                detail.coveragedescription,
                detail.coveragetype,
                detail.unlimited,
                detail.effectivedate,
                detail.individuallimit,
                detail.policylimit,
            ))
    }

    return new TravelInsuranceROIGeneral(
        json.id,
        json.name,
        json.status,
        json.description,
        json.type,
        json.sub_type,
        json.listing_id,
        json.amount,
        json.tax_amount,
        json.discount,
        json.subtotal,
        json.total_amount,
        json.reservation_id,
        json.reservation_name,
        json.tax_rate,
        json.fee_type,
        insurancePolicyDetails,
        json.service_window_start,
        json.service_window_end,
        json.start_time,
        json.end_time,
    )

}