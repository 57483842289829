// usePublicIpAddress.ts
import { useState, useEffect } from 'react';

// Define the URL for the IP address API
const IPIFY_URL = 'https://api.ipify.org?format=json';

const usePublicIpAddress = () => {
    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await fetch(IPIFY_URL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (e) {
                setError('Unable to fetch IP address');
            } finally {
                setLoading(false);
            }
        };

        fetchIpAddress();
    }, []);

    return { ipAddress, loading, error };
};

export default usePublicIpAddress;
