/* eslint-disable import/order */
import ReactDOM from 'react-dom/client';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import * as Sentry from "@sentry/react";
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { CheckEnvironment, CheckLocalDev, SupportedEnvironments } from './config-global';
import { clientVersion } from './utils/mrr/version';
import { brandConfig } from './config';

if (!brandConfig) {
    throw new Error('Missing Brand.')
}

//Not a private variable, but a public one
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS
if (!SENTRY_DNS) {
    throw new Error('Missing REACT_APP_SENTRY_DNS variable.')
}

const devBuild = CheckEnvironment(SupportedEnvironments.Dev);

const sentryDebugEnabled = false;

if (!CheckLocalDev()) {
    Sentry.init({
        debug: devBuild && sentryDebugEnabled,
        dsn: SENTRY_DNS,
        release: clientVersion,
        environment: devBuild ? 'development' : 'production',
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            new Sentry.Replay()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: devBuild
            ? [/^https:\/\/remote-dev\.guest-portal-v2\.pages\.dev/]
            : [/^https:\/\/guest\.mauiresortrentals\.com/],
        //TODO: Maybe not. It may be that we don't need this at all ^^.
        //      https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/

        // Capture Replay for 10% of all sessions,
        replaysSessionSampleRate: 0.1,
        // plus for 100% of sessions with an error
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
