import { Button, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SeverErrorIllustration } from '../../assets/illustrations';
import { PATH_APP } from '../../routes/paths';
import { MotionContainer, varBounce } from '../animate';
import { CenteredStack } from '../containers/CenteredStack';
import { sendToSentry } from '../../utils/mrr/sentryReporter';

interface StandardErrorFallbackProps {
    errorInfo: any,
    customErrorMessage: string,
    reportToSentry: boolean,
    fingerprintDetail?: string,
    fingerprintScope?: string
}

export function StandardErrorFallback( {
    errorInfo,
    customErrorMessage,
    reportToSentry,
    fingerprintDetail,
    fingerprintScope
}: StandardErrorFallbackProps) {
    const [reported, setReported] = useState(false);

    //NOTE: ChunkLoadError happens when a browser is looking for an outdated chunk. This is due
    //      to a version change, possibly in conjunction with a stale session and certain browser
    //      cache behavior. I didn't investigate too deeply, because no matter what, our solution
    //      is to reload the page.
    //      There is a much more involved technique before to abstract a lazy-loading manager.
    //      It looks interesting, but it's major overkill for the current problem.
    //
    // https://sentry.io/answers/chunk-load-errors-javascript/
    // https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
    if (errorInfo && errorInfo.error) {
        let chunkErrorDetected = false;

        if (errorInfo.error.name === 'ChunkLoadError') {
            console.log('ChunkLoadError detected via name');
            chunkErrorDetected = true;
        }
        else if (  typeof errorInfo.error.message === 'string'
                && errorInfo.error.message.indexOf('Loading chunk') === 0
                && errorInfo.error.message.indexOf('failed') !== -1) {
            console.log('ChunkLoadError detected via message');
            chunkErrorDetected = true;
        }

        if (chunkErrorDetected) {
            console.log('Reloading page for new version...');
            window.location.reload();
            return null;
        }

        // process this as a normal error
    }

    if (reportToSentry && !reported) {
        setReported(true);

        console.log('Reporting to Sentry', errorInfo);

        sendToSentry(   errorInfo.error,
                        null,
                        fingerprintScope ? fingerprintScope : 'runtime error',
                        fingerprintDetail ? fingerprintDetail : 'none');
    }

    return (
        <MotionContainer>
            <CenteredStack>
                <m.div variants={varBounce().in}>
                    <Typography variant="h5" paragraph>
                        {customErrorMessage}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                </m.div>

                <Button component={RouterLink} to={PATH_APP.app} variant="text">
                    Home
                </Button>
            </CenteredStack>
        </MotionContainer>
    );
}
