import { IconButton } from "@mui/material";
import Iconify from "../iconify";

export function CloseIconButton({ onClose }: { onClose: VoidFunction }) {
    return (
        <IconButton
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
            onClick={onClose}>
            <Iconify
                width={30}
                color='text.disabled'
                icon='eva:close-fill' />
        </IconButton>
    );
}