import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { sum } from 'lodash';
import {
    doc,
    getDoc
} from 'firebase/firestore';
import { ROIGeneral } from '../../models/ROIGeneral';
import { ReservationGeneral } from '../../models/ReservationGeneral';
// eslint-disable-next-line import/no-cycle
import { DB } from '../../auth/FirebaseContext';

// ----------------------------------------------------------------------

interface CartState {
    isLoading: boolean,
    error: null | string,
    checkout: Record<ReservationGeneral['name'], ROIGeneral[]>,
    subTotal: number,
    discount: number,
    total: number
}

const initialState: CartState = {
    isLoading: false,
    error: null,
    checkout: {},
    subTotal: 0,
    discount: 0,
    total: 0
};

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CHECKOUT
        setCart(state, action) {
            const cart = action.payload as CartState
            state = cart
            state.checkout = cart.checkout
            const subtotalArr = Object.values(cart.checkout).flatMap(arr => arr.map(roi => roi.subtotal))
            const totalArr = Object.values(cart.checkout).flatMap(arr => arr.map(roi => roi.amount))
            const discountArr = Object.values(cart.checkout).flatMap(arr => arr.map(roi => roi.discount))
            state.subTotal = sum(subtotalArr)
            state.total = sum(totalArr)
            state.discount = sum(discountArr)
            state.error = null
            state.isLoading = false
        },

        addToCart(state, action) {
            const newROI = action.payload
            const { reservation_name } = newROI
            const checkout: CartState['checkout'] = {
                ...state.checkout,
                [reservation_name]: state.checkout?.[reservation_name]
                    ? [...state.checkout[reservation_name], newROI]
                    : [newROI]
            }
            state.checkout = checkout
            const subtotalArr = Object.values(checkout).flatMap(arr => arr.map(roi => roi.subtotal))
            const totalArr = Object.values(checkout).flatMap(arr => arr.map(roi => roi.amount))
            const discountArr = Object.values(checkout).flatMap(arr => arr.map(roi => roi.discount))
            state.subTotal = sum(subtotalArr)
            state.total = sum(totalArr)
            state.discount = sum(discountArr)
            state.isLoading = false
        },

        deleteCart(state, action) {
            const {
                id: roiId,
                reservation_name } = action.payload

            let roisRelatedToReservationName = state.checkout[reservation_name]
            delete state.checkout[reservation_name]

            if (roisRelatedToReservationName.length > 1) {
                roisRelatedToReservationName = roisRelatedToReservationName.filter((res) => res.id !== roiId)
                state.checkout[reservation_name] = roisRelatedToReservationName
            }

            const subtotalArr = Object.values(state.checkout).flatMap(arr => arr.map(roi => roi.subtotal))
            const totalArr = Object.values(state.checkout).flatMap(arr => arr.map(roi => roi.amount))
            const discountArr = Object.values(state.checkout).flatMap(arr => arr.map(roi => roi.discount))
            state.subTotal = sum(subtotalArr)
            state.total = sum(totalArr)
            state.discount = sum(discountArr)
            state.isLoading = false
        },

        resetCart(state) {
            state.isLoading = false
            state.error = null
            state.checkout = {}
            state.subTotal = 0
            state.discount = 0
            state.total = 0
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setCart: getCart,
    addToCart,
    resetCart,
    deleteCart,
} = slice.actions;

// ----------------------------------------------------------------------
export function getCartFromFirestore(userId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const cartData = await getCartDoc(userId)
            if (cartData) {
                dispatch(slice.actions.setCart(cartData))
            }
            console.log('no cart data')
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

async function getCartDoc(userId: string) {
    const docRef = doc(DB, 'users', userId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        return docSnap.data()
    }
    return null

}

// ----------------------------------------------------------------------