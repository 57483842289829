// routes
import { PATH_APP } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';
import { CheckEnvironment, CheckLocalDev, SupportedEnvironments } from '../../../config-global';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// const Icon = ({ name }: { name: string }) => (
//   <Iconify icon={name} width={24} height={24} />
// )

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export type ConfigType = {
  subheader: string;
  items: {
    title: string;
    path: string;
    icon: JSX.Element;
  }[];
}[]

const createNavConfig = (singleReservationName: string, singleListingId: string) => {
  const outgoingMenuConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: '',
      items: [
        // { title: 'Home', path: PATH_APP.root, icon: ICONS.dashboard },
        // { title: 'Basics', path: PATH_APP.basics, icon: ICONS.booking },
        // { title: 'Villa', path: PATH_APP.villa, icon: <Icon name='fontisto:holiday-village' /> },
        // { title: 'Resort', path: PATH_APP.resort, icon: <Icon name='fluent:building-16-filled' /> },
        {
          title: 'Stays', path: PATH_APP.reservations, icon: ICONS.booking,
          //! Keep
          // children: [
          //   { subheader: 'Properties', title: 'Properties', path: PATH_APP.properties },
          // ]
        },
        {
          title: 'My Stay', path: PATH_APP.createReservation(singleReservationName), icon: ICONS.booking,
          //! Keep
          // children: [
          //   { subheader: 'Property', title: 'Property', path: PATH_APP.properties },
          // ]
        },
        {
          title: 'Properties', path: PATH_APP.listings, icon: ICONS.banking
        },
        {
          title: 'Property', path: PATH_APP.createListing(singleListingId), icon: ICONS.banking
        }
        //TBD: Maybe Hybrid Children will be a sub-page under My Stay?
        // {
        //   title: 'Hybrids', path: PATH_APP.hybrids, icon: ICONS.external,
        // },
        // { title: 'Properties', path: 'no-match', icon: ICONS.banking },
        // { title: 'Resorts', path: 'no-match', icon: ICONS.user },
        // { title: 'FAQ', path: 'no-match', icon: ICONS.external },
        // { title: 'Guests', path: PATH_APP.guests, icon: ICONS.user },
      ]
    }
    // {
    //   subheader: 'other',
    //   items: [
    //     { title: 'Safety', path: PATH_APP.safety, icon: <Icon name='material-symbols:health-and-safety-outline' /> },
    //     { title: 'Contacts', path: PATH_APP.contacts, icon: <Icon name='fluent:book-contacts-20-regular' /> },
    //     { title: 'FAQ', path: PATH_PAGE.faqs, icon: <Icon name='wpf:faq' /> },
    //   ]
    // }
  ];

  if (CheckLocalDev()) {
    outgoingMenuConfig[0].items.push({
      title: 'Local Dev', path: CheckEnvironment(SupportedEnvironments.Dev) ? 'test' : '', icon: ICONS.lock
    });
  }

  return outgoingMenuConfig;
};

export default createNavConfig;
