import { isThisYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { brandConfig } from '../../config';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { FDateFormat, fDate } from '../../utils/formatTime';


export function fReservationCheckInOut(reservation: ReservationGeneral) {
    const {
        check_in_ISO, check_out_ISO
    } = reservation;

    const checkIn = utcToZonedTime(check_in_ISO, brandConfig.timezone);
    const checkOut = utcToZonedTime(check_out_ISO, brandConfig.timezone);

    const joinStr = ' - ';
    const shouldHideYear = isThisYear(checkOut);
    const checkOutDateFormat = shouldHideYear ? FDateFormat.localized_us_short_month_day : FDateFormat.localized_us_short_month_day_year;

    const checkInTimeStr = fDate(checkIn, FDateFormat.localized_us_short_month_day);
    const checkOutTimeStr = fDate(checkOut, checkOutDateFormat);

    return [checkInTimeStr, checkOutTimeStr].join(joinStr);
}
