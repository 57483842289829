
// Stripe
export const cloud_createCustomer = 'createCustomer';
export const cloud_createPaymentIntent = 'createPaymentIntent';
export const cloud_getCustomer = 'getCustomer';
export const cloud_getCustomerByEmail = 'getCustomerByEmail';

// Rental Agreement
export const cloud_getRentalAgreement = 'getRentalAgreement';
export const cloud_postRentalAgreement = 'postRentalAgreement';

// Reservation
export const cloud_getHybridReservations = 'getHybridReservations';
export const cloud_getReservations = 'getReservations';
export const cloud_getSingleReservation = 'getSingleReservation';
export const cloud_patchExtendStay = 'patchExtendStay'
export const cloud_patchCheckinRequest = 'patchCheckinRequest'
export const cloud_patchCheckoutTime = 'patchCheckoutTime'

// Registration
export const cloud_patchRegistrationStep = 'patchRegistrationStep';

// Case
export const cloud_getCases = 'getCases';
export const cloud_postCase = 'postCase';
export const cloud_postCaseComment = 'postCaseComment';
export const cloud_getAttachments = 'getObjectAttachments';

// Flight
export const cloud_patchFlightInformation = 'patchFlightInformation';

// Guest
export const cloud_getReservationGuests = 'getReservationGuests';
export const cloud_postReservationGuest = 'postReservationGuest';
export const cloud_putReservationGuest = 'putReservationGuest';
export const cloud_deleteReservationGuest = 'deleteReservationGuest';

// Listing
export const cloud_getListings = 'getListings';
export const cloud_getSingleListing = 'getSingleListing';

// Portal Info
export const cloud_getResortAndListingGpi = 'getResortAndListingGpi'

// Revenue
export const cloud_getReservationRevenue = 'getReservationRevenues'

// ROI
export const cloud_getReservationOrderItems = 'getReservationOrderItems'
export const cloud_postReservationOrderItems = 'postReservationOrderItems'
export const cloud_getAdditionalReservationOrderItems = 'getAdditionalReservationOrderItems'
export const cloud_postTravelInsuranceConfirm = 'postTravelInsuranceConfirm'
export const cloud_postTravelInsuranceQuote = 'postTravelInsuranceQuote'
export const cloud_postLateCheckout = 'postLateCheckout'

// User
export const cloud_confirmOneTimePassword = 'confirmOneTimePassword';
export const cloud_createUserAsAdmin = 'createUserAsAdmin';
export const cloud_editUserRecord = 'editUserRecord';
export const cloud_updateUserBrand = 'updateUserBrand';
export const cloud_emailOneTimePassword = 'emailOneTimePassword';
export const cloud_updateUserStripeId = 'updateUserStripeId';
export const cloud_userResetPassword = 'userResetPassword';
export const cloud_verifyEmail = 'verifyEmail';
export const cloud_verifyEmailOrPhone = 'verifyEmailOrPhone';

// Admin
export const cloud_deleteUser = 'deleteUser';
export const cloud_getAllUserRecordsAsAdmin = 'getAllUserRecordsAsAdmin';
export const cloud_getPagedUsers = 'getPagedUsers';
export const cloud_getKnownUserRecord = 'getKnownUserRecord';
export const cloud_sendSignInLinkEmail = 'sendSignInLinkEmail';
export const cloud_signInAsOtherUser = 'signInAsOtherUser';
export const cloud_verifyEmailAsAdmin = 'verifyEmailAsAdmin';

// New single SF endpoint
export const cloud_makeSalesforceRequest = 'makeSalesforceRequest';


// This creates an array of string Ids that meet the following rules:
//  - No empty-string Ids.
//  - No duplicate Ids.
//
// Those that don't pass are thrown out. If the output is empty, it throws.
export function assembleUniqueIds(inputIds: string | string[]) {
  let outputIds: string[] = [];
  const idSet = new Set<string>();

  if (Array.isArray(inputIds)) {
    outputIds = outputIds.concat(inputIds);
  }
  else {
    outputIds.push(inputIds);
  }

  // ensure no duplicates and no empties
  for (let i = 0; i < outputIds.length; ++i) {
    if (outputIds[i] === '') {
      console.warn('empty Id in subset; removed');
      outputIds.splice(1, i);
      --i;
      continue;
    }

    if (idSet.has(outputIds[i])) {
      console.warn('duplicate Id in subset; removed');
      outputIds.splice(1, i);
      --i;
      continue;
    }

    //TODO: Invoke an optional, boolean callback here: 'validateId(outputIds[i])'

    idSet.add(outputIds[i]);
  }

  if (inputIds.length <= 0) {
    throw new Error('no valid Ids in input');
  }

  return outputIds;
};
