const ImageContainer = {
    vertical: {
        width: 'auto',
        height: 240,
        flexShrink: 0,
        position: 'relative',
        cursor: 'pointer',
    },
    horizontal: {
        width: 200,
        height: 240,
        flexShrink: 0,
        position: 'relative',
        cursor: 'pointer',
    },
    chip: {
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 9,
        cursor: 'pointer'
    }
}

export const ImageStyles = {
    ImageContainer
}