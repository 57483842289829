/**
 * @param numBeds Number of beds
 * @returns formatted display string
 */
export function fBedrooms(numBeds: number) {
    if (numBeds === 0) {
        return 'Studio';
    }
    if (numBeds === 1) {
        return `${numBeds} Bedroom`;
    }
    return `${numBeds} Bedrooms`;
}
