import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';

/**
 *
 * @returns a flex container that will position
 * its children vertically at and below 'sm' breakpoint
 * and horizontally above 'sm' breakpoint
 */
export function OrientationContainer({
    children,
    justifyContent = 'space-between',
    sx,
    horizontal
}: PropsWithChildren<{
    justifyContent?: 'space-between' | 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-evenly'
    sx?: SxProps
    horizontal?: boolean
}>
) {
    return (
        <Stack
            sx={sx}
            direction={
                horizontal
                    ? 'row'
                    : 'column'
            }
            spacing={horizontal ? 2 : 0}
            justifyContent={justifyContent}>
            {children}
        </Stack>);
}
