import { UaEventOptions } from "react-ga4/types/ga4";
import { GuestRelation, GuestType } from "../models/GuestGeneral";

export enum GAEventTransport {
    beacon = 'beacon',
    xhr = 'xhr',
    image = 'image'
}

export enum GAEventName {
    AddService = 'add_checkin_requests',
    AddGuest = 'add_guest',
    AddCase = 'add_case',
    AddFlightTime = 'add_flight_time',
    OpenPopover = 'open_popover',
    ThemeMode = 'theme_mode',
}


/**
 * @abstract
 * @class GAEventBase - base event for react-ga4
 * @param {GAEventName} name - name of event
 * @param {boolean} conversion - whether the event was a conversion (i.e. a booking, sale, etc...)
 * @param {string} context - categorization term, appended to the name (optional)
 * @param {string} subContext - further category detail (optional)
 * @method getParams
 * @description Record<string, string | number | boolean> - second param of react-ga4.event function.
 */
export abstract class GAEventBase {
    public readonly name: string

    constructor(
        readonly eventName: GAEventName,
        readonly conversion: boolean,
        readonly context: string = '',
        readonly subContext: string = '',
    ) {
        this.name = this.eventName
        if (context) {
            this.name += `_${this.context}`
        }
        if (subContext) {
            this.name += `_${this.subContext}`
        }
    }

    abstract getParams(): Record<string, string | number | boolean>
}


// ------------------------------------------------------
// The code below is from an older (pre GA4) system.

export enum GAAction {
    Click = 'Click',
    Open = 'Open',
    Request = 'Request'
}

/**
 * @deprecated
 * @class GAEvent
 * Will construct an event for google analytics
 * @param {string} category - E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {GAAction} action - Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param {string} label - E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * @param {number} value - E.g. a rating, a score, etc.
 * @param {boolean} nonInteraction -  If an event is not triggered by a user interaction, but instead by our code (e.g. on page load)
 * @param {GAEventTransport} transport -  This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
 * @method increment -  increases this.value by one.
 * @method decrement -  decreases this.value by one.
 * @returns {GAEvent} Google Analytics Event
 */

export class GAEvent implements UaEventOptions {

    constructor(
        public readonly category: string,
        public readonly action: GAAction,
        public readonly label: string = '',
        public value: number = 0,
        public readonly nonInteraction: boolean = false,
        public readonly transport: GAEventTransport = GAEventTransport.xhr
    ) {
        console.warn('Depreciated Option')
    }

    increment() {
        ++this.value;
    }

    decrement() {
        --this.value;
    }

}

export class GAEventPopover extends GAEventBase {
    constructor(
        readonly popover_name: string
    ) {
        super(GAEventName.OpenPopover, false)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
            popover: this.popover_name
        }
    }
}

export class GAEventTheme extends GAEventBase {
    constructor(
        readonly themeMode: 'dark' | 'light'
    ) {
        super(GAEventName.ThemeMode, true)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
            themeMode: this.themeMode
        }
    }
}

export class GAEventGuest extends GAEventBase {
    constructor(
        public readonly type: GuestType,
        public readonly is_minor: boolean,
        public readonly first_name: string,
        public readonly last_name: string,
        public readonly conversion = true,
        public readonly relation?: GuestRelation,
        public readonly reservation_name?: string,
    ) {
        super(GAEventName.AddGuest, true)
    }
    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            type: this.type,
            is_minor: this.is_minor,
            ...(this.relation !== undefined && { relation: this.relation }),
            ...(this.reservation_name !== undefined && { reservation_name: this.reservation_name }),
        }
    }
}

export class GAEventAddCheckinRequest extends GAEventBase {
    constructor(
        public readonly services: string[],
        public readonly notes: string,
        public readonly reservation_name: string,
    ) {
        super(GAEventName.AddService, true)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return this.services.reduce((obj, item) => {
            obj[item] = 1;
            return obj;
        }, { notes: this.notes } as Record<string, number | string>);
    }
}

export class GAEventAddCase extends GAEventBase {
    constructor(
        public readonly type: string,
        public readonly subject: string,
        public readonly description: string,
        public readonly reservation_name: string,
    ) {
        super(GAEventName.AddCase, true)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
            type: this.type,
            subject: this.subject,
            description: this.description,
            reservation_name: this.reservation_name
        }
    }
}
export class GAEventAddFlightInfo extends GAEventBase {
    constructor(
        public readonly reservation_name: string,
        public readonly arrival_time: string,
        public readonly arrival_flight: string,
        public readonly departure_time: string,
        public readonly departure_flight: string,
    ) {
        super(GAEventName.AddFlightTime, true)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
            arrival_time: this.arrival_time,
            arrival_flight: this.arrival_flight,
            departure_time: this.departure_time,
            departure_flight: this.departure_flight,
            reservation_name: this.reservation_name,
        }
    }
}