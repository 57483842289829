import { SnackbarProps } from '@mui/material';
import { createSlice, Dispatch } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

// const initialState: IErrorState = {
// 	errors: [],
// 	priorityError: null
// };

type customError = { id: number, error: Error | string, showSnackbar: boolean, onClick?(): void }
type customMessage = { id: number, message: string, onClick?(): void, variant?: SnackbarProps['color'] }

interface ApiMessageProviderInitialStateI {
	errors: customError[],
	messages: customMessage[]
}

const initialState: ApiMessageProviderInitialStateI = {
	errors: [],
	messages: []
}

// let errorId = 0
// let messageId = 0

const slice = createSlice({
	name: 'apiMessage',
	initialState,
	reducers: {
		addError(state, action) {
			const errorInfo = {
				id: action.payload.id,
				error: action.payload.error,
				showSnackbar: action.payload.showSnackbar
			};

			state.errors = [...state.errors, errorInfo]
		},
		addMessage(state, action) {
			const messageInfo = {
				id: action.payload.id,
				message: action.payload.message,
			};

			state.messages = [...state.messages, messageInfo]
		},
		removeError(state, action) {
			state.errors = state.errors.filter((e) => e === action.payload.id)
		},
		removeMessage(state, action) {
			state.messages = state.messages.filter((e) => e === action.payload.id)
		}
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addError(error: customError) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.addError(error));
	};
}
export function addMessage(message: customMessage) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.addMessage(message));
	};
}

export function removeError(id: number) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.removeError(id));
	};
}
export function removeMessage(id: number) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.removeMessage(id));
	};
}
