import { PaymentMethod } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { CLOUD_FUNCTIONS } from 'src/auth/FirebaseContext';
import { StripeCustomer } from 'src/models/Stripe';
import { sendToSentry, sentryStripeFingerprint } from '../utils/mrr/sentryReporter';
import { EndSessionCallback, useExpiredSessionErrorToNavigate } from '../pages/dashboard/useExpiredSessionErrorToNavigate';

interface UsePaymentMethodsProps {
    customer?: StripeCustomer;
}

const usePaymentMethods = ({ customer }: UsePaymentMethodsProps, skip?: boolean) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null);
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const handleExpiredSession = useExpiredSessionErrorToNavigate();

    useEffect(() => {
        if (!customer || skip) return;
        const fetchPaymentMethods = async () => {
            setLoading(true);
            try {
                // Fetch the payment methods from Stripe
                const methods = await getPaymentMethodsForCustomer(customer.id, handleExpiredSession);
                setPaymentMethods(methods);

                // Set default payment method if one exists
                if (methods.length > 0) {
                    // console.log(customer)
                    // console.log('default method: ', customer.invoice_settings.default_payment_method, methods[0]);
                    const defaultMethod = methods.find(method => method.id === customer.default_source) || methods[0];
                    setSelectedPaymentMethodId(defaultMethod.id);
                    setDefaultPaymentMethodId(defaultMethod.id);
                }
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };
        fetchPaymentMethods();
    }, [customer, skip, handleExpiredSession]);

    const selectPaymentMethod = (paymentMethodId: string) => {
        // console.log('Payment method selected: ', paymentMethodId);
        setSelectedPaymentMethodId(paymentMethodId);
    };

    return {
        defaultPaymentMethodId,
        paymentMethods,
        selectedPaymentMethodId,
        selectPaymentMethod,
        loading,
        error,
    };
};

async function getPaymentMethodsForCustomer(customerId: string, handleExpiredSession: EndSessionCallback): Promise<PaymentMethod[]> {
    const getCustomerPaymentMethods = httpsCallable(CLOUD_FUNCTIONS, 'getCustomerPaymentMethods');
    const doGetCustomerPaymentMethods = async () => {
       return getCustomerPaymentMethods({ customerId })
            .then((result: any) => {
                return result.data;
            })
            .catch((e: any) => {
                handleExpiredSession(e);
                sendToSentry(e, null, sentryStripeFingerprint, 'getCustomerPaymentMethods');
                throw new Error(e.message)
            })
    }
    const customerPaymentMethods = await doGetCustomerPaymentMethods();
    return customerPaymentMethods;
}

export default usePaymentMethods;
