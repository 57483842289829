import { Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { m } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { RHFMultiSelect } from '../../../components/hook-form';
import Iconify from '../../../components/iconify';
import { CheckInRequest } from '../../../models/ReservationGeneral';
import { mapTypeToColor } from '../../../components/containers/CardPickerInput';

export enum CheckInRequestInputName {
    CHECK_IN_REQUESTS = 'check_in_requests',
    CHECK_IN_REQUESTS_DETAIL = 'check_in_requests_detail',
    ACTIVE_UNSELECTED_OPTIONS = 'activeUnselectedOptions',
    ACTIVE_SELECTED_OPTIONS = 'activeSelectedOptions',
}

export interface CheckInRequestFormState {
    [CheckInRequestInputName.CHECK_IN_REQUESTS]: CheckInRequest[],
    [CheckInRequestInputName.CHECK_IN_REQUESTS_DETAIL]: string,
    [CheckInRequestInputName.ACTIVE_UNSELECTED_OPTIONS]: CheckInRequest[],
    [CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS]: CheckInRequest[],
}


interface InputProps {
    label: string,
    name: string,
    onChange: (name: string, selection: string) => void
}

export default function CheckinRequestSelect({
    options,
    label: inputLabel,
    name,
    mapIcon,
    onChange
}: {
    options: { label: string, value: string }[],
    mapIcon?: (id: string) => string
} & InputProps) {
    const { watch, setFocus } = useFormContext()

    // Logger for any input changes
    // React.useEffect(() => {
    //     const subscription = watch((vals) => {
    //         const value = vals[name]
    //         console.log('input:', name, value)
    //     })
    //     return subscription.unsubscribe
    // }, [name, watch])

    const { [name]: inputValues } = watch()

    return (
        <Stack>
            <Typography
                variant="subtitle1">
                {inputLabel}
            </Typography>
            <FormControl
                fullWidth>
                <RHFMultiSelect
                    open={false}
                    displayEmpty
                    fullWidth
                    name={name} // required
                    value={inputValues}
                    multiple
                    chip
                    onFocus={() => setFocus(name)}
                    options={options}
                    checkbox
                    IconComponent={() => null}
                    renderValue={(selection) => (
                        <ChipsInContainer
                            selection={selection}
                            name={name} // used for key. MultiSelect passes the name to RHF.
                            onChange={onChange} // this on change will be attached to the chips
                            mapIcon={mapIcon}
                        />
                    )}
                />
            </FormControl>
        </Stack >
    );
}

function ChipsInContainer({
    selection,
    onChange,
    name,
    mapIcon
}: {
    selection: unknown,
    onChange: (name: string, selection: string) => void,
    name: string,
    mapIcon?: (id: string) => string
}) {
    const theme = useTheme()
    const isRequested = name === CheckInRequestInputName.ACTIVE_SELECTED_OPTIONS

    return (
        <Box
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {Array.isArray(selection) && selection.sort().map((value) => (
                <Chip
                    sx={{ bgcolor: mapTypeToColor(value) }}
                    deleteIcon={
                        <m.div
                            whileHover={{
                                scale: 1.1,
                                color: theme.palette.text.primary
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Iconify
                                icon='teenyicons:x-small-solid' />
                        </m.div>
                    }
                    onClick={isRequested ? undefined : () => {
                        onChange(name, value)
                    }}
                    onDelete={isRequested ? () => {
                        onChange(name, value)
                    } : undefined}
                    icon={mapIcon &&
                        <Iconify
                            icon={mapIcon(value)} />
                    }
                    key={`${name}_${value}_chip__input`}
                    label={value} />
            ))}
        </Box>
    )
}