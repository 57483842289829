import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button, FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Stack,
    TextField,
    Typography
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
    addDays,
    addHours,
    isAfter,
    isBefore,
    isFuture,
    isPast,
    isToday,
    isValid,
    parseISO,
    setHours,
    setMilliseconds,
    setMinutes,
    setSeconds
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import { StandardStack } from "../../../../components/containers/StandardStack";
import { brandConfig } from "../../../../config";
import { StandardError } from "../../../error/StandardError";
import {
    InstantiateROIFromJSON,
    ROIGeneral
} from "../../../../models/ROIGeneral";
import { ReservationGeneral } from "../../../../models/ReservationGeneral";

export type SelectableTimeBounds = {
    earliestSelectable: Date | null,
    latestSelectable: Date | null,
    errorText: String | null
}

export const SCROLL_AREA_MIN_HEIGHT = 466;
export const START_HOUR = 9;
export const END_HOUR = 14;
export const START_HOUR_TEXT = START_HOUR > 12 ? (START_HOUR % 12 + 'pm') : (START_HOUR + 'am');
export const END_HOUR_TEXT = END_HOUR > 12 ? (END_HOUR % 12 + 'pm') : (END_HOUR + 'am');

type SelectDateStepProps = {
    selectService: (service: ROIGeneral) => void;
    reservation: ReservationGeneral;
    roi: ROIGeneral;
    isLoading: boolean;
    handleDoneChoosingDate: () => void;
    handleNavBack?: () => void;
};
// -----------------------------
export const SelectDateStep: React.FC<SelectDateStepProps> = ({
    reservation,
    roi,
    selectService,
    isLoading,
    handleDoneChoosingDate,
    handleNavBack
}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [startHourOptions, setStartHourOptions] = useState(makeOptions(roi))
    const [timeSelectValue, setTimeSelectValue] = useState(startHourOptions[0]?.value || START_HOUR)

    const handleUpdateROI = useCallback((date: Date | null) => {
        if (date) {
            const clone = InstantiateROIFromJSON(roi);
            clone.setStartTime(date)
            selectService(clone);
            setStartHourOptions(makeOptions(clone))
        }
    }, [roi, selectService]);
    useEffect(() => {
        if (!roi.id || !roi.start_time_ISO) {
            return;
        }
        // We will only do this the first time for roi that already exist.
        // The reason we create a zoned time here is to set the initial drop down option.
        // These options are not dates but rather avalible hour slots that can be scheduled.
        const time = utcToZonedTime(roi.start_time_ISO, brandConfig.timezone)
        handleUpdateROI(utcToZonedTime(roi.start_time_ISO, brandConfig.timezone))
        setTimeSelectValue(time.getHours())
        console.log('end 0')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (roi.start_time_ISO) {
            return;
        }
        // Will be sure that roi has start_time for payload
        if (isValid(new Date(roi.service_window_start)) && isValid(new Date(roi.service_window_end))) {
            const timeBoundsInfo = establishSelectableTimeBounds(parseISO(roi.service_window_start), parseISO(roi.service_window_end), roi);
            if (timeBoundsInfo.errorText !== null) {
                handleUpdateROI(parseISO(roi.service_window_start));
                setErrorMessage(timeBoundsInfo.errorText as string);
                return;
            }
            handleUpdateROI(timeBoundsInfo.earliestSelectable);
        }
    }, [handleUpdateROI, roi, selectService, startHourOptions]);

    if (!roi) {
        if (handleNavBack) {
            handleNavBack();
        }
        return (
            <StandardStack>
                <StandardError error={new Error('Missing order item.')} />
            </StandardStack>
        );
    }

    const startTimeReady = roi && roi.start_time_ISO;
    const timePickerId = 'service-time-input';
    const timePickerLabel = 'Preferred Time';

    // find first selectable date
    const timeBoundsInfo = establishSelectableTimeBounds(reservation.check_in_ISO, reservation.check_out_ISO, roi);

    const windowStart = new Date(roi.service_window_start);
    const windowEnd = new Date(roi.service_window_end);
    const minDateTime = setHours(windowStart, START_HOUR - 1);
    const maxDateTime = setHours(windowEnd, END_HOUR + 1);

    return (
        <Stack spacing={2} flexGrow={1}>
            {!errorMessage && <Alert
                severity="warning"
                variant="outlined">
                {'Please select a preferred start time between the hours of ' + START_HOUR_TEXT + ' and ' + END_HOUR_TEXT + '.'}
            </Alert>
            }
            {errorMessage && <Alert
                severity="error"
                variant="outlined">
                {errorMessage}
            </Alert>}
            {timeBoundsInfo.errorText !== null
                ? <Typography
                    variant="body1">
                    {timeBoundsInfo.errorText}
                </Typography>
                : <Stack flexGrow={1} spacing={2}>
                    <DateTimePicker
                        label='Preferred Day'
                        reduceAnimations
                        loading={isLoading || !startTimeReady}
                        disableMaskedInput
                        inputFormat='EEE, MMMM do'
                        disableHighlightToday={isAfter(new Date(), reservation.check_out_ISO)
                            || isBefore(new Date(), reservation.check_in_ISO)}
                        hideTabs
                        disablePast
                        views={['day']}
                        // onAccept={scrollToTotal}
                        showToolbar={false}
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        shouldDisableTime={(tDate, view) => {
                            if (view === 'hours') {
                                return tDate > END_HOUR || tDate < START_HOUR;
                            }
                            return false;
                        }}
                        minutesStep={60}
                        closeOnSelect
                        value={roi.start_time_ISO || timeBoundsInfo.earliestSelectable}
                        onChange={(date) => {
                            handleUpdateROI(date);
                        }}
                        onError={(reason, value) => {
                            switch (reason) {
                                case 'shouldDisableTime-hours':
                                case 'minTime':
                                    setErrorMessage('Please select a time between ' + START_HOUR_TEXT + ' to ' + END_HOUR_TEXT + '.');
                                    break;
                                case 'invalidDate':
                                    setErrorMessage('Please select a valid preferred start date.');
                                    break;
                                case null:
                                    setErrorMessage(null);
                                    break;
                                default:
                                    console.log('unexpected date', value, reason);
                                    break;
                            }
                        }}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    fullWidth />
                            );
                        }} />
                    {startHourOptions.length > 0 &&
                        <FormControl>
                            <InputLabel id={timePickerId}>{timePickerLabel}</InputLabel>
                            <Select
                                // variant="outlined"
                                input={<OutlinedInput fullWidth label={timePickerLabel} />}
                                // label='Preferred Start Time'
                                labelId={timePickerId}
                                fullWidth
                                value={timeSelectValue}
                                onChange={(item) => {
                                    const val = Number(item.target.value);
                                    if (roi.start_time_ISO && !Number.isNaN(val)) {
                                        handleUpdateROI(setHours(roi.start_time_ISO, val));
                                    }
                                    setTimeSelectValue(val)
                                }}>
                                {startHourOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                    {!startHourOptions && startTimeReady &&
                        <Typography
                            color='error'
                            variant="caption">
                            Please choose another day.
                        </Typography>}
                </Stack>}
            <Stack
                direction='row'
                justifyContent={handleNavBack ? 'space-between' : 'flex-end'}
                width='100%'>
                {handleNavBack && <Button
                    variant="text"
                    onClick={handleNavBack}>
                    Back
                </Button>}
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={handleDoneChoosingDate}>
                    Next
                </LoadingButton>
            </Stack>
        </Stack>
    );
}

function establishSelectableTimeBounds(check_in_ISO: Date, check_out_ISO: Date, roi: ROIGeneral): SelectableTimeBounds {
    if (!roi) {
        throw new Error('roi not set on establish time bounds');
    }

    const now = new Date();
    let midday = false;
    let earliestSelectable: Date | null = setHours(check_in_ISO, START_HOUR);
    let latestSelectable: Date | null = setHours(check_out_ISO, END_HOUR);
    let errorText: String | null = null;

    //NOTE: This assumes all reservations are longer than 1 day.
    if (isFuture(check_in_ISO)) {
        if (isToday(check_in_ISO)) {
            // TODO: Should we allow services on check in day considering early check in?
            // It's check in day
            if (now.getHours() > END_HOUR - 1) {
                // Too late to schedule today; use tomorrow
                earliestSelectable = setHours(addDays(check_in_ISO, 1), START_HOUR);
            } else {
                // There are available hours today
                midday = true;
            }
        } else {
            // check in is in the future; all hours available
        }
    } else if (isPast(check_out_ISO)) {
        // check out has passed
        // setErrorMessage('Check out has passed.')
        console.warn("invalid checkout state for add service.");

        earliestSelectable = null;
        latestSelectable = null;

        errorText = 'Check out has passed.';
    }

    // We are in the middle of the stay
    else if (isToday(check_out_ISO)) {
        // Today is checkout day. There is no tomorrow.
        if (now.getHours() > END_HOUR - 1) {
            // Impossible to schedule today.
            // setErrorMessage('Check out has ended.')
            console.warn("It is too late for this service.");

            earliestSelectable = null;
            latestSelectable = null;

            errorText = 'Check out has ended.';
        } else {
            // There are available hours today
            midday = true;
        }
    } else if (now.getHours() > END_HOUR - 1) {
        // Too late to schedule today. Start with tomorrow morning.
        earliestSelectable = setHours(addDays(now, 1), START_HOUR);
    } else {
        // There are available hours today
        midday = true;
    }

    if (errorText !== null) {
        return { earliestSelectable, latestSelectable, errorText };
    }

    if (earliestSelectable === null) {
        throw new Error('Failed to set base time');
    }

    // choose the first-available hour
    const baseTime = midday ? now : earliestSelectable;

    let clampedTime = baseTime;

    if (clampedTime.getHours() < START_HOUR) {
        clampedTime = setHours(clampedTime, START_HOUR);
    }
    else if (midday) {
        clampedTime = addHours(clampedTime, 1);
    }

    // remove minutes (we only support fixed intervals)
    earliestSelectable = setMinutes(setSeconds(setMilliseconds(clampedTime, 0), 0), 0);

    // VERBOSE console.log('ESTABLISHED TIME BOUNDS\n', earliestSelectable, '\n', latestSelectable);
    return { earliestSelectable, latestSelectable, errorText };
}
const makeOption = (val: number) => {
    const isPM = val > 11;
    const label = isPM ? `${val % 12 || 12} PM` : `${val} AM`;
    return { value: val, label };
};
const makeOptions = (roi: ROIGeneral) => {
    const options: {
        value: number;
        label: string;
    }[] = [];

    if (!roi || !(roi.start_time_ISO instanceof Date)) {
        return options;
    }
    if (isAfter(roi.start_time_ISO, setHours(new Date(), END_HOUR))) {
        // The desired time is tomorrow at the earliest
        for (let i = START_HOUR; i <= END_HOUR; i++) {
            options.push(makeOption(i));
        }
        return options;
    }
    // This selection is today
    const now = new Date();
    if (now.getHours() > END_HOUR) {
        return options; // []
    }

    // There are hours available today
    const firstHour = Math.max(START_HOUR, now.getHours() + 1);

    for (let i = firstHour; i <= END_HOUR; i++) {
        options.push(makeOption(i));
    }

    return options;
}