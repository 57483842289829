import brandConfig from "./config/brandConfig";
import { PATH_ADMIN, PATH_APP } from "./routes/paths";
import { Roles, routeRoleAsAdmin, allowRoleToSeeDevTab } from "./utils/mrr/userConstants";

// Environments
// ----------------------------------------------------------------------
const ENVIRONMENT = process.env.REACT_APP_ENV;
const LOCAL_DEV = process.env.REACT_APP_LOCAL_DEV;

export enum SupportedEnvironments {
  Dev = 'development',
  Prod = 'production'
}

export function CheckEnvironment(supportedEnv: SupportedEnvironments) {
  return ENVIRONMENT === supportedEnv;
}

export function CheckLocalDev() {
  // NOTE: The .env file will produce a string if you give it a boolean.
  return LOCAL_DEV === 'true' && ENVIRONMENT === SupportedEnvironments.Dev;
}

// API
// ----------------------------------------------------------------------
export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;


export const STRIPE_PUBLISHABLE_KEY = brandConfig.stripePublishableKey;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const GCF_BASE_URL = process.env.REACT_APP_GCF_BASE_URL

// default landing after login successful
export const WritePostLoginPath = (role: Roles) => {
  return (routeRoleAsAdmin(role) || allowRoleToSeeDevTab(role)) ? PATH_ADMIN.user.list : PATH_APP.app;
}

// LAYOUT
// ----------------------------------------------------------------------
export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
