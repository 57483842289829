import {
    Card,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import StaticMap from "../../assets/maps/StaticMap";
import { CenteredRow } from "../../components/containers/CenteredRow";
import { StyledCardContent } from "../../components/containers/StyledCardContent";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "../../components/iconify";
import { ListingCardImage } from "../../components/listing/ListingImage";
import { useSettingsContext } from "../../components/settings";
import { ListingGeneral } from "../../models/ListingGeneral";
import { useGetReservationsQuery, useGetSingleListingQuery } from "../../redux/rtkQuery/apiSlice";
import { PATH_APP, PATH_ROOT } from "../../routes/paths";
import { GPISSection } from "../../sections/gpis/GPISSection";
import { StandardRTKLoader } from "../../sections/loader/StandardRTKLoader";
import { ReservationCardSection } from "../../sections/reservation/Details/ReservationCardSection";
import { cloud_getReservations, cloud_getSingleListing } from "../../utils/mrr/cloudFunctions";
import { fBathrooms } from "../../utils/mrr/fBathrooms";
import { fBedrooms } from "../../utils/mrr/fBedrooms";
import fTitle from "../../utils/mrr/formatTitle";
import { StaticMapCard } from "../../assets/maps/StaticMapCardProps";

export default function ListingPage() {
    const { themeStretch } = useSettingsContext()

    // the listing Id is baked into this page's route
    const { listingId } = useParams()

    const {
        data: listingData,
        isError: listingError,
        isSuccess: listingSuccess,
        isFetching: listingFetching,
        error: listingErrorObject
    } = useGetSingleListingQuery({ listingId: listingId || ''  })

    const {
        data: reservationsData,
        isSuccess: reservationsSuccess,
        isError: reservationsError,
        isFetching: reservationsFetching,
        error: reservationsErrorObject
    } = useGetReservationsQuery({}, { skip: !listingSuccess })

    if (!listingSuccess) {
        return (
            <StandardRTKLoader
                endpoint={cloud_getSingleListing}
                mutationCall={false}
                isError={listingError}
                isFetching={listingFetching}
                loadedObjectName="property"
                error={listingErrorObject}
            />);
    }
    if (!reservationsSuccess) {
        return (
            <StandardRTKLoader
                endpoint={cloud_getReservations}
                mutationCall={false}
                isError={reservationsError}
                isFetching={reservationsFetching}
                loadedObjectName="listing reservations"
                error={reservationsErrorObject}
            />);
    }

    const filteredReservations = reservationsData.filter((reservation) => reservation.listing_id === listingId)

    return (
        <>
            <Helmet>
                <title>{fTitle(listingData.name_detailed)}</title>
            </Helmet>
            <Container
                maxWidth={
                    themeStretch
                        ? 'md'
                        : 'lg'
                }
            >
                <CustomBreadcrumbs
                    heading={listingData.name}
                    links={[
                        { name: 'Home', href: PATH_ROOT },
                        { name: 'Properties', href: PATH_APP.listings },
                    ]}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ListingCardImage listing={listingData} />
                    </Grid>
                    <Grid item xs={12}>
                        <ListingInfoSection listing={listingData} />
                    </Grid>
                    <Grid item xs={12}>
                        <StaticMapCard
                            raised
                            scale={0.7}
                            zoom={17}
                            listingName={listingData.name_detailed}
                            height={300}
                            lat={listingData.latitude}
                            lon={listingData.longitude} />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <StyledCardContent>
                                <Typography variant="h4">Your Stays</Typography>
                                <ReservationCardSection reservations={filteredReservations} isProperty />
                            </StyledCardContent>
                        </Card>
                    </Grid>
                    {listingSuccess &&
                        <Grid
                            item
                            xs={12}>
                            <GPISSection listingId={listingId} />
                        </Grid>
                    }
                </Grid>
            </Container>
        </>
    )
}



// ------------------------------------------------------------------

function ListingInfoSection({ listing }: { listing: ListingGeneral }) {
    const {
        bathrooms,
        bedrooms,
        name_detailed,
        name,
        unit_view,
        // resort_name,
        // type,
        // latitude,
        // longitude
    } = listing;
    return (
        <Card raised>
            <StyledCardContent id={`${name}_details`}>
                <Typography variant="h4">
                    {name_detailed}
                </Typography>

                <Stack
                    typography='body1'
                    color='text.primary'
                    direction="row"
                    flexWrap="wrap"
                    columnGap={1.5}
                    rowGap={0.5}
                >
                    <CenteredRow>
                        <Iconify icon="mdi:bedroom-outline" sx={{ mr: 0.5 }} />
                        {fBedrooms(bedrooms)}
                    </CenteredRow>

                    <CenteredRow>
                        <Iconify icon="mdi:bathroom" sx={{ mr: 0.5 }} />
                        {fBathrooms(bathrooms)}
                    </CenteredRow>

                    {/* <CenteredRow>
                        <Iconify icon={mapIconToListingType(type)} sx={{ mr: 0.5 }} />
                        {type}
                    </CenteredRow> */}

                    <CenteredRow>
                        <Iconify icon="uil:scenery" sx={{ mr: 0.5 }} />
                        {unit_view}
                    </CenteredRow>

                </Stack>
            </StyledCardContent>
            {/* Resort Section is not going to be in initial release */}
            {/*<Divider />
            <StyledCardContent>
                <Typography variant="h6">
                    Resort
                </Typography>
                <Link variant="body1" component={NavLink} to={PATH_PAGE.comingSoon}>
                    {resort_name}
                </Link>
            </StyledCardContent> */}
            {/* {typeof latitude === 'number' && typeof longitude === 'number' &&
                <CardContentButton
                    component='a'
                    href={createGeoLink(latitude, longitude)}
                    rel="noopener noreferrer"
                    target="_blank"
                    icon="mdi:location-radius-outline">View in Maps</CardContentButton>} */}
        </Card>
    )
}
