import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import Image from "../../components/image";
import { ImageStyles } from "../../components/image/style";
import { ListingGeneral } from "../../models/ListingGeneral";
import { PATH_APP } from "../../routes/paths";

type ListingListCardImageProps = {
    listing: ListingGeneral;
    horizontal?: boolean;
};

export function ListingListCardImage({ listing, horizontal }: ListingListCardImageProps) {
    const navigate = useNavigate();
    const { id, name, listing_photo_url } = listing;
    const navigateToListing = () => {
        navigate(PATH_APP.createListing(id));
    };

    return (
        <Box
            onClick={navigateToListing}
            sx={horizontal
                ? ImageStyles.ImageContainer.horizontal
                : ImageStyles.ImageContainer.vertical}>
            <Image
                alt={`${name}_list_image`}
                src={listing_photo_url}
                sx={{
                    height: 1,
                    borderRadius: 1.5,
                }} />
        </Box>
    );

}
