import { forwardRef, useRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Link, Stack, Tooltip } from '@mui/material';
import createGeoLink from '../../utils/mrr/url/createGeoLink';

// ----------------------------------------------------------------------

export interface StaticMapProps extends BoxProps {
    height?: number,
    width?: number,
    lat: number,
    lon: number,
    scale: number,
    zoom: number,
    listingName: string
}

/**
 * @param disabledLink will disable navigation when clicked. Helpfull on OOB action pages.
 * @param full will determain which Logo will be shown.
 * @param height vertical size
 * @param width horizontal size
 */
const StaticMap = forwardRef<HTMLDivElement, StaticMapProps>(
    ({
        height,
        width,
        lat,
        lon,
        listingName,
        scale,
        zoom,
        sx,
        ...other }, ref) => {
        return (
            <Tooltip title="View in Maps" arrow>
                <Link
                    component='a'
                    target='_blank'
                    rel="noopener noreferrer"
                    href={createGeoLink(lat, lon)}>
                    <Box
                        ref={ref}
                        component="img"
                        src={createMapLink(lat, lon, scale, zoom, listingName)}
                        sx={{
                            width: width || '100%',
                            height: height || '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            overflow: 'hidden',
                            display: 'inline-flex',
                            ...sx
                        }}
                        {...other}
                    />
                </Link>
            </Tooltip>
        )
    }

);

export default StaticMap;

// ----------------------------------------------------------------------
function createMapLink(lat: number, lon: number, scale: number, zoom: number, listingName: string) {
    const base = new URL('https://maps.googleapis.com/maps/api/staticmap');
    base.searchParams.append('center', `${lat},${lon}`);
    base.searchParams.append('zoom', `${zoom}`);
    base.searchParams.append('scale', `${scale}`);
    // base.searchParams.append('format', 'png');
    base.searchParams.append('size', '500x500');
    base.searchParams.append('maptype', 'roadmap');
    base.searchParams.append('markers', `color:blue|label:${listingName}|${lat},${lon}`);
    base.searchParams.append('key', process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '');
    return base.toString();
}
