// @mui
import { Typography, Stack, StackProps } from '@mui/material';
//
import Image from '../image';

// ----------------------------------------------------------------------

interface EmptyContentProps extends StackProps {
  title?: string;
  img?: string;
  imgHeight?: number;
  description?: string;
  hideImg?: boolean;
}

export default function EmptyContent({ title, description, img, sx, imgHeight, hideImg, ...other }: EmptyContentProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      {!hideImg && <Image
        disabledEffect
        alt="empty content"
        src={img || '/assets/illustrations/illustration_empty_content.svg'}
        sx={{ height: imgHeight || 240, mb: 3 }}
      />}

      {title && <Typography variant="h5" gutterBottom>
        {title}
      </Typography>}

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary', maxWidth: 600 }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
