import { format, getTime, formatDistanceToNow } from 'date-fns';
// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

// --------------

export enum FDateFormat {
  localized_us_long_month_day_year = 'PPP',           // April 29th, 1453
  localized_us_numeric_month_day_year = 'M/dd/yyyy',  // 5/22/2023
  localized_us_short_month_day_year = 'MMM d, yyyy',
  localized_us_short_month_day = 'MMM d',
  localized_month_day = 'M/d',                        // 6/19 10/19
  localized_chat_timestamp = 'MMM d h:mma',
  localized_month_year = 'MMM yyyy',
  localized_transfer_format = 'MMM do, yyyy',
  localized_date_time_full = 'M/dd/yyyy, h:mm a',
  localized_month_label = 'LLLL'
};

export type FDateType = keyof typeof FDateFormat;

export const scopedRequestDateFormat = 'yyyy-MM-dd';

export enum WidgetDateModes {
  CurrentMonth = 'CurrentMonth',					     // July 1 - July 31
  MonthToDate = 'MonthToDate',					       // July 1 - <today>
  YearToDate = 'YearToDate',						       // January 1 - <today>
  YearToDateInclusive = 'YearToDateInclusive', // January 1 - <end of current month>
  PastToCurrentMonth = 'PastToCurrentMonth'    // <start of last month> - <end of current month>
};

export const yearChartMonthLabels: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const ONE_DAY_MS = 24 * 60 * 60 * 1000;