import { Container } from "@mui/system";
import { Card } from "@mui/material";
import StripePayment from "../../components/stripe/StripePayment";
import { StyledCardContent } from "../../components/containers/StyledCardContent";

export function StripePaymentPage() {
    console.warn('StripePaymentPage is temporary for development, should be removed');

    return (
        <Container maxWidth="sm">
            <Card raised>
                <StyledCardContent>
                    <StripePayment amount={4321.99} category="Test Page" />
                </StyledCardContent>
            </Card>
        </Container>
    )
}