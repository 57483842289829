import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import usePublicIpAddress from "../../hooks/use-local-ip-address";

type SContext = {
    htmlDataStr: string | null,
    setHtmlDataStr(a: string | null): void,
    ipAddress: string | null,
    date: Date | null,
};
const SignatureContext = createContext<SContext>({ htmlDataStr: null, setHtmlDataStr: () => null, ipAddress: null, date: null });

const SignatureProvider = ({ children }: PropsWithChildren) => {
    const [htmlDataStr, setHtmlDataString] = useState<string | null>(null);
    const [date, setDate] = useState<Date | null>(null);
    const { ipAddress } = usePublicIpAddress();
    const handleSetNavContext = (a: string | null) => {
        setHtmlDataString(a);
        setDate(new Date());
    };
    const value = useMemo<SContext>(() => ({
        htmlDataStr: htmlDataStr,
        setHtmlDataStr: handleSetNavContext,
        ipAddress,
        date
    }), [htmlDataStr, ipAddress, date]);

    return (
        <SignatureContext.Provider value={value}>
            {children}
        </SignatureContext.Provider>
    );
};

export default SignatureProvider;

export const useSignatureNavigationContext = () => {
    const context = useContext(SignatureContext);
    if (!context) {
        throw new Error('Hook must be called within SignatureNavigationProvider.');
    }
    return context;
};