import { Masonry } from "@mui/lab";
import {
    Dialog,
    Link,
    LinkProps,
    Stack,
    Typography,
    TypographyProps
} from "@mui/material";
import { useState } from "react";
import { GPISDetailSection } from "src/models/GPISDetailSection";
import useResponsive from "../../hooks/useResponsive";
import { StyledCardContent } from "../containers/StyledCardContent";
import { CloseIconButton } from "../dialog/CloseIconButton";
import { GPISSectionBody } from "./GPISSectionBody";

// ----------------------------------------------------------------------
export interface GPISLinksProps {
    title: string;
    titleProps?: TypographyProps;
    linkProps?: LinkProps;
    items: GPISDetailSection[];
    showCategory?: boolean;
}
export function GPISLinks({ title, titleProps, items, linkProps, showCategory }: GPISLinksProps) {
    const downMd = useResponsive('down', 'md')
    const downSm = useResponsive('down', 'sm')
    const groupedByCategory = items.reduce((acc, item) => {
        if (item.category === undefined) {
            return acc
        }
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {} as Record<string, GPISDetailSection[]>)
    return (
        <Stack>
            <Typography variant="h6" {...titleProps}>{title}</Typography>
            <Masonry columns={downSm ? 1 : downMd ? 2 : undefined}>
                {Object.keys(groupedByCategory).map((category, index) => {
                    const categoryItems = groupedByCategory[category];
                    return (<GPISLink showCategory={showCategory} key={`${index}_${category}`} categoryItems={categoryItems} category={category} linkProps={linkProps} />)
                })}
            </Masonry>
        </Stack>
    );
}

function GPISLink({ category, categoryItems, linkProps, showCategory }: { category: string; categoryItems: GPISDetailSection[]; linkProps?: LinkProps; showCategory?: boolean; }) {
    const [selected, setSelected] = useState<GPISDetailSection | null>(null);
    const openInfoModal = (item: GPISDetailSection) => {
        setSelected(item);
    }
    const closeInfoModal = () => {
        setSelected(null);
    }
    return (
        <>
            <Stack spacing={0.5}>
                {showCategory &&
                    <Typography variant="subtitle1" id={category}>
                        {category}
                    </Typography>}
                {categoryItems.map((item, index) => (
                    <Link
                        whiteSpace='nowrap'
                        sx={{ cursor: 'pointer' }}
                        key={`${index}_${item.title}_modal_link`}
                        onClick={() => openInfoModal(item)}
                        {...linkProps}
                        variant="body2">
                        {item.title}
                    </Link>
                ))}
            </Stack>
            {selected &&
                <Dialog
                    open={!!selected}
                    onClose={closeInfoModal}>
                    <CloseIconButton onClose={closeInfoModal} />
                    <StyledCardContent>
                        <Typography variant="h6">{selected.title}</Typography>
                        <Typography component='div' variant='body1'>
                            <GPISSectionBody body={selected.body} />
                        </Typography>
                    </StyledCardContent>
                </Dialog>
            }
        </>
    );
}