import { PaymentIntent } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { CLOUD_FUNCTIONS } from '../../../auth/FirebaseContext';
import { StripePaymentIntentParams } from '../../../models/Stripe';
import { cloud_createPaymentIntent } from '../../../utils/mrr/cloudFunctions';
import { sendToSentry, sentryStripeFingerprint } from '../../../utils/mrr/sentryReporter';

export async function createPaymentIntent(createPaymentIntentParams: StripePaymentIntentParams): Promise<PaymentIntent | null> {
    const createPaymentIntentCallable = httpsCallable(CLOUD_FUNCTIONS, cloud_createPaymentIntent);
    const doCreatePaymentIntent = async () => {
        return createPaymentIntentCallable(createPaymentIntentParams)
            .then((result: any) => {
                return result.data;
            })
            .catch((e) => {
                sendToSentry(e, null, sentryStripeFingerprint, cloud_createPaymentIntent);
                throw new Error(`Error: ${e.message}`);
            });
    }
    const paymentIntentResult = await doCreatePaymentIntent();
    return paymentIntentResult
}