import Box from '@mui/material/Box';
import { useState } from 'react';
import { ListingGeneral } from '../../models/ListingGeneral';
import Image from '../image';

export function ListingCardImage({ listing }: { listing: ListingGeneral; }) {
    const { name_detailed, listing_photo_url, } = listing;
    const [imageSrc, setImageSrc] = useState(listing_photo_url);
    const [hasError, setHasError] = useState(false);

    const handleImageError = () => {
        // Set a different image source if the first one fails
        setImageSrc(`${process.env.PUBLIC_URL}/assets/background/bg_img1_33pct.jpg`);
        setHasError(true);
    };
    return (
        <Box
            position='relative'
            sx={{ boxShadow: 1 }}>
            <Image
                alt={hasError ? 'stock_replacement_photo' : `${name_detailed}_photo`}
                src={imageSrc}
                ratio='21/9'
                onError={handleImageError} />
            {/* <Stack
                direction='row'
                sx={{ position: 'absolute', top: 10, right: 8 }}
                spacing={1}>
                <Chip
                    sx={{ width: 'fit-content' }}
                    color={mapStageToColor(stage)}
                    label={stage} />
                <Chip
                    sx={{ width: 'fit-content' }}
                    color='primary'
                    label={resort_name} />
            </Stack> */}
        </Box>
    );
}
