import { LoadingButton } from "@mui/lab";
import {
    Button,
    Card,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { useCallback, useState } from "react";
import ConfirmDialog from "../../components/confirm-dialog";
import { StyledCardContent } from "../../components/containers/StyledCardContent";
import EmptyContent from "../../components/empty-content/EmptyContent";
import Iconify from "../../components/iconify";
import { GuestGeneral } from "../../models/GuestGeneral";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { useDeleteReservationGuestMutation } from "../../redux/rtkQuery/apiSlice";
import { checkAgeValid } from "../../utils/mrr/ageUtils";
import { cloud_getReservationGuests } from "../../utils/mrr/cloudFunctions";
import { StandardRTKQError } from "../error/StandardRTKQError";


interface RegistrationGuestsSectionProps {
    mainGuest: GuestGeneral,
    reservation: ReservationGeneral,
    ageRequired: boolean,
    guestModels: GuestGeneral[] | null,
    parentLoading: boolean,
    callbackCancel: VoidFunction,
    callbackAddGuest: VoidFunction,
    callbackEditGuest: (guest: GuestGeneral) => void,
    callbackGuestDeleted: (guest: GuestGeneral) => void,
    callbackContinue: VoidFunction;
}
export function RegistrationGuestsSection({
    mainGuest,
    reservation,
    ageRequired,
    guestModels = [],
    parentLoading,
    callbackCancel,
    callbackAddGuest,
    callbackEditGuest,
    callbackGuestDeleted,
    callbackContinue
}: RegistrationGuestsSectionProps) {
    const { name } = reservation;

    const isFetchingGuest = false;
    const isSuccessGuest = true;
    const dataGuest = Array.isArray(guestModels) ? guestModels : new Array<GuestGeneral>();
    const isErrorGuest = false;
    const errorGuest = null;

    const [
        sendDeleteReservationGuestMutation,
        {
            isLoading: isLoadingDelete,
            isError: isErrorDelete,
            error: errorDelete
        }
    ] = useDeleteReservationGuestMutation();

    const handleDeleteGuest = useCallback(async (guest: GuestGeneral) => {
        await sendDeleteReservationGuestMutation({
            reservationName: reservation.name,
            guestId: guest.id
        }).then((response: any) => {
            if (response.error) {
                // eslint-disable-next-line no-useless-return
                return;
            }
        }).catch((deleteError: any) => {
            console.error('error on delete guest', deleteError);
        });
    }, [reservation.name, sendDeleteReservationGuestMutation]);

    const noGuests = isSuccessGuest && dataGuest.length === 0;
    let sortedGuestData: GuestGeneral[] = [];
    let maxGuests = 0;
    let totalGuests = 0;
    let allAgesSet = true;
    if (isSuccessGuest) {
        const cloned: GuestGeneral[] = [...dataGuest];
        sortedGuestData = cloned.sort((a, b) => {
            if (mainGuest) {
                if (mainGuest.id === a.id) {
                    return -1;
                }
                if (mainGuest.id === b.id) {
                    return 1;
                }
            }
            return a.guest_name.localeCompare(b.guest_name);
        });
        maxGuests = reservation.max_guests;
        totalGuests = sortedGuestData.length;
        sortedGuestData.forEach((guest: GuestGeneral) => {
            if (typeof guest.age !== 'number') {
                allAgesSet = false;
            }
        });
    }
    const allowAddGuest = totalGuests < maxGuests;
    const guestCountExceeded = totalGuests > maxGuests;
    const allowContinue = !guestCountExceeded && allAgesSet;

    if (isErrorGuest) {
        return (
            <Card raised>
                <StyledCardContent>
                    <Stack spacing={2}>
                        <Typography variant="h4">
                            Guests
                        </Typography>
                        <StandardRTKQError
                            mutationCall={false}
                            error={errorGuest}
                            endpoint={cloud_getReservationGuests} />
                    </Stack>
                </StyledCardContent>
            </Card>
        );
    }

    return (
        <>
            <Stack minHeight='350px' gap={4}>
                <Grid container columns={4}>
                    {/* Label row */}
                    <Grid item xs={2}>
                        <Typography variant="h6" color='text.secondary'>
                            Guest Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="h6" color='text.secondary'>
                            Age
                        </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign='end'>
                        <Typography sx={{ visibility: 'hidden' }} variant="h6" color='text.secondary'>
                            Edit
                        </Typography>
                    </Grid>
                    {noGuests &&
                        <Grid item xs={4}>
                            <EmptyContent hideImg title="No guests." description="You may add guests below." />
                        </Grid>
                    }
                    {!noGuests && !isFetchingGuest &&
                        <>
                            {sortedGuestData.map((guest, i) => {
                                const { guest_id, id } = guest;
                                //TODO: Consider the is-session-user icon as a polish item.
                                // const isCurrentUser = user?.email === email
                                if (mainGuest) {
                                    const isMainHost = mainGuest.id === id;
                                    if (isMainHost) {
                                        return <MainHostEntry key={guest_id + '-' + i} guest={guest} />;
                                    }
                                }
                                return (
                                    <NonMainEntry
                                        key={guest_id + '-' + i}
                                        guest={guest}
                                        handleDelete={() => {
                                            // console.log('DELETE GUEST');
                                            handleDeleteGuest(guest);
                                        }}
                                        handleEdit={(guestSelection: GuestGeneral) => {
                                            // console.log('EDIT GUEST');
                                            callbackEditGuest(guestSelection);
                                        }}
                                    />
                                );
                            })}
                        </>
                    }
                </Grid>
                <Typography align="right" variant="subtitle1" color={guestCountExceeded ? '#d22' : ''}>
                    {'Guest Count: ' + totalGuests} <Typography color='text.secondary' variant="caption"> (Max of {maxGuests})</Typography>
                </Typography>
                {!allAgesSet &&
                    <Typography align="center" variant="subtitle1" color='#d22'>
                        Please enter an age for each guest.
                    </Typography>
                }
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isLoadingDelete || parentLoading}
                    onClick={() => {
                        callbackCancel();
                    }}
                >
                    Back
                </Button>
                <Stack direction='row' spacing={1}>
                    <Button
                        color="secondary"
                        disabled={!allowAddGuest || isLoadingDelete || parentLoading}
                        variant="contained"
                        onClick={callbackAddGuest}
                    >
                        Add Guest
                    </Button>
                    <LoadingButton
                        loading={isLoadingDelete || parentLoading}
                        disabled={!allowContinue || isLoadingDelete || parentLoading}
                        variant="contained"
                        onClick={callbackContinue}
                    >
                        Continue
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}

// local sub-components

function NonMainEntry({
    guest,
    handleDelete,
    handleEdit,
}: {
    guest: GuestGeneral,
    handleDelete: (guestSelection: GuestGeneral) => void,
    handleEdit: (guestSelection: GuestGeneral) => void;
}
) {
    const { guest_name, age } = guest;
    const ageValid = checkAgeValid(age);
    const [openConfirm, setOpenConfirm] = useState(false);
    return (
        <Grid py={1} borderBottom={1} borderColor='lightgray' item container alignItems="center" columns={4} xs={4}>
            <Grid item xs={2}>
                <Typography variant="body1">
                    {guest_name}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                {ageValid
                    ? <Typography variant="body1">{age}</Typography>
                    : <Typography variant="body1" color='#d20'>Required</Typography>
                }
            </Grid>
            <Grid item xs={1}>
                <Stack direction='row' justifyContent='flex-end' gap={2} alignItems='center'>
                    <IconButton color='primary' onClick={() => {
                        handleEdit(guest);
                    }}>
                        <Iconify aria-disabled width={24} icon='mdi:edit-box' />
                    </IconButton>
                    <IconButton color='error' onClick={() => {
                        setOpenConfirm(true);
                    }}>
                        <Iconify aria-disabled width={24} icon='mdi:delete-circle-outline' />
                    </IconButton>
                </Stack>
            </Grid>
            <ConfirmDialog
                open={openConfirm}
                action={<Button
                    color='error'
                    onClick={() => {
                        handleDelete(guest);
                        setOpenConfirm(false);
                    }}>
                    Remove
                </Button>}
                onClose={() => { setOpenConfirm(false); }}
                title="Remove Guest"
                content={`Are you sure you want to remove ${guest.guest_name}?`}
            />
        </Grid>
    );
}

function MainHostEntry({ guest, userIsGuest }: { guest: GuestGeneral, userIsGuest?: boolean; }) {
    const { guest_name, age } = guest;
    const textColor = ''; // '#888' - appear disabled
    return (
        <Grid py={2} borderTop={1} borderBottom={1} borderColor='lightgray' item container alignItems="center" columns={4} xs={4}>
            <Grid item xs={2}>
                <Typography variant="body1">
                    {guest_name + ' (Host)'}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                {userIsGuest && <Iconify ml={1} icon='mdi:user-star-outline' />}
                <Typography variant="body1" color={textColor} width='20%'>{age}</Typography>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}