import { PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import { ActionMapType } from './auth/types';
import LoadingScreen from './components/loading-screen';
import { useGetListingsQuery } from './redux/rtkQuery/apiSlice';
import { useAuthContext } from './auth/useAuthContext';
import { Roles } from './utils/mrr/userConstants';
import { useReservationInfo } from './ReservationInfoContext';
import { safelyReadErrorMessage } from './utils/mrr/errorHandling';

const verboseLogging = false;

let loadingAttempts = 0;

type ListingInfoStateType = {
    isLoading: boolean;
    singleListingId: string | null;
    totalListings: number;
    errorMessage: string | null
};
const initialState: ListingInfoStateType = {
    isLoading: false,
    singleListingId: null,
    totalListings: 0,
    errorMessage: null
};
const ListingInfoContext = createContext(initialState);
enum Types {
    Initial = 'Initial',
    Update = 'Update',
    Loading = 'Loading',
    Error = 'Error'
}
type Payload = {
    [Types.Initial]: {
        totalListings: number;
    };
    [Types.Update]: {
        isLoading: false;
        singleListingId: string | null;
        totalListings: number;
    };
    [Types.Loading]: {
        isLoading: boolean;
    };
    [Types.Error]: {
        isLoading: false;
        errorMessage: string;
    }

};
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];
const reducer = (state: ListingInfoStateType, action: ActionsType) => {
    verboseLogging && console.log('IN >> LISTING REDUCER ACTION', action, '\nstate', state)

    if (action.type === Types.Initial) {
        const newState = initialState;
        return newState;
    }
    if (action.type === Types.Update) {
        return {
            ...state,
            ...action.payload
        };
    }
    if (action.type === Types.Loading) {
        const out = {
            ...state,
            isLoading: action.payload.isLoading
        }

        verboseLogging && console.log('LISTING REDUCER onLoading', '\nout', out);
        return out;
    }
    if (action.type === Types.Error) {
        return {
            ...state,
            errorMessage: action.payload.errorMessage,
            isLoading: false
        }
    }
    return state;
};
export function ListingInfoContextProvider({ children }: PropsWithChildren) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user, isInitialized } = useAuthContext()
    const {
        isError,
        isUninitialized,
        isLoading: isRTKQLoading,
        isSuccess,
        data,
        error
    } = useGetListingsQuery({}, { skip: !user || user.role !== Roles.User || !isInitialized });

    const { isLoading: parentLoading, errorMessage: parentError } = useReservationInfo()

    verboseLogging && console.log('LISTINGS PRELOADER', 'loading', isRTKQLoading, 'parentLoading', parentLoading, 'error', error, 'parentError', parentError);

    useEffect(() => {
        // verboseLogging && console.log('LISTINGS CONTEXT', isError, isFetching, isSuccess, isUninitialized);

        if (isUninitialized) {
            verboseLogging && console.log("initializing listings preloader")
            dispatch({
                type: Types.Initial,
                payload: initialState
            })
            return
        }
        if (isRTKQLoading) {
            // we need a manual cutoff to prevent infinite refetch attempts when the server is down
            const attemptNumber = loadingAttempts++;

            // only on the initial request do we need to display the loading screen
            verboseLogging && console.log("listings preloader loading", attemptNumber)

            if (attemptNumber < 1) {
                dispatch({
                    type: Types.Loading,
                    payload: {
                        isLoading: true
                    }
                })
            }
            return
        }
        if (isSuccess && Array.isArray(data)) {
            verboseLogging && console.log("listings preloader update", data)

            let singleListingId: string | null = null;

            if (data.length === 1) {
                singleListingId = data[0].id;
            }

            dispatch({
                type: Types.Update,
                payload: {
                    singleListingId: singleListingId,
                    totalListings: data.length,
                    isLoading: false
                }
            });
            return
        }
        if (isError) {
            verboseLogging && console.warn('listings preloader error', error)
            dispatch({
                type: Types.Error,
                payload: {
                    errorMessage: (error as string),
                    isLoading: false
                }
            })
            return
        }

        verboseLogging && console.warn('state not found for listings context')
    }, [data, error, isError, isRTKQLoading, isSuccess, isUninitialized]);

    if (isError) {
        console.log('listing preloader error', safelyReadErrorMessage(null, error));

        // fall through to draw the route
    }
    else if (loadingAttempts > 1) {
        verboseLogging && console.log('listing preloader already attempted', loadingAttempts);

        // fall through to draw the route
    }
    else if (parentLoading || isRTKQLoading) {
        //NOTE: Important! This 'parentLoading' depends on this context being a child of the
        //      ReservationInfoContext, along with any others that may be added.
        //      Since it's the innermost context of the preloader, it blocks until the data arrives.

        // Note that isLoading tracks of the initial request, only
        return <LoadingScreen />
    }

    return (
        <ListingInfoContext.Provider value={state}>
            {children}
        </ListingInfoContext.Provider>
    );
}
export const useListingInfo = () => {
    const listingContextInfo = useContext(ListingInfoContext);
    if (!listingContextInfo) throw new Error('useListingInfo context must be used inside ListingInfoContextProvider');
    return listingContextInfo;
};
