import { Card } from "@mui/material";
import { GPISGeneral } from "src/models/GPISGeneral";
import { FaqsList } from "../../sections/faqs";
import { FAQ } from "../../sections/faqs/FAQS";
import { StyledCardContent } from "../containers/StyledCardContent";

export function GPISFaq({ faq }: { faq: GPISGeneral['faq']; }) {

    if (!faq) {
        return null
    }

    const faqs: FAQ[] = faq.sections.map((faqItem) => ({
        id: faqItem.id,
        value: faqItem.category,
        heading: faqItem.title,
        detail: faqItem.body as string,
    }));

    return (
        <Card raised>
            <StyledCardContent>
                <FaqsList groupByCategory disableAnimation disableGutter title={faq.title} faqs={faqs} />
            </StyledCardContent>
        </Card>
    );
}
