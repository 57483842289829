import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/OobSignInPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const NotVerifiedPage = Loadable(lazy(() => import('../pages/auth/MRRNotVerifiedPage')));
export const OobActionLandingPage = Loadable(lazy(() => import('../pages/auth/OobActionLandingPage')));
export const MRRVerifiedPage = Loadable(lazy(() => import('../pages/auth/MRRVerifiedPage')));
export const OobChangePasswordPage = Loadable(lazy(() => import('../pages/auth/OobChangePasswordPage')));
export const OobCreateInitialPasswordPage = Loadable(lazy(() => import('../pages/auth/OobCreateInitialPasswordPage')));
export const LoginSuccessPage = Loadable(lazy(() => import('../pages/auth/LoginSuccessPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const ReservationsPage = Loadable(lazy(() => import('../pages/reservation/ReservationsPage')));
export const ReservationPage = Loadable(lazy(() => import('../pages/reservation/ReservationPage')));
export const TestPage = Loadable(lazy(() => import('../pages/dev/TestPage')));
export const TravelInsurancePage = Loadable(lazy(() => import('../pages/travelInsurance/TravelInsurancePage')));
export const InvoicePage = Loadable(lazy(() => import('../pages/revenue/InvoicePage')));
export const SignaturePage = Loadable(lazy(() => import('../pages/signurature/SignaturePage')));

// DASHBOARD: USER
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/account/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

// PAGE BY ROLE GUARD
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
