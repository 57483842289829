import { LabelColor } from "../components/label";

export class AccessCode {
    constructor(
        public readonly listing_name_detailed: string,
        public readonly name: string,
        public readonly instructions: string,
        public readonly access_code: string
    ) { }
    // ------------------------------------------------------------------
    public static mapIconToAccessCodeName(accessCodeName: string) {
        switch (accessCodeName) {
            case 'Door Code':
                return 'mdi:door-sliding-lock';
            case 'Pool Code':
                return 'mdi:pool';
            case 'Gate Code':
                return 'mdi:gate';
            case 'Garage Code':
                return 'mdi:garage';
            case 'Fitness Center Code':
                return 'ic:round-fitness-center';
            default:
                return 'mdi:key-chain';
        }
    }

    // ------------------------------------------------------------------
    public static mapColorToAccessCodeName(accessCodeName: string): LabelColor {
        switch (accessCodeName) {
            case 'Door Code':
                return 'primary';
            default:
                return 'default';
        }
    }

}