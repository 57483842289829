import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line import/no-cycle
import { apiSlice } from './rtkQuery/apiSlice';
import apiMessage from './slices/apiMessage';
import cartReducer from './slices/cart';
import { brandConfig } from '../config';

// ----------------------------------------------------------------------
export const chartPersistConfig = {
  key: 'cart',
  storage,
  keyPrefix: brandConfig.brandId,
  whitelist: ['checkout'],
};

const rootReducer = combineReducers({
  apiMessage: apiMessage,
  cart: persistReducer(chartPersistConfig, cartReducer),
  [apiSlice.reducerPath]: apiSlice.reducer
});

export default rootReducer;
