import { PaymentIntent } from '@stripe/stripe-js';
import { getAnalytics } from 'firebase/analytics';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useReducer } from "react";
import ReactGA from "react-ga4";
import { firebaseApp } from "../auth/FirebaseContext";
import { ActionMapType } from "../auth/types";
import { useAuthContext } from "../auth/useAuthContext";
import { GAEventBase } from "./GAEvent";
import { ReservationGeneral } from '../models/ReservationGeneral';
import { ROIGeneral } from '../models/ROIGeneral';
import { brandConfig } from '../config';


export const analytics = getAnalytics(firebaseApp)

const VERBOSE_LOGGING = false

// Initialize Analytics
if (!analytics) {
    throw new Error('Missing required GA_MEASUREMENT_ID')
}

export enum paymentEvent {
    VIEW_ITEM = 'view_item',
    SELECT_ITEM = 'select_item',
    PURCHASE = 'purchase',
    BEGIN_CHECKOUT = 'begin_checkout'
}

export enum itemCategory {
    LATE_CHECKOUT = 'Late Checkout',
    EXTENDED_CHECKOUT = 'Extend Checkout',
    BALANCE_DUE = "Balance Due",
    ADD_SERVICE = "Add Service",
    TRAVEL_INSURANCE = "Travel Insurance",
    CHECK_IN_REQUESTS = "Check In Request",
}

enum Types {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING'
}

type AnalyticsStateType = {
    isInitialized: boolean,
}

type Payload = {
    [Types.INITIAL]: {
        isInitialized: boolean;
    };
    [Types.LOADING]: {
        isLoading: boolean;
    };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const reducer = (state: AnalyticsStateType, action: ActionsType) => {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isLoading: false,
        };
    }
    if (action.type === Types.LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};

const initialReducerState: AnalyticsStateType = {
    isInitialized: false,
};

export interface AnalyticsContextI extends AnalyticsStateType {
    sendAnalytic: (fieldObject: any) => void
    setAnalytic: (fieldObject: any) => void
    sendEvent: (event: GAEventBase) => void
    tagPurchaseEvent: (name: string, items: AnalyticsCustomItem[], value: number, paymentIntent?: PaymentIntent, currency?: string) => void
}

const initialState = {
    ...initialReducerState,
    sendAnalytic: () => { throw new Error('Analytics not initialized.') },
    setAnalytic: () => { throw new Error('Analytics set not initialized.') },
    sendEvent: () => { throw new Error('Analytics event not initialized.') },
    tagPurchaseEvent: () => { throw new Error('Analytics event not initialized.') },
}

export const AnalyticsContext = createContext<AnalyticsContextI>(initialState)

export function AnalyticsProvider({ children }: PropsWithChildren) {
    ReactGA.initialize([
        {
            trackingId: analytics.app.options.measurementId || '',
            gaOptions: {},
            gtagOptions: {
            }
        },
    ])
    const { user } = useAuthContext()
    const [state, dispatch] = useReducer(reducer, initialReducerState)

    const setLoading = (isLoading: boolean) => {
        dispatch({
            type: Types.LOADING,
            payload: {
                isLoading
            }
        })
    }

    const {
        isInitialized,
        send: gaSend,
        set,
        event: gaEvent,
        reset,
    } = ReactGA

    // check initialized analytics
    useEffect(() => {
        dispatch({
            type: Types.INITIAL,
            payload: {
                isInitialized
            }
        })
    }, [isInitialized])

    useEffect(() => {
        if (isInitialized && user && user?.id) {
            set({ userId: user.id })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, user])

    const event = useCallback((concreteEvent: GAEventBase) => {
        if (!isInitialized) {
            console.warn('Analytics is not initailized.')
            return
        }

        VERBOSE_LOGGING && console.warn('Sending analytics Event', concreteEvent.name)
        setLoading(true)
        gaEvent(concreteEvent.name, concreteEvent.getParams())
        setLoading(false)


    }, [gaEvent, isInitialized])

    const tagPurchaseEvent = useCallback((
        name: string,
        items: AnalyticsCustomItem[],
        value: number,
        paymentIntent?: PaymentIntent,
        currency: string = 'USD',
    ) => {
        if (!isInitialized) {
            console.warn('Analytics is not initailized.')
            setLoading(false)
            return
        }

        setLoading(true)
        const params = {
            items,
            value,
            currency,
            ...(paymentIntent && { transaction_id: paymentIntent.id })
        }
        gaEvent(name, params)
        setLoading(false)
    }, [gaEvent, isInitialized])

    const send = useCallback((fileObject: any) => {
        if (!isInitialized) {
            console.warn('Analytics is not initailized.')
            return
        }
        gaSend(fileObject)

    }, [gaSend, isInitialized])


    const value = useMemo(() => ({
        ...state,
        sendAnalytic: send,
        setAnalytic: set,
        sendEvent: event,
        tagPurchaseEvent
    }), [state, send, set, event, tagPurchaseEvent])


    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    )
}

function createPriceFromReservation(reservation: ReservationGeneral, category: string, priceOverride?: number): number {
    if (priceOverride) {
        return priceOverride
    }
    switch (category) {
        case itemCategory.BALANCE_DUE:
            return reservation.balance_due
        case itemCategory.LATE_CHECKOUT:
            return reservation.late_check_out_amount
        case itemCategory.TRAVEL_INSURANCE:
            return reservation.estimated_travel_insurance_amount
        default:
            return reservation.total_amount
    }
}

export function createAnalyticsPaymentItem(item: ReservationGeneral | ROIGeneral | AnalyticsCustomItem, index: number, category?: string, amountOverride?: number): AnalyticsCustomItem {
    if (item instanceof ReservationGeneral) {
        if (!category) throw new Error('Category is required.')
        const priceFromReservation = createPriceFromReservation(item, category, amountOverride)
        return {
            item_id: item.id,
            item_name: category,
            affiliation: item.name,
            discount: 0,
            index,
            item_brand: brandConfig.name,
            item_category: category,
            price: priceFromReservation,
            quantity: 1,
        }
    }
    if (item instanceof ROIGeneral) {
        return {
            item_id: item.id,
            item_name: item.sub_type,
            affiliation: item.reservation_name,
            discount: 0,
            index,
            item_brand: brandConfig.name,
            item_category: item.type,
            price: item.amount,
            quantity: 1,
        }
    }
    return item
}

interface AnalyticsCustomItem {
    item_id: string,
    item_name: string,
    affiliation: string,
    discount: number,
    index: number,
    item_brand: string,
    item_category: string,
    price: number,
    quantity: number,
}