import { Box, Button, ButtonProps, Link, LinkProps, Stack, Typography } from '@mui/material';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { StripeCustomer } from 'src/models/Stripe';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { ROIGeneral } from '../../models/ROIGeneral';
import Iconify from '../iconify';
import { PaymentButton } from './PaymentButton';

interface StripePaymentActionProps {
    amount: number;
    handleAddCardClick: VoidFunction;
    showPaymentMethods: boolean;
    paymentMethods: PaymentMethod[];
    handleReturnToSavedPaymentMethodsClick: VoidFunction;
    selectedPaymentMethodId: string | null;
    paymentError: StripeError | null,
    setPaymentError: (error: StripeError | null) => void;
    return_url?: string;
    onClose?: VoidFunction;
    cancelButtonText?: string;
    onSuccessfulPayment?: () => Promise<{
        data: any;
    } | {
        error: unknown;
    }>;
    reservation?: ReservationGeneral;
    reservationOrderItem?: ROIGeneral;
    category: string;
    loading?: boolean;
    disabled?: boolean;
    metadata?: Record<string, string>
    description?: string;
    customer: StripeCustomer
}
export function StripePaymentActions(props: StripePaymentActionProps) {
    const {
        amount,
        cancelButtonText = 'Cancel Payment',
        category,
        handleAddCardClick,
        handleReturnToSavedPaymentMethodsClick,
        loading,
        onClose,
        onSuccessfulPayment,
        paymentError,
        paymentMethods,
        reservation,
        reservationOrderItem,
        return_url,
        selectedPaymentMethodId,
        setPaymentError,
        showPaymentMethods,
        disabled,
        metadata,
        description,
        customer
    } = props;

    return (
        <Stack spacing={1}>
            {showPaymentMethods && <NewPaymentMethodBtn onClick={handleAddCardClick} />}
            {!showPaymentMethods && paymentMethods.length > 0 && <ReturnToSavedPaymentsLink onClick={handleReturnToSavedPaymentMethodsClick} />}
            <PaymentButton
                category={category}
                reservation={reservation}
                reservationOrderItem={reservationOrderItem}
                returnUrl={return_url}
                selectedPaymentMethodId={selectedPaymentMethodId || ''}
                amount={amount}
                paymentError={paymentError}
                handlePaymentError={(e) => { setPaymentError(e); }}
                onSuccessfulPayment={onSuccessfulPayment}
                loading={loading}
                disabled={disabled}
                metadata={metadata}
                description={description}
                customer={customer} />
            {onClose &&
                <Stack direction='row' justifyContent='flex-start'>
                    <Button disabled={loading} variant='text' onClick={onClose}>{cancelButtonText}</Button>
                </Stack>
            }
        </Stack>
    );
}


function NewPaymentMethodBtn({ sx, onClick }: ButtonProps) {
    return (
        <Button
            variant='outlined'
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                borderColor: 'rgba(145, 158, 171, 0.24)',
                py: 1,
                ...sx
            }}
            startIcon={
                <Box
                    sx={{
                        border: (theme) => `1px solid ${theme.palette.primary.light}`,
                        p: .5,
                        display: 'flex',
                        borderRadius: 1,
                        mr: 1,
                    }}>
                    <Iconify height={16} icon='mdi:add' color='primary.light' />
                </Box>
            }>
            <Typography color='primary.light' fontWeight='600'>
                New Payment Method
            </Typography>
        </Button>
    )
}

function ReturnToSavedPaymentsLink({ onClick, sx }: LinkProps) {
    return (
        <Stack>
            <Link
                color='primary.light'
                variant='subtitle2'
                sx={{ width: 'fit-content', cursor: 'pointer', ...sx }}
                onClick={onClick}>
                Saved Payment Methods
            </Link>
        </Stack>
    )
}