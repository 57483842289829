import * as Sentry from "@sentry/react";
import { FirebaseError } from "firebase/app";
import { cloud_getCustomer } from "./cloudFunctions";
import { shouldReportToSentryFirebaseError } from "./shouldReportToSentryFirebaseError";
import { CheckEnvironment, SupportedEnvironments } from "../../config-global";

export const sentryAdminActionsFingerprint = 'admin-actions';
export const sentryOOBActionsFingerprint = 'oob-actions';
export const sentryStripeFingerprint = 'stripe-actions';

const envTag = CheckEnvironment(SupportedEnvironments.Dev) ? '-dev' : '';

export function checkStripeErrorExpected(firebaseError: FirebaseError, mode: string) {
    switch (mode) {
        case cloud_getCustomer:
            if (firebaseError.code === 'functions/not-found') {
                return true;
            }
            return false;
        default:
            return false;
    }
};

export function sendToSentry(error: any, errorMsg: string | null, fingerprintCategory: string, fingerprintDetail: string, extraDetails: any = null) {
    if (!error && !errorMsg) {
        console.warn('sendToSentry called without error information to send');
        return;
    }

    if (!shouldReportToSentryFirebaseError(error)) {
        return
    }

    const customFingerprint = ['{{ default }}', fingerprintCategory + envTag, fingerprintDetail];

    const outgoingError = error ? error : new Error(errorMsg as string);

    // optionally pass in extra info; NOTE: Max three levels deep, and max 8kb (or 256kb in certain cases).
    if (extraDetails) {
        //console.log('adding Sentry context', extraDetails);

        // set this data to be sent with the error
        Sentry.setContext('extra details', extraDetails);
    }

    Sentry.captureException(
        outgoingError,
        {
            fingerprint: customFingerprint
        });

    // clear the error context, so others don't include the same data
    Sentry.getCurrentScope().clear();
};
