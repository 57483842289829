import { Stack, Typography } from "@mui/material";
import { sum } from "lodash";
import { fCurrency } from "../../utils/formatNumber";

type PriceBreakdownListItem = {
    label: string,
    value: number,
    display: boolean,
    type?: 'percent'
}[]

interface PriceBreakdownListI {
    rows: PriceBreakdownListItem[],
    disableCalculatedTotal?: boolean
    totalOnly?: boolean
}

/**
 * @param {[[]]} rows - a two dimensional array
 * where each inner array is a row and each row is a {label, value} array of items.
 */

export function PriceBreakdownListPlusTax({ rows, disableCalculatedTotal, totalOnly = false }: PriceBreakdownListI) {

    const total = sum(rows.map((row) => row.map((item) => item.value)).flat())

    return (
        <Stack
            width='100%'
            alignItems='flex-end'
            px={2}
            spacing={2}
        >
            {!totalOnly &&
                rows.map((row, i) => (
                    <RenderRow key={`row_${i + 1}`} row={row} />
                ))
            }
            {!disableCalculatedTotal && <Stack>
                <Typography
                    textAlign='right'
                    fontSize={10}
                    variant="overline"
                    color='text.secondary'>
                    Total Before Taxes
                </Typography>
                <Typography
                    textAlign='right'
                    lineHeight={1}
                    variant="subtitle1">
                    {fCurrency(total, true)}
                </Typography>
            </Stack>}
        </Stack>
    )
}

function RenderRow({ row }: { row: PriceBreakdownListItem }) {
    return (
        <Stack
            direction='row'
            spacing={2}>
            {row.map((item) => (
                item.display
                    ? <Stack
                        key={`row_item_${item.label}`}
                        textAlign='right'>
                        <Typography
                            fontSize={10}
                            variant="overline"
                            color='text.secondary'>
                            {item.label}
                        </Typography>
                        <Typography
                            lineHeight={1}
                            variant="subtitle1">
                            {item.type === 'percent' ?
                                `${item.value}%`
                                : fCurrency(item.value, true)}
                        </Typography>
                    </Stack>
                    : null
            ))}
        </Stack>
    )
}