import { parseISO } from "date-fns"


export enum RevenueType {
    'Payment' = 'Payment',
    'Revenue Adjustment' = 'Revenue Adjustment',
    'Refund' = 'Refund',
    'Authorization' = 'Authorization',
}

export function mapIconToRevenueType(type: RevenueType) {
    switch (type) {
        case (RevenueType.Authorization):
            return 'mdi:receipt-text-pending';
        case (RevenueType.Payment):
            return 'mdi:cash';
        case (RevenueType.Refund):
            return 'mdi:cash-refund';
        default:
            return 'mdi:shape-outline'
    }
}

export class RevenueGeneral {
    public transaction_time_ISO: Date | null = null

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly status: string, //TODO: Enum and map these, as needed.
        public readonly vendor_name: string,
        public readonly description: string,
        public readonly amount: number,
        public readonly type: RevenueType,
        public readonly card_type: string,
        public readonly transaction_id: string,
        public readonly reservation_id: string,
        public readonly tender: string,
        private readonly _transaction_time: string, // YYYY-MM-DDT00:00:00Z

    ) {
        if (typeof this._transaction_time === 'string' && this._transaction_time.endsWith('Z')) {
            this.transaction_time_ISO = parseISO(this._transaction_time)
        }
    }
}

export function InstantiateRevenueFromJSON(json: any) {
    return new RevenueGeneral(
        json.id,
        json.name,
        json.status,
        json.vendor_name,
        json.description,
        json.amount,
        json.type,
        json.card_type,
        json.transaction_id,
        json.reservation_id,
        json.tender,
        json.transaction_time,
    )
}