import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CheckEnvironment, SupportedEnvironments } from "src/config-global";
import { ReservationGeneral } from "src/models/ReservationGeneral";
import * as Yup from 'yup';
import FormProvider from "../../components/hook-form/FormProvider";


interface RegistrationSummaryProps {
    reservation: ReservationGeneral,
    callbackClose: VoidFunction
};

interface FormInputProps {};

const POLL_INTERVAL_MS = 500;
const MAX_POLL_TIME = (CheckEnvironment(SupportedEnvironments.Dev) ? 999 : 15) * 1000;
const MAX_POLL_HITS = MAX_POLL_TIME / POLL_INTERVAL_MS;

const autoCloseTimerEnabled = true;

export function RegistrationSummary({
    reservation,
    callbackClose}: RegistrationSummaryProps
) {
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const [ pollExpired, setPollExpired ] = useState<boolean>(false);
    const [ pollHits, setPollHits ] = useState<number>(0);
    const [ pollRunning, setPollRunning ] = useState<boolean>(true);
    const [ pollTime, setPollTime ] = useState<number>(0);
    const pollTimerId = useRef<NodeJS.Timeout | null>(null);

    // timer to check the backend for account identity verification
    useEffect(() => {
        if (!autoCloseTimerEnabled) {
            return () => {};
        }

        // local helper
        function cleanupTimer(reason: string) {
            if (pollTimerId.current === null) {
                throw new Error('invalid poll state; timer id null on cleanup: ' + reason);
            }
            clearInterval(pollTimerId.current);
            pollTimerId.current = null;
            setPollHits(0);
            setPollTime(0);
            setPollRunning(false);
        }
        let timerId: NodeJS.Timeout;
        if (pollRunning) {
            if (pollTimerId.current === null) {
                timerId = setInterval(() => {
                    setPollHits((prev) => prev + 1);
                    setPollTime((prev) => prev + POLL_INTERVAL_MS);
                }, POLL_INTERVAL_MS);
                pollTimerId.current = timerId;
            }
        }
        else if (pollTimerId.current !== null) {
            cleanupTimer('STOPPED');
        }

        return () => {
            if (pollTimerId.current) {
                clearInterval(pollTimerId.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollRunning]);

    // a separate effect stops the timer, since its scope gets buried by React
    useEffect(() => {
        if (pollRunning && pollHits >= MAX_POLL_HITS) {
            setPollExpired(true);
            setPollRunning(false);
            callbackClose();
        }
    }, [callbackClose, pollHits, pollRunning]);

    const resolver = useMemo(() => {
        return Yup.object().shape({});
    }, []);

    const formDefaults = {};

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    return (
        <FormProvider name="Registration Summary Form" id={`Registration Summary Form: ${reservation.name}`} methods={methods}>
            {autoCloseTimerEnabled &&
                <Typography variant="body1">
                    Closing in {((MAX_POLL_TIME - pollTime) / 1000).toFixed(0)}...
                </Typography>
            }
            <div style={{height: '200px'}} />
        </FormProvider>
    );
}
