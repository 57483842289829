import { Stack, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";

export function CenteredRow({ children, spacing, sx }: PropsWithChildren<{ spacing?: number, sx?: SxProps }>) {
    return (
        <Stack
            sx={sx}
            spacing={spacing}
            direction="row"
            alignItems="center">
            {children}
        </Stack>
    );
}