import { MouseEvent, ReactElement, useState } from "react"
import { Button } from "@mui/material"
import { NavLink } from "react-router-dom"
import { ContactUsPopover } from "../../pages/dashboard/ContactUsPopover"
import { Roles } from "../../utils/mrr/userConstants"
import { GAEventPopover, useAnalyticsContext } from "../../analytics"

export interface FAQ {
    id: string,
    value: string,
    heading: string,
    detail: string,
    actions?: ReactElement[]
}

// General
const generalFaqs: FAQ[] = [
    {
        id: 'general_a',
        value: 'panel_general_a',
        heading: 'I need general help.',
        detail: 'Please contact the guest experience team.',
        actions: [<ContactUs key='general_a' />]
        // actions: [<ContactUs key='general_1' />]
    }
]

// Profile
const baseProfileFaqs: FAQ[] = [
    {
        id: 'profile_a',
        value: 'panel_profile_a',
        heading: 'Profile request A',
        detail: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
        actions: []
        // actions: [
        //     <NavigateToBtn
        //         title="Change Password"
        //         key='profile_4'
        //         to={PATH_HOME.user.account}
        //         search="tab=change_password" />]
    }
]

// Super-user Profile
const superUserProfileFaqs: FAQ[] = [
    {
        id: 'superuser_profile_a',
        value: 'panel_superuser_profile_a',
        heading: 'Super-user profile request A',
        detail: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
        actions: []
        // actions: [
        //     <NavigateToBtn
        //         title="Change Password"
        //         key='profile_4'
        //         to={PATH_HOME.user.account}
        //         search="tab=change_password" />]
    }
]

const profileFaqs: (role: Roles) => FAQ[] = (role) => {
    if (role === Roles.User) {
        return baseProfileFaqs
    }
    return [...baseProfileFaqs, ...superUserProfileFaqs]
}

const reservationFaqs: FAQ[] = [
    {
        id: 'placeholder_faq_a',
        value: 'panel_placeholder_faq_a',
        heading: 'Placeholder question A?',
        detail: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
        // actions: [<NavigateToBtn title="Create Booking" key='mangaged_property_bookings_2' to={PATH_HOME.booking.create} />]
        actions: []
    },
    // {
    //     id: 'placeholder_faq_b',
    //     value: 'panel_mangaged_property_bookings_3',
    //     heading: 'I want to cancel or modify my stay.',
    //     detail: 'Cancel or modify your booking by selecting it from any bookings table or calendar.',
    //     actions: [<NavigateToBtn title="Bookings" key='mangaged_property_bookings_3' to={PATH_HOME.booking.list} search={`guest_type=${ReservationGuestType.Owner}`} />]
    // },
]

const FAQS = (role: Roles) => [...generalFaqs, ...profileFaqs(role), ...reservationFaqs]

export default FAQS

function NavigateToBtn(props: { to: string, title: string, search?: string }) {
    const { to, title, search } = props
    return <Button variant="contained" component={NavLink} to={{ pathname: to, search }}>{title}</Button>
}

function ContactUs() {
    const { sendEvent } = useAnalyticsContext()
    const [openSupportPopOver, setOpenSupportPopOver] = useState<HTMLElement | null>(null)
    const handleCloseSupportPopOver = () => {
        setOpenSupportPopOver(null)
    }
    const handleOpenSupportPopOver = (event: MouseEvent<HTMLElement>) => {
        setOpenSupportPopOver(event.currentTarget)
        sendEvent(new GAEventPopover(`Support`))
    }
    return (
        <>
            <ContactUsPopover openSupportPopOver={openSupportPopOver} handleCloseSupportPopOver={handleCloseSupportPopOver} />
            <Button variant='contained' onClick={handleOpenSupportPopOver}>Contact Us</Button>
        </>
    )
}
