import { parseISO } from "date-fns"

export enum ROIStatus {
    PENDING = 'Pending',
    CONFIRMED = 'Confirmed',
    SCHEDULED = 'Scheduled',
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    // DEEP = 'Deep' //? Inactive
}

export const ROIStatusOptions = [ROIStatus.PENDING]

export enum ROIType {
    CAR_RENTAL = 'Car Rental',
    CLEANING = 'Cleaning',
    INSURANCE = 'Insurance',
    MISC = 'Misc',
    RENT = 'Rent',
    SPECIAL_REQUEST = 'Special Request',
}

export const ROITypeOptions = Object.values(ROIType)

export enum ROISubType {
    AIR_MATTRESS = 'Air Mattress', // Special Request
    BOOSTER = 'Booster', // Special Request
    CAR_RENTAL = 'Car Rental', // Car Rental *Default
    DAILY_HOUSEKEEPING = 'Daily Housekeeping', // Cleaning
    DAMAGE_WAIVER = 'Damage Waiver', // Misc
    DEPARTURE_CLEAN = 'Departure Clean', // Cleaning
    DEPARTURE_LINENS = 'Departure Linens', // Cleaning
    DONATION = 'Donation', // Misc
    FULL_CLEAN = 'Full Clean', // Cleaning
    FITNESS_CENTER = 'Fitness Center',
    FITNESS_CENTER_CODE = 'Fitness Center Code',
    GENERAL_EXCISE = 'General Excise',
    GROCERY_DELIVERY = 'Grocery Delivery', // Special Request
    HIGH_CHAIR = 'High Chair', // Special Request
    LANAI_CLEAN = 'Lanai Clean', // Cleaning
    LATE_CHECK_OUT = 'Late Check-Out', // Misc
    LINENS = 'Linens', // Cleaning
    LINENS_AND_TOWELS = 'Linens & Towels', // Cleaning
    NOT_AVAILABLE = 'N/A',
    OTHER = 'Other', // Misc
    PACK_AND_PLAY = 'Pack & Play', // Special Request
    PARKING_FEE = 'Parking Fee', // Rent
    PARTNER_FEE = 'Partner Fee',
    PLATFORM_FEE = 'Platform Fee',
    PROCESSOR_FEE = 'Processor Fee',
    RENT = 'Rent', // Rent *Default
    RESORT_FEE = 'Resort Fee', // Rent
    RICE_COOKER = 'Rice Cooker', // Special Request
    ROLLAWAY_MATTRESS = 'Rollaway Mattress', // Special Request
    SERVICE_CHARGE = 'Service Charge', // Misc, Rent
    SPLIT_BED = 'Split Bed', // Special Request
    TIDY = 'Tidy', // Cleaning *Default
    TIMESHARE_OCCUPANCY = 'Timeshare Occupancy',
    TOWELS = 'Towels', // Cleaning
    TRANSIENT_ACCOMMODATIONS = 'Transient Accommodations',
    TRAVEL_INSURANCE = 'Travel Insurance', // Travel Insurance *Default
    UPCHARGE = 'Upcharge', // Rent
    BIWEEKLY_TIDY = 'Biweekly Tidy', // Cleaning
    PET_FEE = 'Pet Fee', // Misc
}

export const getDefaultSubTypeFromType: (type: ROIType) => ROISubType | null = (type) => {
    switch (type) {
        case ROIType.CAR_RENTAL: {
            return ROISubType.CAR_RENTAL
        }
        case ROIType.RENT: {
            return ROISubType.RENT
        }
        case ROIType.CLEANING: {
            return ROISubType.TIDY
        }
        case ROIType.INSURANCE: {
            return ROISubType.TRAVEL_INSURANCE
        }
        default: {
            return null
        }
    }
}

const typeToSubType = new Map<ROIType, Set<ROISubType>>([
    [
        ROIType.CAR_RENTAL,
        new Set([ROISubType.CAR_RENTAL])
    ],
    [
        ROIType.CLEANING,
        new Set([
            ROISubType.DAILY_HOUSEKEEPING,
            ROISubType.DEPARTURE_CLEAN,
            ROISubType.DEPARTURE_LINENS,
            ROISubType.FULL_CLEAN,
            ROISubType.LANAI_CLEAN,
            ROISubType.LINENS,
            ROISubType.LINENS_AND_TOWELS,
            ROISubType.TIDY,
            ROISubType.BIWEEKLY_TIDY,
        ])
    ],
    [
        ROIType.INSURANCE,
        new Set([ROISubType.TRAVEL_INSURANCE])
    ],
    [
        ROIType.MISC,
        new Set([
            ROISubType.DAMAGE_WAIVER,
            ROISubType.DONATION,
            ROISubType.LATE_CHECK_OUT,
            ROISubType.OTHER,
            ROISubType.SERVICE_CHARGE,
        ])
    ],
    [
        ROIType.RENT,
        new Set([
            ROISubType.PARKING_FEE,
            ROISubType.RENT,
            ROISubType.RESORT_FEE,
            ROISubType.SERVICE_CHARGE,
            ROISubType.UPCHARGE,
        ])
    ],
    [
        ROIType.SPECIAL_REQUEST,
        new Set([
            ROISubType.AIR_MATTRESS,
            ROISubType.BOOSTER,
            ROISubType.GROCERY_DELIVERY,
            ROISubType.HIGH_CHAIR,
            ROISubType.PACK_AND_PLAY,
            ROISubType.RICE_COOKER,
            ROISubType.ROLLAWAY_MATTRESS,
            ROISubType.SPLIT_BED,
        ])
    ],
])

export const ROISubTypeOptions = (type: ROIType | null) => {
    if (type && typeToSubType.has(type)) {
        return Array.from(typeToSubType.get(type)!)
    }
    return []
}

export class ROIGeneralBase {
    constructor(
        public readonly type: ROIType,
        public readonly sub_type: ROISubType,
        public readonly status: ROIStatus,
        public description: string,
        public readonly tax_amount: number, // 17.14%
        public readonly tax_rate: number, // 17.14%
        public readonly listing_id: string,
        public readonly amount: number,
    ) { }
}

export class ROIGeneral extends ROIGeneralBase {
    public start_time_ISO: Date | null = null
    public end_time_ISO: Date | null = null

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly status: ROIStatus,
        public description: string,
        public readonly type: ROIType,
        public readonly sub_type: ROISubType,
        public readonly listing_id: string,
        public readonly amount: number,
        public readonly tax_amount: number, // in dollar value $10.00
        public readonly discount: number,
        public readonly subtotal: number,
        public readonly total_amount: number,
        public readonly reservation_id: string,
        public readonly reservation_name: string,
        public readonly tax_rate: number, // 17.14%
        public readonly fee_type: string,
        public readonly service_window_start: string,
        public readonly service_window_end: string,
        private _start_time: string, // YYYY-MM-DDT00:00:00Z
        private readonly _end_time: string, // YYYY-MM-DDT00:00:00Z
        public requested_check_out_time?: string,
    ) {
        super(type,
            sub_type,
            status,
            description,
            tax_amount,
            tax_rate,
            listing_id,
            amount)

        if (typeof this._start_time === 'string' && this._start_time.endsWith('Z')) {
            this.start_time_ISO = parseISO(this._start_time)
        }
        if (typeof this._end_time === 'string' && this._end_time.endsWith('Z')) {
            this.end_time_ISO = parseISO(this._end_time)
        }
    }

    setStartTime(date: Date) {
        this.start_time_ISO = date;
        this._start_time = date.toJSON();
    }
}

export function InstantiateROIFromJSON(json: any) {
    return new ROIGeneral(
        json.id,
        json.name,
        json.status,
        json.description,
        json.type,
        json.sub_type,
        json.listing_id,
        json.amount,
        json.tax_amount,
        json.discount,
        json.subtotal,
        json.total_amount,
        json.reservation_id,
        json.reservation_name,
        json.tax_rate,
        json.fee_type,
        json.service_window_start,
        json.service_window_end,
        json.start_time,
        json.end_time,
        json.requested_check_out_time
    )
}