import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Stack
} from "@mui/material";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import {
    RHFAutocomplete,
    RHFTextField
} from "../../components/hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { usePatchRegistrationStepMutation } from "../../redux/rtkQuery/apiSlice";
import { cloud_patchRegistrationStep } from "../../utils/mrr/cloudFunctions";
import { StandardRTKQError } from "../error/StandardRTKQError";
import { RegistrationPicklistItem } from "./types";

enum StayReason {
    Anniversary = 'Anniversary',
    BacheloretteParty = 'Bachelorette Party',
    BachelorParty = 'Bachelor Party',
    Birthday = 'Birthday',
    Engagement = 'Engagement',
    FamilyVacation = 'Family Vacation',
    Graduation = 'Graduation',
    Honeymoon = 'Honeymoon',
    RomanticGetaway = 'Romantic Getaway',
    Wedding = 'Wedding',
    Other = 'Other'
};

const StayReasonOptions = Object.values(StayReason);

interface RegistrationStayReasonFormProps {
    stepCompleted: boolean,
    reservation: ReservationGeneral,
    callbackCancel: VoidFunction,
    callbackContinue: VoidFunction,
};

interface FormInputProps {
    reason: string,
    details: string;
};

const reasonErrorText = 'Reason is required. If unsure, please select \'Other\'.';

export function RegistrationStayReasonForm({
    stepCompleted,
    reservation,
    callbackCancel,
    callbackContinue }: RegistrationStayReasonFormProps
) {
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const resolver = useMemo(() => {
        return Yup.object().shape({
            reason: Yup.string().required(reasonErrorText).oneOf(StayReasonOptions, reasonErrorText),
            details: Yup.string().max(250).test('details', 'Please provide details.', (value, context) => {
                if (context.parent.reason !== StayReason.Other) {
                    return true;
                }
                return !!value;
            })
        });
    }, []);

    const formDefaults = {
        reason: reservation.stay_reason || '',
        details: reservation.stay_reason_detail || ''
    };

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            dirtyFields,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async ({
        reason,
        details
    }) => {

        //TODO: Check form-clean to bypass here, too?
        if (stepCompleted) {
            callbackContinue();
            return;
        }

        const sfPayload = {
            reservationName: reservation.name,
            registrationStep: RegistrationPicklistItem.STAY_REASON,
            stay_reason: reason,
            ...(details && { stay_reason_detail: details })
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    console.error(data.error.message);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update stay reason', putError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    });

    return (
        <FormProvider name="Add Reason Form" id={`Add Reason Form: ${reservation.name}`} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2}>
                {patchStepIsError &&
                    <StandardRTKQError
                        error={patchStepError}
                        endpoint={cloud_patchRegistrationStep}
                        mutationCall
                    />
                }
                <RHFAutocomplete
                    fullWidth
                    aria-required
                    name='reason'
                    options={StayReasonOptions}
                    label="Reason" />
                <RHFTextField
                    fullWidth
                    multiline
                    rows={4}
                    name="details"
                    label='Details' />
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isSubmitting}
                    onClick={callbackCancel}
                >
                    Back
                </Button>
                <LoadingButton
                    type="submit"
                    loading={patchStepIsLoading}
                    disabled={!isValid && !stepCompleted}
                    variant="contained"
                >
                    Continue
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
