import { useDispatch, useSelector } from 'src/redux/store';
// import { showError } from 'src/redux/slices/error';
import { PropsWithChildren, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { removeError, removeMessage } from '../../redux/slices/apiMessage';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';

// ----------------------------------------------------------------------

export default function ReduxMessageProvider({ children }: PropsWithChildren) {
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	const errorsSelector = useSelector(state => state.apiMessage.errors);
	const messagesSelector = useSelector(state => state.apiMessage.messages);

	const [processingError, setProcessingError] = useState(false)
	const [processingMessage, setProcessingMessage] = useState(false)

	//! Handle Errors ----------------
	useEffect(() => {
		if (errorsSelector.length && !processingError) {
			setProcessingError(true)
			const curError = errorsSelector[0]
			if (curError.showSnackbar) {
				const message = safelyReadErrorMessage(null, curError.error)
				enqueueSnackbar(message, { variant: 'error', onClick: curError.onClick })
			}
			dispatch(removeError(curError.id))
			setProcessingError(false)
		}
	}, [errorsSelector, enqueueSnackbar, dispatch, processingError])

	//! Handle Messages ----------------
	useEffect(() => {
		if (messagesSelector.length && !processingMessage) {
			setProcessingMessage(true)
			const curMessage = messagesSelector[0]
			const variant = curMessage.variant || 'success'
			enqueueSnackbar(curMessage.message, { variant: variant as any, onClick: curMessage.onClick })
			dispatch(removeMessage(curMessage.id))
			setProcessingMessage(false)
		}
	}, [messagesSelector, enqueueSnackbar, dispatch, processingMessage])

	return (
		<>
			{children}
		</>
	);
}