import axios, { AxiosHeaders, AxiosRequestHeaders } from 'axios'
import { User } from 'firebase/auth'
import { GCF_BASE_URL } from '../config-global'

export async function axiosToGCF(
    user: User | null,
    GCFunctionName: string,
    contentType: string,
    method: 'GET' | 'PUT' | 'POST' | 'DELETE' = 'GET',
    headers: AxiosRequestHeaders = new AxiosHeaders,
    searchParams: URLSearchParams = new URLSearchParams(),
    abortController: AbortController = new AbortController
) {
    if (!user) {
        throw new Error(`Unauthorized request to ${GCFunctionName}`)
    }
    // Set Tokens
    headers.setAuthorization(`Bearer ${await user.getIdToken()}`)
    headers.Accept = '*'
    if (!headers['Content-Type']) {
        if (!contentType) {
            throw new Error('Missing content type for request to GCF.')
        }
        headers.setContentType(contentType)
    }

    return axios.create({
        headers: headers,
        method: method,
        signal: abortController.signal,
        baseURL: GCF_BASE_URL,
        params: searchParams
    })
}
