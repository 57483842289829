import { CircularProgress, Typography } from '@mui/material';
import { CenteredStack } from '../containers/CenteredStack';
import { StyledCardContent } from '../containers/StyledCardContent';

export const PaymentLoadSpinner = () => {
    return (
        <StyledCardContent>
            <CenteredStack spacing={2}>
                <Typography variant="h6">Setting up payment.</Typography>
                <CircularProgress />
            </CenteredStack>
        </StyledCardContent>
    );
};
