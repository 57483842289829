import * as Yup from 'yup';

export const handleIntegerInputChange = (e: any) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // allow only numbers
    return inputValue;
};

export const convertInputTextToInt = (inputText: string) => {
    const numberValue = parseInt(inputText, 10);
    if (!Number.isNaN(numberValue)) {
        return String(numberValue);
    }
    return "";
};

export const ageRequirementText = 'Please provide a valid age.';

export const checkAgeRequirement: Yup.TestFunction<string | undefined, Yup.AnyObject> = (value) => {
    const age = parseInt(value || '', 10);
    return checkAgeValid(age);
};

export const checkAgeValid = (age: any, minAge: number = 0) => {
    if (typeof age === 'number') {
        if (!Number.isNaN(age)) {
            return age >= minAge;
        }
    }
    return false;
};

export const MINOR_AGE = 18;