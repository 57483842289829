import { CountryCode } from 'libphonenumber-js';
import * as Yup from 'yup';
import { validatePhoneNumber } from '../../utils/mrr/phone/phone';
import { EMAIL_REGEX } from '../../utils/mrr/uiConstants';
import { Roles, SalutationOptions, UserStatus } from '../../utils/mrr/userConstants';

export const credentialRequiredErrorMessage = 'Email or phone required.';
export const credentialRequiredErrorType = 'credential';

const errorOnEmptyCredentials = true;

function isEmailValid(email: string): boolean {
    return EMAIL_REGEX.test(email);
}

function isPhoneValid(phoneNumber: string, phoneCountry: string): boolean {
    const result = validatePhoneNumber(phoneNumber, (phoneCountry || 'US') as CountryCode);
    return result.success;
}

export const yupCheckPhone = (value: any, context: Yup.TestContext) => {
    if (!value) {
        // empty phone is supported only if the email address is valid
        if (isEmailValid(context.parent.email)) {
            return true;
        }
        if (errorOnEmptyCredentials) {
            throw new Yup.ValidationError(credentialRequiredErrorMessage, value, context.path, credentialRequiredErrorType);
        }
        return true;
    }
    const { phoneCountry } = context.parent;
    const result = validatePhoneNumber(value, phoneCountry || 'US');
    if (result.success) {
        return true;
    }
    throw new Yup.ValidationError(result.error, value, context.path);
};

export const yupCheckEmail = (value: any, context: Yup.TestContext) => {
    if (!value) {
        // empty email is supported only if the phone number is valid
        if (isPhoneValid(context.parent.phoneNumber, context.parent.phoneCountry)) {
            return true;
        }
        if (errorOnEmptyCredentials) {
            throw new Yup.ValidationError(credentialRequiredErrorMessage, value, context.path, credentialRequiredErrorType);
        }
        return true;
    }
    if (isEmailValid(value)) {
        return true;
    }
    throw new Yup.ValidationError('Email must be valid.', value, context.path);
};

const BaseUserSchema = Yup.object().shape({
    nameFirst: Yup.string().required('First name is required.'),
    nameLast: Yup.string().required('Last name is required.'),
    email: Yup.string().test('email', 'Invalid email address.', yupCheckEmail),
    phoneNumber: Yup.string().test('phoneNumber', 'Invalid phone number.', yupCheckPhone),
    salutation: Yup.string().oneOf(SalutationOptions, { message: 'Please pick a value from the list.' }).nullable()
});

//---------- EDIT USER ------------------
/**
 * @Validation used to validate edit non-admin user.
 */
export const EditSelfSchema = BaseUserSchema.shape({
    address: Yup.string().required('Please provide an address.'),
    city: Yup.string().required('Please provide a city.'),
    zipCode: Yup.string().required('Please provide a Zip/code.'),
    country: Yup.string().nullable(),
    state: Yup.string().nullable(),
    avatarUrl: Yup.mixed().nullable()
});

/**
 * @Validation used to validate edit admin user.
 */
export const EditAdminSchema = BaseUserSchema.shape({
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    country: Yup.string().nullable(),
    state: Yup.string().nullable(),
    avatarUrl: Yup.mixed().nullable(),
    role: Yup.string().nullable(),
    isVerified: Yup.boolean().nullable()
});

//-------------- Create User -----------
/**
 * @Validation used to validate create non-admin user.
 */
export const CreateSelfSchema = BaseUserSchema.shape({
    phoneNumber: Yup.string().test('phoneNumber', 'Invalid phone number.', yupCheckPhone),
    address: Yup.string().nullable(),
    country: Yup.string().nullable(),
    state: Yup.string().nullable(),
    city: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    avatarUrl: Yup.mixed().nullable()
});

/**
 * @Validation used to validate create admin user.
 */
export const CreateAdminSchema = CreateSelfSchema.shape({
    role: Yup.string().required('Role is required'),
    isVerified: Yup.boolean().nullable()
});


// Helpers ---------------

export const defaultCreateUserValues = {
    nameFirst: "",
    nameLast: "",
    email: "",
    targetEmail: "",
    isVerified: false,
    role: Roles.User,
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    avatarUrl: "",
    status: UserStatus.Active,
    salutation: "",

    // here for one RHF checkbox, which requires a bool
    statusIsActive: true
}