import { Alert, Card, Container, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, Navigate, useSearchParams } from "react-router-dom";
import { StyledCardContent } from "../../components/containers/StyledCardContent";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import Iconify from "../../components/iconify";
import { useSettingsContext } from "../../components/settings";
import { paymentAmountURLKey, paymentCallbackError, paymentOriginURLKey, paymentRedirectStatus } from "../../components/stripe/PaymentTypes";
import { PATH_ROOT } from "../../routes/paths";
import { fCurrency } from "../../utils/formatNumber";
import fTitle, { PageTitles } from "../../utils/mrr/formatTitle";

const redirectDelaySeconds = 10;

type RedirectStatus = 'canceled'
    | 'processing'
    | 'requires_action'
    | 'succeeded'
    | 'unknown'
    | 'failed'
/* these will not happen from our flow
| 'requires_capture'
| 'requires_confirmation'
| 'requires_payment_method'
*/

export default function PaymentConfirmationPage() {
    const { themeStretch } = useSettingsContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const [queryParams, setQueryParams] = useState<Map<string, string>>(new Map<string, string>())
    const [originURL, setOriginURL] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(redirectDelaySeconds);

    const amount = queryParams.get(paymentAmountURLKey);
    const redirectStatus = (queryParams.get(paymentRedirectStatus) as RedirectStatus) || 'unknown';
    const callbackError = queryParams.get(paymentCallbackError);

    useEffect(() => {
        const map = new Map<string, string>();
        searchParams.forEach((value, key) => {
            map.set(key, value)
        });
        setQueryParams(map);
        setOriginURL(map.get(paymentOriginURLKey) || PATH_ROOT);
        setSearchParams(new URLSearchParams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (callbackError) { return () => null }
        let timerId: NodeJS.Timeout | null = null;
        if (originURL !== null) {
            timerId = setInterval(() => {
                setCountdown((prev) => {
                    if (prev > 0) {
                        return prev - 1;
                    }
                    return prev;
                });
            }, 1000);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
                setCountdown(redirectDelaySeconds)
            }
        }
    }, [callbackError, originURL]);

    if (!callbackError && countdown === 0 && originURL !== null) {
        try {
            const targetRoute = new URL(originURL).pathname;
            return <Navigate to={targetRoute} replace />;
        } catch {
            return <Navigate to={originURL} replace />;
        }
    }

    const showRedirectSection = () => {
        switch (redirectStatus) {
            case 'requires_action':
                return false;
            default:
                return true;
        }
    }
    return (
        <>
            <Helmet>
                <title>{fTitle(PageTitles.PaymentConfirmationPage)}</title>
            </Helmet>
            <Container
                maxWidth={
                    themeStretch
                        ? 'md'
                        : 'lg'
                }
            >
                <CustomBreadcrumbs
                    heading={PageTitles.PaymentConfirmationPage}
                    links={[
                        { name: 'Home', href: PATH_ROOT }
                    ]}
                />
                <Card raised>
                    <StyledCardContent>
                        <Stack spacing={2} alignItems='center' justifyContent='center' >
                            {redirectStatus === 'succeeded' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="line-md:confirm-circle" width={55} color='success.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Confirmed
                                    </Typography>
                                    {callbackError &&
                                        <Alert sx={{ my: 1 }} severity="error">{callbackError}</Alert>
                                    }
                                    {amount && <Typography variant="body1" textAlign='center'>
                                        Your payment of {fCurrency(amount, true)} has been processed successfully.
                                    </Typography>}
                                    {!amount && <Typography variant="body1" textAlign='center'>
                                        Your payment has been processed successfully.
                                    </Typography>}
                                </Stack>
                            }
                            {redirectStatus === 'processing' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="mdi:receipt-pending" width={55} color='primary.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Pending
                                    </Typography>
                                    {callbackError &&
                                        <Alert sx={{ my: 1 }} severity="error">{callbackError}</Alert>
                                    }
                                    {amount && <Typography variant="body1" textAlign='center'>
                                        Your payment of {fCurrency(amount, true)} is processing.
                                    </Typography>}
                                    <Typography variant="body2" textAlign='center'>
                                        This may take up to 3 days.
                                    </Typography>
                                </Stack>
                            }
                            {redirectStatus === 'failed' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="line-md:alert-circle" width={55} color='error.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Failed
                                    </Typography>
                                    <Typography variant="body1" textAlign='center'>
                                        Unfortunately, there was an issue processing your payment.
                                    </Typography>
                                    <Typography variant="body2" textAlign='center'>
                                        Please try again.
                                    </Typography>
                                </Stack>}
                            {redirectStatus === 'requires_action' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="clarity:bank-outline-badged" width={55} color='info.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Requires Action
                                    </Typography>
                                    <Typography variant="body1" textAlign='center'>
                                        To complete your payment, please verify two small deposits that will be sent to your bank account.
                                    </Typography>
                                    <Typography variant="body2" textAlign='center'>
                                        This verification is necessary to ensure your account security and will appear within 1-2 business days.
                                    </Typography>
                                    {originURL &&
                                        <Link
                                            variant="h6"
                                            component={NavLink}
                                            to={originURL}
                                            replace
                                            sx={{ mt: 2 }}>
                                            Click Here To Continue
                                        </Link>}
                                </Stack>}
                            {redirectStatus === 'canceled' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="line-md:cancel-twotone" width={55} color='secondary.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Canceled
                                    </Typography>
                                    {amount && <Typography variant="body1" textAlign='center'>
                                        Your payment of {fCurrency(amount, true)} has canceled.
                                    </Typography>}
                                </Stack>}
                            {redirectStatus === 'unknown' &&
                                <Stack alignItems='center' justifyContent='center' flexGrow={1}>
                                    <Iconify icon="line-md:question-circle" width={55} color='warning.main' />
                                    <Typography variant="h3" textAlign='center'>
                                        Payment Status Unknown
                                    </Typography>
                                    <Typography variant="body1" textAlign='center'>
                                        We are unable to determine the status of your payment.
                                    </Typography>
                                    <Typography variant="body2" textAlign='center'>
                                        Please contact support for assistance.
                                    </Typography>
                                </Stack>}
                            {originURL !== null && showRedirectSection() &&
                                <RedirectSection url={originURL} countdown={countdown} showCountDown={!callbackError} />
                            }
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Container >
        </>
    )
}

function RedirectSection({ countdown, url, showCountDown }: { countdown: number, url: string, showCountDown: boolean }) {
    return (
        <>
            {showCountDown && <>
                <Typography variant="subtitle1" color='text.secondary'>
                    You will be redirected in {countdown} second{countdown !== 1 ? 's' : ''}...
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    or
                </Typography>
            </>}
            <Link
                variant="h6"
                component={NavLink}
                to={url}
                replace>
                Click Here To Continue
            </Link>

        </>
    )
}