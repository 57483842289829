import { Box } from "@mui/material";


export function GPISSectionBody({ body }: { body: string | JSX.Element; }) {
    if (!(typeof body === 'string')) {
        return body;
    }
    return (
        <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            flexWrap='wrap'
            component='div'
            sx={{
                lineHeight: 'normal',
                '& ul': { px: 2 },
                '& li': { wordBreak: 'break-word' },
                typography: { variant: 'body1', color: 'text.primary' }
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ '__html': body }} />);
}
