import { FirebaseError } from "firebase/app";
import { MutationMessageProviderErrorText, QueryMessageProviderErrorText } from "../../redux/middleware/apiMessageTexts";

export const EmergencyFallbackErrorText = 'There was a problem.';

export function safelyReadErrorMessage(prefix: null | string, error: any) {
    let errorText = prefix === null ? '' : prefix;

    let errorDetail = '';

    if (error) {
        // check "errorMessage" first, which comes from our custom errors
        if ((typeof error.errorMessage === 'string') && error.errorMessage !== '') {
            errorDetail = error.errorMessage;
        }

        // check "message" next, which is the most common
        if ((typeof error.message === 'string') && error.message !== '') {
            errorDetail = error.message;
        }

        // we'll also take a raw string, to be thorough
        if ((typeof error === 'string') && error !== '') {
            errorDetail = error;
        }
    }

    // if we found an error detail, tack it on
    if (errorDetail !== '') {
        errorText += errorDetail;
    }
    else if (prefix) {
        // failsafe: if there was a prefix, but we found no suffix, append _something_
        errorText += 'Unknown';
    }

    return errorText;
};


export type FriendlyErrorInfo = {
    message: string,
    detail: string | null
};


export function convertRTKErrorToFriendly(error: any, endpoint: string, mutationCall: boolean): FriendlyErrorInfo {
    let cannedMessage = mutationCall
                            ? MutationMessageProviderErrorText[endpoint]
                            : QueryMessageProviderErrorText[endpoint];

    if (!cannedMessage) {
        // failsafe
        cannedMessage = EmergencyFallbackErrorText;
    }

    const outgoing: FriendlyErrorInfo = {
        message: cannedMessage,
        detail: null
    };

    const errorAny: any = error as any;

    if (errorAny) {
        if (errorAny instanceof FirebaseError) {
            // NOTE: This is available, if there are supported 404s we plan to handle more gently
            // if (errorAny.code === "functions/not-found") {
            //     dataNotFound = true;
            // }

            // parse out embedded SF details, if present
            if (errorAny.message) {
                try {
                    const parsedJSON = JSON.parse(errorAny.message);
                    errorAny.message = parsedJSON.error ? parsedJSON.error.message : EmergencyFallbackErrorText;
                }
                catch (parseException: any) {
                    //NOTE: Not necessarily a problem! We get varied packages from GCF and/or Apex.
                    //      It could be just another format we haven't seen.
                    //TODO: If so, add logic to support it, and ultimately produce a human-friendly string.
                }
            }

            outgoing.detail = errorAny.message;
        }
        else {
            // failsafe
            outgoing.detail = String(errorAny);

            if (outgoing.detail === 'true') {
                // these can come from RTK errors
                outgoing.detail = null;
            }
        }
    }

    return outgoing;
};
