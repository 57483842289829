import { PaymentIntent } from "@stripe/stripe-js";
import { AnalyticsContextI, createAnalyticsPaymentItem, paymentEvent } from "./index";
import { ReservationGeneral } from "../models/ReservationGeneral";
import { ROIGeneral } from "../models/ROIGeneral";

const verboseLogging = false;

export function sendAndSwallowPurchaseEvent(
    category: string,
    amount: number,
    tagPurchaseEvent: AnalyticsContextI['tagPurchaseEvent'],
    paymentIntent?: PaymentIntent,
    reservation?: ReservationGeneral,
    reservationOrderItem?: ROIGeneral,
    eventType?: paymentEvent
) {
    try {
        // ----- Analytics -----
        const items = [];
        if (reservation) {
            items.push(createAnalyticsPaymentItem(reservation, 0, category));
        }
        if (reservationOrderItem) {
            items.push(createAnalyticsPaymentItem(reservationOrderItem, 0, category));
        }
        verboseLogging && console.log('sending analytics purchase event.');
        tagPurchaseEvent(eventType || paymentEvent.PURCHASE, items, amount, paymentIntent, 'USD');
    } catch (error) {
        // swallow analytics errors
        verboseLogging && console.warn('error sending analytics', error);
    }
}
