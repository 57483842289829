import { Masonry } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import EmptyContent from "../../../components/empty-content/EmptyContent";
import Label, { LabelColor } from "../../../components/label";
import { ReservationListCard } from "../../../components/reservation/ReservationListCard";
import useResponsive from "../../../hooks/useResponsive";
import { ReservationGeneral } from "../../../models/ReservationGeneral";

// -----------------------------

enum ReservationsTabLabel {
    current = 'Current',
    upcoming = 'Upcoming',
    past = 'Past'
}
const TabOptions = Object.values(ReservationsTabLabel);

// -----------------------------

function sortReservationsByCheckout(reservations: ReservationGeneral[]) {
    return reservations.sort(
        (a, b) => a.check_in_ISO.getTime() - b.check_in_ISO.getTime()
    );
}

// -----------------------------
export function ReservationCardSection({
    reservations,
    isProperty
}: {
    reservations: ReservationGeneral[];
    isProperty?: boolean;
}) {
    const horizontal = useResponsive('up', 500)
    const below860 = useResponsive('down', 860)
    const [tab, setTab] = useState(ReservationsTabLabel.current);

    const filteredCurrentReservations = reservations.filter((res) => res.is_current_booking);
    const filteredPastReservations = reservations.filter((res) => res.is_past_booking);
    const filteredUpcomingReservations = reservations.filter((res) => res.is_future_booking);

    const filteredReservations =
        tab === ReservationsTabLabel.current
            ? sortReservationsByCheckout(filteredCurrentReservations)
            : tab === ReservationsTabLabel.upcoming
                ? sortReservationsByCheckout(filteredUpcomingReservations)
                : sortReservationsByCheckout(filteredPastReservations);

    const handleTabChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: ReservationsTabLabel) => {
        setTab(newValue);
    };

    let tabInfo = TabOptions.map(v => {
        const color =
            v === ReservationsTabLabel.current
                ? 'secondary'
                : v === ReservationsTabLabel.upcoming
                    ? 'info'
                    : 'primary';

        const count =
            v === ReservationsTabLabel.current
                ? filteredCurrentReservations.length
                : v === ReservationsTabLabel.upcoming
                    ? filteredUpcomingReservations.length
                    : filteredPastReservations.length;

        return {
            value: v,
            label: v,
            color: color as LabelColor,
            count
        };
    });

    const currentEmpty = filteredCurrentReservations.length === 0;
    const pastEmpty = filteredPastReservations.length === 0;
    const upcomingEmpty = filteredUpcomingReservations.length === 0;

    if (currentEmpty && pastEmpty && upcomingEmpty) {
        // no-data case
        return (
            <EmptyContent
                title="No Stays."
            />
        );
    }

    tabInfo = tabInfo.filter((v) => {
        if (v.value === ReservationsTabLabel.current) {
            return !currentEmpty;
        }
        if (v.value === ReservationsTabLabel.past) {
            return !pastEmpty;
        }
        if (v.value === ReservationsTabLabel.upcoming) {
            return !upcomingEmpty;
        }

        throw new Error('Invalid entry in the stay tabs enum: ' + v.value);
    });

    // switch away from empty tabs (also a very rare case)

    if (tab === ReservationsTabLabel.current) {
        if (currentEmpty) {
            setTab(ReservationsTabLabel.upcoming);
        }
    }

    if (tab === ReservationsTabLabel.upcoming) {
        if (upcomingEmpty) {
            setTab(ReservationsTabLabel.past);
        }
    }

    if (tab === ReservationsTabLabel.past) {
        if (pastEmpty) {
            setTab(ReservationsTabLabel.current);
        }
    }

    return (
        <>
            <Tabs
                variant="standard"
                value={tab}
                onChange={handleTabChange}
                sx={{
                    mb: 1
                }}
            >
                {tabInfo.map((tabItem, i) => (
                    <Tab
                        key={`${tabItem.value}-${i}`}
                        value={tabItem.value}
                        label={tabItem.label}
                        icon={<Label color={tabItem.color} sx={{ mr: 1 }}> {tabItem.count} </Label>} />
                ))}
            </Tabs>

            <Masonry
                columns={below860 ? 1 : 2}
                spacing={2}>
                {filteredReservations.length === 0 &&
                    <EmptyContent
                        title={`No ${tab.toLowerCase()} stays.`} />
                }
                {filteredReservations.map(
                    (reservation, i) => {
                        return (
                            <ReservationListCard
                                key={`${reservation.name}_hybrid_${i}`}
                                horizontal={horizontal}
                                reservation={reservation}
                                isProperty={isProperty} />
                        )
                    })}
            </Masonry>
        </>
    );
}
