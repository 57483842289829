import { Card } from '@mui/material';
import { CardContentButton } from '../../components/containers/CardContentButton';
import createGeoLink from '../../utils/mrr/url/createGeoLink';
import StaticMap, { StaticMapProps } from './StaticMap';

// ----------------------------------------------------------------------
interface StaticMapCardProps extends StaticMapProps {
    raised?: boolean;
}

export function StaticMapCard({ raised, lat, lon, listingName, width, height, scale, zoom }: StaticMapCardProps) {
    return (
        <Card raised={raised} sx={{ p: 0, m: 0, display: 'flex', flexDirection: 'column' }}>
            <StaticMap
                scale={scale}
                zoom={zoom}
                listingName={listingName}
                width={width}
                height={height}
                lat={lat}
                lon={lon}
                sx={{ display: 'flex', flexGrow: 1 }} />
            <CardContentButton
                component='a'
                href={createGeoLink(lat, lon)}
                rel="noopener noreferrer"
                target="_blank"
                icon="mdi:location-radius-outline">
                View in Maps
            </CardContentButton>
        </Card>
    );
}
