import {
    List,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";
import { SplitRow } from "../../../../components/containers/SplitRow";
import Iconify from "../../../../components/iconify";
import { ReservationGeneral } from "../../../../models/ReservationGeneral";

export function SelectHybridChild({
    reservations,
    selectReservation
}: {
    reservations: ReservationGeneral[];
    selectReservation: (reservation: ReservationGeneral) => void;
}) {
    return (
        <List>
            <Stack spacing={1}>
                {reservations.map(
                    (reservation, index) => (
                        <SelectReservation
                            reservation={reservation}
                            selectReservation={selectReservation}
                            selected={false}
                            key={'Add_reservation' + index} />
                    ))}
            </Stack>
        </List>
    );
}
function SelectReservation({
    reservation, selectReservation, selected,
}: {
    reservation: ReservationGeneral;
    selectReservation: (reservation: ReservationGeneral) => void;
    selected: boolean;
}) {

    const handleSelectreservation = (selection: ReservationGeneral) => {
        selectReservation(selection);
    };


    return (
        <ListItemButton
            selected={selected}
            onClick={() => { handleSelectreservation(reservation) }}
            sx={{
                minHeight: 65,
                p: 0
            }}>
            <SplitRow>
                <Stack>
                    <Typography variant="h6">
                        {reservation.name_detailed}
                    </Typography>
                    <Typography variant="body1">
                        {reservation.name}
                    </Typography>
                </Stack>
                <Iconify icon='ep:arrow-right-bold' ml={0.5} />
            </SplitRow>
        </ListItemButton>
    );
}
