import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';

export function StandardStack({ children }: PropsWithChildren) {
    return (
        <Stack spacing={2} marginY={2}>
            {children}
        </Stack>
    );
}
