import useDownloadRA from 'src/utils/download-ra';
import { CardContentButton } from './containers/CardContentButton';

const DownloadRA = ({ raID, reservationName }: { raID: string; reservationName: string }) => {
  const { downloadingRA, downloadRentalAgreement } = useDownloadRA(raID, reservationName);
  return (
    <CardContentButton
      disabled={downloadingRA}
      icon={downloadingRA ? 'line-md:loading-twotone-loop' : 'eva:eye-outline'}
      onClick={() => {
        downloadRentalAgreement();
      }}
    >
      View Rental Agreement
    </CardContentButton>
  );
};

export default DownloadRA;
