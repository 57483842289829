import { m } from 'framer-motion';
// @mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
// components
import React from 'react';
import { useAuthContext } from '../../auth/useAuthContext';
import { MotionViewport, varFade } from '../../components/animate';
import { GPISSectionBody } from '../../components/gpis/GPISSectionBody';
import Iconify from '../../components/iconify';
import { Roles } from '../../utils/mrr/userConstants';
import FAQS, { FAQ } from './FAQS';

// ----------------------------------------------------------------------
interface FaqsListI {
  title: string,
  faqs?: FAQ[],
  disableAnimation?: boolean
  disableGutter?: boolean
  groupByCategory?: boolean
  maxWidth?: number
}

export default function FaqsList(props: FaqsListI) {
  const {
    title,
    faqs,
    disableAnimation,
    disableGutter,
    groupByCategory,
    maxWidth
  } = props
  const { user } = useAuthContext()

  const userRole = user ? user?.role : Roles.User;
  const faqsList = faqs ? faqs : FAQS(userRole)
  const animation = !disableAnimation ? varFade().inRight : {}

  const faqsData = groupByCategory
    ? faqsList.sort((a, b) => a.value.localeCompare(b.value))
    : faqsList

  return (
    <Stack component={MotionViewport} spacing={1} maxWidth={maxWidth} flexGrow={1}>
      <m.div variants={animation}>
        <Typography variant="h4">
          {title}
        </Typography>
      </m.div>
      <Stack spacing={1}>
        {faqsData.map((accordion, index, arr) => {
          const newCategory = groupByCategory && arr[index - 1]?.value !== accordion.value
          return (
            <React.Fragment key={`${accordion.id}_${index}_fregment`}>
              {newCategory &&
                <m.div variants={animation}>
                  <Typography variant="subtitle2" color='text.secondary'>
                    {accordion.value}
                  </Typography>
                </m.div>
              }
              <FAQItem accordion={accordion} disableAnimation={disableAnimation} hasHTML={!!faqs} />
            </React.Fragment>)
        })}
        {!disableGutter && <div style={{ opacity: '0.0', height: '300px' }} />}
      </Stack>
    </Stack >
  );
}

// ----------------------------------------------------------------------
function FAQItem({
  accordion,
  disableAnimation,
  hasHTML,
}: {
  accordion: FAQ,
  disableAnimation?: boolean
  hasHTML?: boolean
}) {
  const animation = !disableAnimation ? varFade().inRight : {}
  return (
    <m.div variants={animation} key={accordion.id}>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
          <Typography variant="subtitle1">{accordion.heading}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography component='div' variant='body1' color='#666'>
            {hasHTML && <GPISSectionBody body={accordion.detail} />}
            {!hasHTML && accordion.detail}
          </Typography>
        </AccordionDetails>
        {accordion.actions &&
          <AccordionActions sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, mx: 1 }}>
            {accordion.actions}
          </AccordionActions>
        }
      </Accordion>
    </m.div>
  )
}