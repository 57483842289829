import { isBefore } from 'date-fns';
import { PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import { ActionMapType } from './auth/types';
import { useAuthContext } from './auth/useAuthContext';
import { ReservationGeneral } from './models/ReservationGeneral';
import { useGetReservationsQuery } from './redux/rtkQuery/apiSlice';
import { Roles } from './utils/mrr/userConstants';
import { safelyReadErrorMessage } from './utils/mrr/errorHandling';

const verboseLogging = false;

let loadingAttempts = 0;

type ReservationInfoStateType = {
    isLoading: boolean;
    errorMessage: string | null;
    fetchTime: Date;
    nextUpcomingReservation: ReservationGeneral | null;
    primaryReservation: ReservationGeneral | null;
    reservationsByName: Map<string, ReservationGeneral>;
    totalReservations: number;
};
const initialState: ReservationInfoStateType = {
    isLoading: false,
    errorMessage: null,
    fetchTime: new Date(),
    nextUpcomingReservation: null,
    primaryReservation: null,
    reservationsByName: new Map<string, ReservationGeneral>(),
    totalReservations: 0,
};
const ReservationInfoContext = createContext(initialState);
enum Types {
    Initial = 'Initial',
    Update = 'Update',
    Loading = 'Loading',
    Error = 'Error'
}
type Payload = {
    [Types.Initial]: {
        totalReservations: number;
    };
    [Types.Update]: {
        isLoading: false;
        fetchTime: Date;
        nextUpcomingReservation: ReservationGeneral | null;
        primaryReservation: ReservationGeneral | null;
        reservationsByName: Map<string, ReservationGeneral>;
        totalReservations: number;
    };
    [Types.Loading]: {
        isLoading: boolean;
    };
    [Types.Error]: {
        isLoading: false;
        errorMessage: string;
    };
};
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];
const reducer = (state: ReservationInfoStateType, action: ActionsType) => {
    verboseLogging && console.log('RESV REDUCER ACTION', action, '\nstate', state)

    if (action.type === Types.Initial) {
        const newState = initialState;
        return newState;
    }
    if (action.type === Types.Update) {
        const out = {
            ...state,
            ...action.payload
        };

        verboseLogging && console.log('RESV REDUCER onUpdate', '\nout', out);
        return out;
    }
    if (action.type === Types.Loading) {
        const out = {
            ...state,
            isLoading: action.payload.isLoading
        };

        verboseLogging && console.log('RESV REDUCER onLoading', '\nout', out);
        return out;
    }
    if (action.type === Types.Error) {
        const out = {
            ...state,
            errorMessage: action.payload.errorMessage,
            isLoading: false
        };

        verboseLogging && console.log('RESV REDUCER onError', '\nout', out)
        return out;
    }
    return state;
};
export function ReservationInfoContextProvider({ children }: PropsWithChildren) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user, isInitialized } = useAuthContext()

    const {
        isError,
        isUninitialized,
        isLoading: isRTKQLoading,
        isSuccess,
        data,
        error
    } = useGetReservationsQuery({}, { skip: user?.role !== Roles.User || !isInitialized });

    verboseLogging && console.log('RESERVATION PRELOADER ', 'loading', isRTKQLoading, 'error', error);

    useEffect(() => {
        // console.log('RESVs CONTEXT', isError, isFetching, isSuccess, isUninitialized);

        if (isUninitialized) {
            verboseLogging && console.log("resevations preloader DISPATCH init")
            dispatch({
                type: Types.Initial,
                payload: initialState
            })
            return
        }
        if (isRTKQLoading) {
            // we need a manual cutoff to prevent infinite refetch attempts when the server is down
            const attemptNumber = loadingAttempts++;

            // only on the initial request do we need to display the loading screen
            verboseLogging && console.log("resevations preloader DISPATCH loading", attemptNumber)

            if (attemptNumber < 1) {
                dispatch({
                    type: Types.Loading,
                    payload: {
                        isLoading: true
                    }
                })
            }
            return
        }
        if (isSuccess && Array.isArray(data)) {
            verboseLogging && console.log("resevations preloader DISPATCH update", data)

            const processedData = processReservations(data);

            dispatch({
                type: Types.Update,
                payload: {
                    isLoading: false,
                    fetchTime: new Date(),
                    nextUpcomingReservation: processedData.nextUpcomingReservation,
                    primaryReservation: processedData.primaryReservation,
                    reservationsByName: processedData.reservationsByName,
                    totalReservations: data.length
                }
            });
            return
        }
        if (isError) {
            verboseLogging && console.log('resevations preloader DISPATCH error', error)
            dispatch({
                type: Types.Error,
                payload: {
                    errorMessage: (error as string),
                    isLoading: false
                }
            })
            return
        }

        console.warn('state not found for reservations context')
    }, [data, error, isError, isRTKQLoading, isSuccess, isUninitialized]);

    //NOTE: Important! This context assumes a child context (currently ListingInfoContext) will
    //      block until the data is received.
    //      If we were to block here, for example by returning <LoadingScreen />, the child context
    //      would not make its call until ours responded. That makes _every_ launch much longer.
    //      We want these pre-loads to run in parallel, so all but the last should render children.

    if (isError) {
        console.log('reservation preloader error', safelyReadErrorMessage(null, error));
    }

    return (
        <ReservationInfoContext.Provider value={state}>
            {children}
        </ReservationInfoContext.Provider>
    );
}

export const useReservationInfo = () => {
    const reservationContextInfo = useContext(ReservationInfoContext);
    if (!reservationContextInfo) throw new Error('useReservationInfo context must be use inside ReservationInfoContextProvider');
    return reservationContextInfo;
};

// local helper
const processReservations = (data: ReservationGeneral[]) => {
    const allCurrent: ReservationGeneral[] = [];
    const allFuture: ReservationGeneral[] = [];
    const allPast: ReservationGeneral[] = [];

    const reservationsByName = new Map<string, ReservationGeneral>();

    data.forEach((res) => {
        if (res.is_current_booking) {
            allCurrent.push(res);
        }
        else if (res.is_future_booking) {
            allFuture.push(res);
        }
        else if (res.is_past_booking) {
            allPast.push(res);
        }

        if (reservationsByName.has(res.name)) {
            console.warn('Duplicate reservation name found in preloader.', res.name);
            return;
        }

        reservationsByName.set(res.name, res);
    });

    // sort these ascending by check-in (i.e. earliest first)
    allCurrent.sort((a, b) => { return isBefore(a.check_in_ISO, b.check_in_ISO) ? -1 : 1 });
    allFuture.sort((a, b) => { return isBefore(a.check_in_ISO, b.check_in_ISO) ? -1 : 1 });
    allPast.sort((a, b) => { return isBefore(a.check_in_ISO, b.check_in_ISO) ? -1 : 1 });

    let nextUpcomingReservation = null;
    let primaryReservation = null;

    //NOTE: This determines our 'primary' reservation, meaning the one the user is likely
    //      most interested in. That should be the common usage case.
    //      First we pick the current reservation. If there isn't one, we pick the earliest future reservation.
    //      If there are only past reservations, we take the latest one.
    //      Otherwise 'primary' remains null, which is fine. It's only used for pre-fetching and optional
    //      features. Also, a user with zero reservations is going to be very rare.

    if (allCurrent.length > 0) {
        // technically there can be more than one, but that's very rare outside dev
        primaryReservation = allCurrent[0];
    }
    else if (allFuture.length > 0) {
        nextUpcomingReservation = allFuture[0];
        primaryReservation = allFuture[0];
    }
    else if (allPast.length > 0) {
        primaryReservation = allPast[allPast.length - 1]; // take the latest one
    }

    return {
        nextUpcomingReservation: nextUpcomingReservation,
        primaryReservation: primaryReservation,
        reservationsByName: reservationsByName,
        allFuture: allFuture
    }
};
