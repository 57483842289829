import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button,
    Stack,
    Typography
} from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import countryConfig, { CountryConfig } from "src/utils/mrr/phone/countryConfig";
import * as Yup from 'yup';
import {
    RHFSelect,
    RHFTextField
} from "../../components/hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import Iconify from "../../components/iconify";
import {
    GuestGeneral,
    GuestType
} from "../../models/GuestGeneral";
import { usePatchRegistrationStepMutation, usePostReservationGuestMutation } from "../../redux/rtkQuery/apiSlice";
import { checkAgeValid, convertInputTextToInt, handleIntegerInputChange } from "../../utils/mrr/ageUtils";
import { cloud_postReservationGuest } from "../../utils/mrr/cloudFunctions";
import { extractCountryConfig, validatePhoneNumber } from "../../utils/mrr/phone/phone";
import { EMAIL_REGEX } from "../../utils/mrr/uiConstants";
import { yupCheckPhone } from "../account/userSchemes";
import { StandardRTKQError } from "../error/StandardRTKQError";
import { RegistrationPicklistItem } from "./types";


interface RegistrationChangeHostFormProps {
    ageRequired: boolean,
    minAge: number,
    mainGuest: GuestGeneral | null,
    reservationName: string,
    callbackMainGuestChange: VoidFunction,
    callbackCancel: VoidFunction,
    callbackContinue: VoidFunction,
};

interface FormInputProps {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    age: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

export function RegistrationChangeHostForm({
    ageRequired,
    minAge,
    mainGuest,
    reservationName,
    callbackMainGuestChange,
    callbackCancel,
    callbackContinue }: RegistrationChangeHostFormProps
) {
    const [globalError, setGlobalError] = useState<string | null>(null);
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const resolver = useMemo(() => {
        return Yup.object().shape({
            first_name: Yup.string().required('First name is required.'),
            last_name: Yup.string().required('Last name is required.'),
            email: Yup.string().required('Email is required').matches(EMAIL_REGEX, 'Please provide a valid email.'),
            phone: Yup.string().required('Phone is required').test('phone', 'Please provide a valid phone number.', yupCheckPhone),
            age:
                ageRequired
                    ? Yup.string().required('Host must be at least ' + minAge + ' years old at date of check-in.').test(
                        'age',
                        //NOTE: The full "Host must be at least..." message is shown as a static info tip.
                        'Must be at least ' + minAge,
                        (value) => {
                            const age = parseInt(value || '', 10);
                            return checkAgeValid(age, minAge);
                        })
                    : Yup.string().test(
                        'age',
                        'Age is invalid.',
                        (value) => {
                            const age = parseInt(value || '', 10);
                            return checkAgeValid(age);
                        }),
            address: Yup.string().required('Please provide an address.'),
            city: Yup.string().required('Please provide a city.'),
            state: Yup.string().nullable('Please provide a state/province.'),
            zipCode: Yup.string().required('Please provide a Zip/code.'),
            country: Yup.string().nullable()
        });
    }, [ageRequired, minAge]);

    const formDefaults = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        age: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'US'
    };

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            dirtyFields,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const values = watch();
    const [stateOptions, setStateOptions] = useState<CountryConfig>();

    // drives the PROVINCE/STATE fields by watching changes to country
    useEffect(() => {
        if (values.country) {
            const options = countryConfig.find((country) => (country.code === values.country) && country.divisions);
            setStateOptions(options);
            if (!options) {
                setValue('state', '')
            }
        } else if (stateOptions) {
            setValue('state', '')
            setStateOptions(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.country]);

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    const completeMainGuestStep = useCallback(async () => {
        const sfPayload = {
            reservationName: reservationName,
            registrationStep: RegistrationPicklistItem.VERIFY_HOST
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    //TODO: Double check this step. Errors were already handled on this page before a refactor.
                    console.error(data.error.message);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update main guest step', putError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    }, [callbackContinue, reservationName, sendPatchRegistrationStep]);

    const [
        sendPostReservationGuest,
        {
            isLoading: postGuestIsLoading,
            isError: postGuestIsError,
            error: postGuestError,
            reset: resetPostGuestMutation
        },
    ] = usePostReservationGuestMutation();

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async ({
        first_name,
        last_name,
        email,
        phone,
        age,
        address,
        city,
        state,
        zipCode,
        country
    }) => {
        const sfPayload = {
            hostMode: true,
            reservationName: reservationName,
            first_name,
            last_name,
            email,
            phone,
            age,
            address: {
                street: address,
                postal_code: zipCode,
                city,
                state,
                country
            },
            type: GuestType.Host
        };

        if (phone) {
            const extractedCountryConfig = extractCountryConfig(phone);
            let sfPhone = '';
            const phoneResult = validatePhoneNumber(phone || '', extractedCountryConfig.code as CountryCode);
            if (phoneResult.success) {
                sfPhone = phoneResult.parsedPhone.formatInternational();
            }
            if (sfPhone) {
                sfPayload.phone = sfPhone;
            }
        }

        // CREATE NEW
        await sendPostReservationGuest(sfPayload)
            .then(async (data: any) => {
                if (data.error) {
                    setGlobalError(data.error.message);
                    return;
                }

                await completeMainGuestStep();

                callbackMainGuestChange();
                callbackContinue();
            })
            .catch((postError: any) => {
                console.error('error on create main guest', postError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    });

    const { age } = watch();

    // this drives real-time validation, since we override the age inputs on-change
    useEffect(() => {
        //TODO: This is not complete. We don't always get the current dirty fields.
        //      It solves the init-form case, but it can break the as-you-type case
        //      (which this is intended to solve).
        if (!dirtyFields.age) {
            // We don't want this input to initialize with an error, which would happen
            // without this check, because we're manually triggering validation.
            return;
        }

        trigger('age');
    }, [age, dirtyFields.age, trigger]);

    const showStandardError = postGuestIsError && !globalError;
    const showGlobalError = globalError;

    const formLoading = isSubmitting || postGuestIsLoading;

    return (
        <FormProvider name="Change Host Form" id={`Change Host Form: ${reservationName}`} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2}>
                {showStandardError &&
                    <StandardRTKQError
                        error={postGuestError}
                        endpoint={cloud_postReservationGuest}
                        mutationCall
                    />}
                {showGlobalError &&
                    <Alert
                        severity="error">
                        {globalError}
                    </Alert>}
                <RHFTextField
                    fullWidth
                    aria-required
                    name="first_name"
                    label='First Name' />
                <RHFTextField
                    fullWidth
                    aria-required
                    name="last_name"
                    label='Last Name' />
                <RHFTextField
                    aria-required
                    name="email"
                    type="email"
                    label='Email' />
                <RHFTextField
                    aria-required
                    name="phone"
                    type="phone"
                    label='Phone' />
                <RHFTextField
                    fullWidth
                    aria-required
                    name="age"
                    label='Age'
                    onChange={(e) => {
                        const parsedValue = handleIntegerInputChange(e);
                        setValue('age', convertInputTextToInt(parsedValue));
                        trigger('age');
                    }}
                />
                {ageRequired &&
                    <Stack
                        alignItems='flex-start'
                        direction='row'
                        spacing={.5}>
                        <Iconify
                            icon='flat-color-icons:info'
                            width={16}
                        />
                        <Typography
                            fontSize={12}
                            variant="caption"
                            color='text.secondary'>
                            {'Host must be at least ' + minAge + ' years old at date of check-in.'}
                        </Typography>
                    </Stack>
                }
                <RHFTextField type='text' name="address" label="Address" />
                <RHFTextField type='text' name="city" label="City" />
                {stateOptions && stateOptions.divisions &&
                  <RHFSelect native name="state" label={stateOptions.divisionLabel}>
                    <option value="" />
                    {stateOptions.divisions.map((optionObj) => (
                      <option key={optionObj.name} value={optionObj.name}>
                        {optionObj.name}
                      </option>
                    ))}
                  </RHFSelect>
                }
                <RHFTextField type='text' name="zipCode" label="Zip/Code" />
                <RHFSelect
                  native
                  name="country"
                  label="Country"
                  placeholder="Country"
                  onInput={(event: any) => {
                    setValue('country', event.target.value)
                  }}
                >
                  <option value="" />
                  {countryConfig.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </RHFSelect>
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isSubmitting}
                    onClick={callbackCancel}
                >
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    loading={formLoading}
                    disabled={!isValid}
                    variant="contained"
                >
                    Change Host
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
