/* eslint-disable consistent-return */
import {
    Alert,
    Button,
    LinearProgress
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { PATH_APP } from "../../routes/paths";
import { convertRTKErrorToFriendly } from "../../utils/mrr/errorHandling";

interface StandardLoaderProps {
    loadedObjectName: string,
    endpoint: string,
    mutationCall: boolean, // as opposed to 'query' call
    isError: boolean,
    isFetching: boolean,
    error: any,
    customFetchMessage?: string,
    goBackPath?: string,
    goBackText?: string
}

const loaderHideTime = 200;

export function StandardRTKLoader(
    {
        loadedObjectName,
        endpoint,
        mutationCall,
        isError,
        isFetching,
        error,
        customFetchMessage,
        goBackPath,
        goBackText
    }: StandardLoaderProps) {

    const navigate = useNavigate();

    const isMobileViewport = useResponsive('down', 'sm');

    const errorMessageRef = useRef<string | null>(null);
    const isSmallViewport = useResponsive('between', 'xs', 'sm')

    const [showLoading, setShowLoading] = useState(false);

    // used to hide the loader initially, very briefly, to prevent flicker on very fast loads
    useEffect(() => {
        if (showLoading) {
            return;
        }

        const hidePhaseTimerId = setTimeout(() => {
            if (!showLoading) {
                setShowLoading(true);
            }
        }, loaderHideTime);

        return () => {
            clearTimeout(hidePhaseTimerId);
        };
    }, [showLoading]);

    //TODO: Check-in note: Ask Ray to help move this to a stylesheet.

    const containerStyle = {
        width: '100%',
        marginTop: isMobileViewport ? '10vh' : '15vh',
        fontSize: '1.25em',
        display: 'flex',
        flexDirection: 'column' as 'column', // cast due to a css types import bug in React
        alignItems: 'center',
        justifyContent: 'flex-end'
    };

    const alertStyle = {
        fontSize: '0.9em'
    };

    if (isError) {
        // this is a page-wide error state

        const friendlyInfo = convertRTKErrorToFriendly(error, endpoint, mutationCall);

        errorMessageRef.current = friendlyInfo.detail;

        return (
            <div style={containerStyle}>
                <Alert severity="error" style={alertStyle}>
                    {/* <AlertTitle>Error</AlertTitle> */}
                    {friendlyInfo.message} Our team has been notified.
                    <br />
                    <br />
                    <Button
                        variant="text"
                        onClick={() => {
                            if (goBackPath) {
                                navigate(goBackPath);
                                return;
                            }

                            //NOTE: The 'back' target selection is done this way for the following reasons:
                            //      - React Router Dom explicitly removed history from their API (v6). They now expect
                            //        you to pass state around with all of your transitions, which we don't do.
                            //      - document.referrer is not reliable, and is often cleared by the browser.
                            //      - window.navigation is not currently supported in Firefox or Safari.
                            //
                            //      So this is kind of a happy medium. If your history length is one, that means you
                            //      opened the browser directly to the page that errored. If it's two, that means you
                            //      navigated to the page with the error from one other page, most likely a new tab.
                            //
                            //      The case that's less than ideal is when you open the browser directly to a page,
                            //      navigate once to another page, then that page errors. In that case, the back button
                            //      will take you back to the dashboard. If we could analyze what's in the history, we
                            //      we could handle that case more elegantly, but that's a browser security issue.
                            //      See: https://developer.mozilla.org/en-US/docs/Web/API/Window/history
                            const navToPreviousRoute = window && window.history && window.history.length > 2;

                            if (navToPreviousRoute) {
                                navigate(-1);
                                return;
                            }

                            navigate(PATH_APP.app);
                        }}
                    >
                        {goBackText ? goBackText : 'Back'}
                    </Button>

                    {/*KEEP: Valid code. This feature has come and gone.
                    {(friendlyInfo.detail !== null
                        && friendlyInfo.detail !== standardFBInternalMessage
                        && friendlyInfo.detail !== standardSFInternalMessage) &&
                        <>
                            <br />
                            <br />
                            <span style={{ fontSize: '0.8em' }}>
                                {friendlyInfo.detail}
                            </span>
                        </>
                    }
                    */}
                </Alert>
                <br />
            </div>
        );
    }

    errorMessageRef.current = null;

    if (showLoading && isFetching) {
        // page-wide loading state
        return (
            <div style={containerStyle}>
                {customFetchMessage
                    ? customFetchMessage
                    : ('Getting ' + loadedObjectName + '...')
                }
                <br />
                <br />
                <LinearProgress color="inherit" sx={{ width: 1, maxWidth: isSmallViewport ? 240 : 360 }} />
            </div>
        );
    }

    return null;
}
