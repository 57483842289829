import { isValid } from "date-fns";
import { GPISDetailSection, GPISItem } from "./GPISDetailSection";
// eslint-disable-next-line import/no-cycle
import { GPISChannelList } from "../components/gpis/GPISChannelList";

export class GPISGeneral {
    public readonly basic_information: GPISItem | null = null;
    public readonly contact_information: GPISItem | null = null;
    public readonly faq: GPISItem | null = null;
    public readonly resort_information: GPISItem | null = null;
    public readonly safety_information: GPISItem | null = null;
    public readonly suite_information: GPISItem | null = null;
    public readonly channel_list: GPISChannel[] = [];
    public readonly last_updated: Date | null = null;
    constructor(
        public readonly table_of_contents: string[],
        private readonly _last_updated: string,
        private readonly _basic_information: Record<string, any>,
        private readonly _contact_information: Record<string, any>,
        private readonly _faq: Record<string, any>,
        private readonly _resort_information: Record<string, any>,
        private readonly _safety_information: Record<string, any>,
        private readonly _suite_information: Record<string, any>,
        private readonly _channel_list: any[]
    ) {
        if (this._basic_information) {
            this.basic_information = new GPISItem(this._basic_information.title, this._basic_information.link, this._basic_information.sections);
        }
        if (this._contact_information) {
            this.contact_information = new GPISItem(this._contact_information.title, this._contact_information.link, this._contact_information.sections);
        }
        if (this._faq) {
            this.faq = new GPISItem(this._faq.title, this._faq.link, this._faq.sections);
        }
        if (this._resort_information) {
            this.resort_information = new GPISItem(this._resort_information.title, this._resort_information.link, this._resort_information.sections);
        }
        if (this._safety_information) {
            this.safety_information = new GPISItem(this._safety_information.title, this._safety_information.link, this._safety_information.sections);
        }
        if (this._suite_information) {
            this.suite_information = new GPISItem(this._suite_information.title, this._suite_information.link, this._suite_information.sections);
        }
        if (this._channel_list) {
            this.channel_list = this._channel_list.map((channel: any) => new GPISChannel(channel?.program, channel?.definition, channel?.channel));
        }
        if (isValid(new Date(this._last_updated))) {
            this.last_updated = new Date(this._last_updated);
        }

    }
}
export function InstanciateGPISFromJSON(json: any): GPISGeneral {
    const {
        table_of_contents, channel_list, last_updated, basic_information, contact_information, faq, resort_information, safety_information, suite_information,
    } = json;

    return new GPISGeneral(
        table_of_contents || [],
        last_updated || '',
        basic_information || null,
        contact_information || null,
        faq || null,
        resort_information || null,
        safety_information || null,
        suite_information || null,
        channel_list || []);
}

export class GPISChannel {
    constructor(
        public readonly program: string,
        public readonly definition: string,
        public readonly channel: string
    ) { }

    static createGPISItem = (channel_list: GPISChannel[]) => {
        return new GPISDetailSection(
            'Channel List',
            'channel_list',
            '',
            <GPISChannelList channel_list={channel_list} />
        );
    };
}
